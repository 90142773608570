import moment from "moment";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

//------user defined components or elements
import Topbar from "../../../shared/components/topbar/Topbar";
import {
  dashboardConstants,
  datepickerConstants,
  globalConstants,
  STATS_SCREEN_CONSTANTS,
} from "../../../constants";
import {
  DownloadIcon,
  PrintSaleSummaryIcon,
  RoundIcon,
} from "../../../assets/icons/iconsProvider";
import DashboardDatePicker from "../../../shared/components/datetimePickerDropdown/DashboardDatePicker";
import {
  formatMonth,
  getSalesGraphConfiguration,
} from "../../Dashboard/utility";
import { dashboardActions } from "../../../redux/actions";
import DashboardProgressBar from "../../../shared/components/progressBar/DashboardProgressBar";
import ToggleButtonWithText from "../../../shared/components/toggleButtons/ToggleButtonWithText";
import DataTable from "react-data-table-component";
import {
  downloadCSV,
  NoDataWrapper,
  parseToThousandSeperatorDecimalNumber,
} from "../../../shared/utility";
import SalesSummaryDataTable from "../../../shared/components/SalesSummary/SalesSummaryDataTable";
import { useReactToPrint } from "react-to-print";
import reportLogo from "../../../assets/images/reportLogo.jpg";

const SalesSummary = ({
  atAGlance,
  currentStore,
  getAtAGlance,
  storeTransactionHistory,
  getStoreTransactionHistory,
}) => {
  //-------UseStates
  const [byDate, setByDate] = useState([]);
  const [byMonth, setByMonth] = useState([]);
  const [isSortByVolume, setIsSortByVolume] = useState({
    topItems: false,
    category: false,
  });
  const [chartData, setChartData] = useState(storeTransactionHistory || []);
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: dashboardConstants.DAILY,
  });
  const contentRef = useRef();
  const printFunction = useReactToPrint({
    contentRef,
    documentTitle: "Sales Summary",
  });
  const columnHeaders = [
    {
      id: "productName",
      name: "Product Name",
      selector: (row) => row.itemName,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.itemName}</span>;
      },
      sortable: true,
      sortDirection: null,
      width: "35%",
    },
    {
      id: "price",
      name: "Price",
      selector: (row) => row.price,
      cell: (row) => {
        return (
          <span>$ {parseToThousandSeperatorDecimalNumber(row?.price)}</span>
        );
      },
      sortable: true,
      sortDirection: null,
      width: "20%",
    },
    {
      id: "sold",
      name: "Sold",
      selector: (row) => row.numberOfSoldUnits,
      cell: (row) => {
        return <span>{row.soldUnitsQty}</span>;
      },
      sortable: true,
      sortDirection: null,
      width: "15%",
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.inStock,
      cell: (row) => {
        return (
          <span
            className={`text-left w-100 pe-4 d-flex align-items-center gap-2 ${
              row.isInStock ? "in-stock-column" : "text-danger"
            }`}
          >
            <RoundIcon />
            <span className="text-nowrap">
              {row.isInStock ? "In Stock" : "Out of Stock"}
            </span>
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
      width: "30%",
    },
  ];

  //------set selected datetime from the datetime picker
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment
        .utc(value.start)
        .format(datepickerConstants.DATE_TIME_PICKER_DEFAULT_FORMAT),
      end: moment
        .utc(value.end)
        .format(datepickerConstants.DATE_TIME_PICKER_DEFAULT_FORMAT),
      duration: value?.duration,
    });
  };

  //-------options and series for the graph
  const { options, series } = getSalesGraphConfiguration(
    byMonth,
    chartData,
    timeFrame
  );

  const toggleSortByVolume = (key) => {
    setIsSortByVolume((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  //-------useEffects
  useEffect(() => {
    setChartData(storeTransactionHistory || []);
    const dailyGroupedData = {};
    const monthlyGroupedData = {};

    // Iterate through the original data
    storeTransactionHistory?.forEach((item) => {
      const date = item.createdOn.substr(0, 10); // Extract the date portion (YYYY-MM-DD)
      if (!dailyGroupedData[date]) {
        dailyGroupedData[date] = {
          createdOn: date,
          totalAmount: 0,
        };
      }
      dailyGroupedData[date].totalAmount += Number(item.TotalAmountPaid || 0);

      const yearMonth = formatMonth(item.createdOn); // Format the month
      if (!monthlyGroupedData[yearMonth]) {
        monthlyGroupedData[yearMonth] = {
          createdOn: yearMonth,
          totalAmount: 0,
        };
      }
      monthlyGroupedData[yearMonth].totalAmount += Number(
        item.TotalAmountPaid || 0
      );
    });

    // Convert the grouped data objects into arrays
    setByDate(Object.values(dailyGroupedData));
    setByMonth(Object.values(monthlyGroupedData));
  }, [storeTransactionHistory]);

  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      getAtAGlance(
        currentStore?.id,
        timeFrame.start,
        timeFrame.end,
        globalConstants.EMPTY_STRING,
        globalConstants.EMPTY_STRING,
        timeFrame.duration,
        STATS_SCREEN_CONSTANTS.SALES_SUMMARY_STATS
      );
      getStoreTransactionHistory(
        currentStore.id,
        "Transaction",
        timeFrame.start,
        timeFrame.end,
        timeFrame.duration
      );
    }
  }, [timeFrame, currentStore]);

  const hanleDownloadCsv = () => {
    downloadCSV(
      [
        { titles: "Sales", value: "" },
        { titles: "Gross Sales", value: "$" + (atAGlance?.grossSales || 0) },
        { titles: "Returns", value: "$" + (atAGlance?.totalReturns || 0) },
        { titles: "Discounts", value: "$" + (atAGlance?.totalDiscounts || 0) },
        { titles: "Net Sales", value: "$" + (atAGlance?.netSales || 0) },
        { titles: "Taxes", value: "$" + (atAGlance?.totalTaxes || 0) },
        {
          titles: "Gift Card Sales",
          value: "$" + (atAGlance?.giftCardSales || 0),
        },
        {
          titles: "Store Credit Payments",
          value: "$" + (atAGlance?.totalStorecreditPayments || 0),
        },
        { titles: "Total Sales", value: "$" + (atAGlance?.totalSales || 0) },
        {
          titles: "Total Sales Transactions",
          value: atAGlance?.totalSaleTransactions || 0,
        },
        {
          titles: "Average Order Value",
          value: "$" + (atAGlance?.averageOrderValue || 0),
        },
        { titles: "Total COGS", value: "$" + (atAGlance?.totalCogs || 0) },
        { titles: "Total Profit", value: "$" + (atAGlance?.totalProfit || 0) },
        { titles: "Trade-Ins", value: "" },
        {
          titles: "Total Trade Transaction",
          value: atAGlance?.totalTradeTransactions || 0,
        },
        {
          titles: "Total Trade-In Stock Price",
          value: "$" + (atAGlance?.totalTradeInStockPrice || 0),
        },
        {
          titles: "Total Trade-In Purchases",
          value: "$" + (atAGlance?.totalTradeInPurchases || 0),
        },
        {
          titles: "Total Store Credit Payments",
          value: "$" + (atAGlance?.totalStoreCreditTradeIns || 0),
        },
        {
          titles: "Total Cash Payments",
          value: "$" + (atAGlance?.totalTradeCashPayments || 0),
        },
        { titles: "Payments", value: "" },
        {
          titles: "Total Collected",
          value: "$" + (atAGlance?.totalPayments || 0),
        },
        { titles: "Cash", value: "$" + (atAGlance?.totalCashPayments || 0) },
        {
          titles: "Credit/Debit Card",
          value: "$" + (atAGlance?.totalCreditCardPayments || 0),
        },
        {
          titles: "Gift Card",
          value: "$" + (atAGlance?.totalGiftcardPayments || 0),
        },
        {
          titles: "Store Credit",
          value: "$" + (atAGlance?.totalStorecreditPayments || 0),
        },
        { titles: "Fees", value: "$" + (atAGlance?.fees || 0) },
        { titles: "Net Total", value: "$" + (atAGlance?.netTotal || 0) },
      ],
      "sales-summary",
      `${moment(timeFrame.start).format("YYYY-MM-DD")}-${moment(
        timeFrame.end
      ).format("YYYY-MM-DD")}`
    );
  };

  //------ Sales Summary Component Starts
  return (
    <>
      <Topbar title="Sales Summary"></Topbar>
      <Row className="m-0 ">
        <Col md={6} className="d-flex align-items-center">
          <DashboardDatePicker label="Date" getTimeFrame={getTimeFrame} />
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <button onClick={printFunction} className="download-summary-button">
            <PrintSaleSummaryIcon />
          </button>
          <button
            onClick={hanleDownloadCsv}
            className="download-summary-button"
          >
            <DownloadIcon />
          </button>
        </Col>
      </Row>
      <Row className="m-0 mt-2 pb-5 sales-summary-wrapper container-scrollbar">
        <Col md={12}>
          <div className="dashboard-card-wrapper overflow-hidden mt-2">
            {chartData?.length ? (
              <>
                <div className="dashboard-chart-wrapper  graph-card-wrapper">
                  <ApexCharts
                    options={options}
                    series={series}
                    type="area"
                    height={200}
                  />
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center flex-column no-graph-data-wrapper">
                <p>No Sales in this date range</p>
                <p>Try selecting a different date range</p>
              </div>
            )}
          </div>
        </Col>
        <Col md={6} className="mt-3">
          <div className="dashboard-card-wrapper sales-card-wrapper">
            <Row className="m-0">
              <Col
                md={12}
                className="p-0 mb-2 d-flex justify-content-between align-items-center w-100"
              >
                <span className="card-heading">Sales by Category</span>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <ToggleButtonWithText
                    isToggle={isSortByVolume.category}
                    handleLeftButtonClick={() => toggleSortByVolume("category")}
                    handleRightButtonClick={() =>
                      toggleSortByVolume("category")
                    }
                    leftButtonLabel="Vol"
                    rightButtonLabel="Qty"
                    className="discount-toggle-button dashboard-card-button"
                  />
                </div>
              </Col>
              <DashboardProgressBar
                atAGlance={atAGlance}
                isSortByVolume={isSortByVolume}
              />
            </Row>
          </div>
        </Col>
        <Col md={6} className="mt-3">
          <div className="dashboard-card-wrapper sales-card-wrapper">
            <Row className="m-0">
              <Col
                md={12}
                className="mb-2 p-0 d-flex justify-content-between align-items-center w-100 "
              >
                <span className="card-heading">Top 5 Selling Items</span>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <ToggleButtonWithText
                    isToggle={isSortByVolume.topItems}
                    handleLeftButtonClick={() => toggleSortByVolume("topItems")}
                    handleRightButtonClick={() =>
                      toggleSortByVolume("topItems")
                    }
                    leftButtonLabel="Vol"
                    rightButtonLabel="Qty"
                    className="discount-toggle-button dashboard-card-button"
                  />
                </div>
              </Col>
              <Col md={12}>
                {atAGlance?.bestSellingStats?.topFiveSellingItems?.byQuantity
                  ?.length &&
                atAGlance?.bestSellingStats?.topFiveSellingItems?.byVolume
                  ?.length ? (
                  <DataTable
                    data={
                      isSortByVolume?.topItems
                        ? atAGlance?.bestSellingStats?.topFiveSellingItems
                            ?.byQuantity || []
                        : atAGlance?.bestSellingStats?.topFiveSellingItems
                            ?.byVolume || []
                    }
                    columns={columnHeaders}
                    fixedHeader
                  />
                ) : (
                  NoDataWrapper()
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <div ref={contentRef} className="px-5">
          <div className="noPrint pt-5">
            <div>
              <div className="d-flex flex-column ">
                {moment(timeFrame.start).format("MMM DD,YYYY")} -
                {moment(timeFrame.end).format("MMM DD,YYYY")}
              </div>
              <span className="sale-summary-report-heading">Sales Summary</span>
            </div>
            <img src={reportLogo} width="30px" />
          </div>
          <SalesSummaryDataTable atAGlance={atAGlance} />
        </div>
      </Row>
    </>
  );
  //------ Sales Summary Component End
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  atAGlance: state.dashboard.atAGlance,
  currentStore: state.store.currentStore,
  storeTransactionHistory: state.dashboard.storeTransactionHistory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAtAGlance: (
    selectedStore,
    start,
    end,
    category,
    productType,
    duration,
    screenName
  ) =>
    dispatch(
      dashboardActions.getAtAGlance(
        selectedStore,
        start,
        end,
        category,
        productType,
        duration,
        screenName
      )
    ),
  getStoreTransactionHistory: (selectedStore, type, start, end, duration) =>
    dispatch(
      dashboardActions.getStoreTransactionHistory(
        selectedStore,
        type,
        start,
        end,
        duration
      )
    ),
});

//------- export sales summary component
export default connect(mapStateToProps, mapDispatchToProps)(SalesSummary);
