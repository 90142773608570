import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  ClockIcon,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../assets/icons/iconsProvider";
import { datetimePickerDropdown } from "../../../assets/scss/style/datetimePickerDropdown";
import {
  datepickerConstants,
  toastMessages,
  toastType,
} from "../../../constants";
import { customToast } from "../../utility";

const DatetimePickerDropdown = ({
  getTimeFrame,
  displayDateFormat = "MM/DD/YYYY",
  timeframeDateFormat = "",
  label,
  menuPortalTarget = document.querySelector("body"),
}) => {
  const datetimePickerOptions = [
    { value: datepickerConstants.DAILY, label: "Daily" },
    { value: datepickerConstants.WEEKLY, label: "Weekly" },
    { value: datepickerConstants.BIWEEKLY, label: "Biweekly" },
    { value: datepickerConstants.MONTHLY, label: "Monthly" },
    { value: datepickerConstants.YEARLY, label: "Yearly" },
    { value: datepickerConstants.CUSTOM, label: "Custom" },
  ];

  const [selectedDatetimePickerOption, setSelectedDatetimePickerOption] =
    useState(datetimePickerOptions[0]);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(
    moment().format(datepickerConstants.MMMM_YYYY)
  );
  const [currentWeek, setCurrentWeek] = useState({
    start: moment().startOf(datepickerConstants.WEEK).format(displayDateFormat),
    end: moment().endOf(datepickerConstants.WEEK).format(displayDateFormat),
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedYearOption, setSelectedYearOption] = useState(new Date());
  const getBiweeklyTimeFrame = (dateFormat) => {
    const today = moment();
    const startOfBiweek = today.clone().startOf(datepickerConstants.WEEK);
    const endOfBiweek = startOfBiweek
      .clone()
      .add(1, datepickerConstants.WEEK)
      .subtract(1, datepickerConstants.DAY);

    const startOfPreviousBiweek = startOfBiweek
      .clone()
      .subtract(1, datepickerConstants.WEEK);

    return {
      start: startOfPreviousBiweek.format(dateFormat),
      end: endOfBiweek.format(dateFormat),
    };
  };
  const [currentBiweekStart, setCurrentBiweekStart] = useState(
    getBiweeklyTimeFrame(displayDateFormat).start
  );

  const [currentBiweekEnd, setCurrentBiweekEnd] = useState(
    getBiweeklyTimeFrame(displayDateFormat).end
  );

  const [selectedYear, setSelectedYear] = useState(
    moment().format(datepickerConstants.YYYY)
  );

  const getDefaultTimeFrame = () => {
    const start = moment(customStartDate)
      .startOf(datepickerConstants.DAY)
      .format(timeframeDateFormat);
    const end = moment(customEndDate)
      .endOf(datepickerConstants.DAY)
      .format(timeframeDateFormat);
    return {
      start: start,
      end: end,
      duration: datepickerConstants.DAILY,
    };
  };

  const getWeekTimeFrame = (dateFormat) => {
    const startOfWeek = moment().startOf(datepickerConstants.WEEK);
    const endOfWeek = moment().endOf(datepickerConstants.WEEK);

    return {
      start: startOfWeek.format(dateFormat),
      end: endOfWeek.format(dateFormat),
    };
  };

  const getMonthlyTimeFrame = (dateFormat) => {
    const today = moment();
    const startOfMonth = today.clone().startOf(datepickerConstants.MONTH);
    const endOfMonth = today.clone().endOf(datepickerConstants.MONTH);

    return {
      start: startOfMonth.format(dateFormat),
      end: endOfMonth.format(dateFormat),
    };
  };

  const getYearlyTimeFrame = (selectedYear, dateFormat) => {
    const startOfYear = moment(selectedYear, datepickerConstants.YYYY).startOf(
      datepickerConstants.YEAR
    );
    const endOfYear = moment(selectedYear, datepickerConstants.YYYY).endOf(
      datepickerConstants.YEAR
    );

    return {
      start: startOfYear.format(dateFormat),
      end: endOfYear.format(dateFormat),
    };
  };

  const getCustomTimeFrame = (dateFormat) => {
    // Assuming customStartDate and customEndDate are already defined elsewhere
    const startOfDay = moment(customStartDate)
      .startOf(datepickerConstants.DAY)
      .format(dateFormat);
    const endOfDay = moment(customEndDate)
      .endOf(datepickerConstants.DAY)
      .format(dateFormat);

    return {
      start: startOfDay,
      end: endOfDay,
    };
  };

  const handleCustomStartDateChange = (date) => {
    if (date <= customEndDate) {
      setCustomStartDate(date);
    } else {
      customToast(
        toastMessages.START_DATE_MUST_BE_LESS_THAN_END_DATE,
        toastType.ERROR
      );
    }
  };

  const handleCustomEndDateChange = (date) => {
    if (customStartDate <= date) {
      setCustomEndDate(date);
    } else {
      customToast(
        toastMessages.END_DATE_MUST_BE_GREATER_THAN_START_DATE,
        toastType.ERROR
      );
    }
  };

  const handleNextMonth = () => {
    const nextMonth = moment(currentMonth, datepickerConstants.MMMM_YYYY).add(
      1,
      datepickerConstants.MONTHS
    );
    setCurrentMonth(nextMonth.format(datepickerConstants.MMMM_YYYY));
    getTimeFrame({
      start: nextMonth
        .startOf(datepickerConstants.MONTH)
        .format(timeframeDateFormat),
      end: nextMonth
        .endOf(datepickerConstants.MONTH)
        .format(timeframeDateFormat),
    });
  };

  const handlePrevMonth = () => {
    const prevMonth = moment(
      currentMonth,
      datepickerConstants.MMMM_YYYY
    ).subtract(1, datepickerConstants.MONTHS);
    setCurrentMonth(prevMonth.format(datepickerConstants.MMMM_YYYY));
    getTimeFrame({
      start: prevMonth
        .startOf(datepickerConstants.MONTH)
        .format(timeframeDateFormat),
      end: prevMonth
        .endOf(datepickerConstants.MONTH)
        .format(timeframeDateFormat),
    });
  };
  const handleNextWeek = () => {
    const nextWeekStart = moment(currentWeek.start, displayDateFormat).add(
      1,
      datepickerConstants.WEEK
    );
    setCurrentWeek({
      start: nextWeekStart
        .startOf(datepickerConstants.WEEK)
        .format(displayDateFormat),
      end: nextWeekStart
        .endOf(datepickerConstants.WEEK)
        .format(displayDateFormat),
    });
    getTimeFrame({
      start: nextWeekStart
        .startOf(datepickerConstants.WEEK)
        .format(timeframeDateFormat),
      end: nextWeekStart
        .endOf(datepickerConstants.WEEK)
        .format(timeframeDateFormat),
    });
  };

  const handlePrevWeek = () => {
    const prevWeekStart = moment(currentWeek.start, displayDateFormat).subtract(
      1,
      datepickerConstants.WEEK
    );
    setCurrentWeek({
      start: prevWeekStart
        .startOf(datepickerConstants.WEEK)
        .format(displayDateFormat),
      end: prevWeekStart
        .endOf(datepickerConstants.WEEK)
        .format(displayDateFormat),
    });
    getTimeFrame({
      start: prevWeekStart
        .startOf(datepickerConstants.WEEK)
        .format(timeframeDateFormat),
      end: prevWeekStart
        .endOf(datepickerConstants.WEEK)
        .format(timeframeDateFormat),
    });
  };
  // Functions to calculate biweekly start and end dates
  const handlePrevBiweek = () => {
    const prevBiweekStart = moment(currentBiweekStart, displayDateFormat)
      .subtract(14, "days")
      .format(displayDateFormat);
    setCurrentBiweekStart(prevBiweekStart);
    setCurrentBiweekEnd(
      moment(prevBiweekStart, displayDateFormat)
        .add(13, "days")
        .format(displayDateFormat)
    );
    getTimeFrame({
      start: prevBiweekStart,
      end: moment(prevBiweekStart, displayDateFormat)
        .add(13, "days")
        .format(timeframeDateFormat),
    });
  };

  const handleNextBiweek = () => {
    const nextBiweekStart = moment(currentBiweekStart, displayDateFormat)
      .add(14, "days")
      .format(displayDateFormat);
    setCurrentBiweekStart(nextBiweekStart);
    setCurrentBiweekEnd(
      moment(nextBiweekStart, displayDateFormat)
        .add(13, "days")
        .format(displayDateFormat)
    );
    getTimeFrame({
      start: nextBiweekStart,
      end: moment(nextBiweekStart, displayDateFormat)
        .add(13, "days")
        .format(timeframeDateFormat),
    });
  };

  const handleNextYear = () => {
    const nextYear = moment(selectedYear, datepickerConstants.YYYY).add(
      1,
      datepickerConstants.YEAR
    );
    setSelectedYear(nextYear.format(datepickerConstants.YYYY));
    getTimeFrame(
      getYearlyTimeFrame(
        nextYear.format(datepickerConstants.YYYY),
        timeframeDateFormat
      )
    );
  };

  const handlePrevYear = () => {
    const prevYear = moment(selectedYear, datepickerConstants.YYYY).subtract(
      1,
      datepickerConstants.YEAR
    );
    setSelectedYear(prevYear.format(datepickerConstants.YYYY));
    getTimeFrame(
      getYearlyTimeFrame(
        prevYear.format(datepickerConstants.YYYY),
        timeframeDateFormat
      )
    );
  };

  const handleDatetimePickerOptionChange = (selectedOption) => {
    setSelectedDatetimePickerOption(selectedOption);

    if (selectedOption) {
      switch (selectedOption.value) {
        case datepickerConstants.DAILY:
          // getTimeFrame(getDefaultTimeFrame());
          const today = new Date();
          getDaily(today);
          break;
        case datepickerConstants.WEEKLY:
          const startDate = moment()
            .startOf(datepickerConstants.WEEK)
            .format(displayDateFormat);
          const endDate = moment()
            .endOf(datepickerConstants.WEEK)
            .format(displayDateFormat);

          setCurrentWeek({ start: startDate, end: endDate });

          // getTimeFrame(getWeekTimeFrame(timeframeDateFormat));

          setCustomStartDate(startDate);
          setCustomEndDate(endDate);

          break;
        case datepickerConstants.BIWEEKLY:
          setCurrentBiweekStart(getBiweeklyTimeFrame(displayDateFormat).start);
          setCurrentBiweekEnd(getBiweeklyTimeFrame(displayDateFormat).end);
          getTimeFrame(getBiweeklyTimeFrame(timeframeDateFormat));
          setCustomStartDate(getBiweeklyTimeFrame(displayDateFormat).start);
          setCustomEndDate(getBiweeklyTimeFrame(displayDateFormat).end);
          break;
        case datepickerConstants.MONTHLY:
          getTimeFrame(getMonthlyTimeFrame(timeframeDateFormat));
          break;
        case datepickerConstants.YEARLY:
          getTimeFrame(getYearlyTimeFrame(selectedYear, timeframeDateFormat));
          break;
        case datepickerConstants.CUSTOM:
          // getTimeFrame(getCustomTimeFrame(timeframeDateFormat));
          const CustomDate = new Date();
          getRange(CustomDate);
          break;
        default:
          return;
      }
    }
  };

  const CustomInput = (props) => (
    <div>
      <components.Input {...props} />
      <ClockIcon className="dropdown-icon" />
    </div>
  );
  useEffect(() => {
    getTimeFrame(getCustomTimeFrame());
  }, [customStartDate, customEndDate]);

  useEffect(() => {
    getTimeFrame(getDefaultTimeFrame());
  }, [timeframeDateFormat]);

  const getDaily = (date) => {
    setCustomStartDate(date);
    setCustomEndDate(date);
  };
  const getRange = async (date) => {
    setCustomStartDate(date);
    setCustomEndDate(date);
  };

  const handleChangeWeek = (week) => {
    const startDate = new Date(week);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };
  const handleChangeBiWeek = (week) => {
    const startDate = new Date(week);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 13);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };
  const handleChangeMonth = (week) => {
    setSelectedMonth(week);
    const startDate = new Date(week);
    const endDate = moment(week).add(1, "month").subtract(1, "day").toDate();
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };
  const handleChangeYear = (week) => {
    setSelectedYearOption(week);
    const startDate = new Date(week);
    const customEndDate = new Date(startDate);
    customEndDate.setDate(startDate.getDate() + 364);
    setCustomStartDate(startDate);
    setCustomEndDate(customEndDate);
  };

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  return (
    <div className="d-flex flex-column">
      {label && <label className="field-label">{label}</label>}
      <div className="d-flex justify-content-center align-items-center gap-2 datetimepicker-container">
        <Select
          options={datetimePickerOptions}
          value={selectedDatetimePickerOption}
          onChange={handleDatetimePickerOptionChange}
          styles={datetimePickerDropdown}
          className="date-time-picker-dropdown"
          components={{
            IndicatorSeparator: () => null,
            Input: CustomInput,
          }}
          menuPortalTarget={menuPortalTarget}
        />

        {selectedDatetimePickerOption.value === datepickerConstants.DAILY ? (
          <div className="datetime-background">
            <DatePicker
              selected={customEndDate}
              onChange={(e) => {
                getDaily(e);
              }}
            />
          </div>
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.WEEKLY ? (
          <div className="datetime-background">
            <DatePicker
              selected={customStartDate}
              onChange={handleChangeWeek}
              // showWeekNumbers
              // showWeekPicker
              selectsRange
              startDate={customStartDate}
              endDate={customEndDate}
            />
          </div>
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.BIWEEKLY ? (
          <div className="datetime-background">
            <DatePicker
              selected={customStartDate}
              onChange={handleChangeBiWeek}
              // showWeekNumbers
              // showWeekPicker
              selectsRange
              startDate={customStartDate}
              endDate={customEndDate}
            />
          </div>
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.MONTHLY ? (
          <div className="d-flex align-items-center gap-2 datetime-background">
            <DatePicker
              selected={selectedMonth}
              renderMonthContent={renderMonthContent}
              showMonthYearPicker
              dateFormat="MM/yyyy"
              onChange={(date) => handleChangeMonth(date)}
            />
          </div>
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.YEARLY ? (
          <div className="d-flex align-items-center gap-2 datetime-background">
            <DatePicker
              selected={selectedYearOption}
              renderYearContent={renderYearContent}
              showYearPicker
              dateFormat="yyyy"
              onChange={(date) => handleChangeYear(date)}
            />
          </div>
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.CUSTOM ? (
          <div className="d-flex justify-content-center gap-2">
            <DatePicker
              selected={customStartDate}
              onChange={handleCustomStartDateChange}
            />
            <DatePicker
              selected={customEndDate}
              onChange={handleCustomEndDateChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DatetimePickerDropdown;
