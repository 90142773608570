import React, { useEffect, useState } from "react";
import { EMPTY_STRING, globalConstants } from "../../../constants";

const ToggleButton = ({
  Disabled,
  handleButtonClick,
  activeButton,
  buttonsList = [
    globalConstants.CASH,
    globalConstants.CREDIT,
    globalConstants.GIFT_CARD,
  ],
  isCreditCardButtonDisable,
}) => {
  // const [activeButton, setActiveButton] = useState(globalConstants.CASH);

  return (
    <div className="toggle-button-container">
      {buttonsList.includes(globalConstants.CASH) && (
        <button
          className={`toggle-button-inner ${
            activeButton === globalConstants.CASH
              ? "light-purple-button-toggle"
              : ""
          }`}
          onClick={() => handleButtonClick(globalConstants.CASH)}
        >
          Cash
        </button>
      )}
      {buttonsList.includes(globalConstants.CREDIT) && (
        <button
          disabled={Disabled}
          className={`toggle-button-inner ${
            activeButton === globalConstants.CREDIT
              ? "light-purple-button-toggle"
              : EMPTY_STRING
          }`}
          onClick={() => handleButtonClick(globalConstants.CREDIT)}
        >
          Store Credit
        </button>
      )}
      {buttonsList.includes(globalConstants.GIFT_CARD) && (
        <button
          className={`toggle-button-inner ${
            activeButton === globalConstants.GIFT_CARD
              ? "light-purple-button-toggle"
              : EMPTY_STRING
          }`}
          onClick={() => handleButtonClick(globalConstants.GIFT_CARD)}
        >
          Gift Card
        </button>
      )}
      {buttonsList.includes(globalConstants.CREDIT_CARD) && (
        <button
          className={`toggle-button-inner ${
            activeButton === globalConstants.CREDIT_CARD
              ? "light-purple-button-toggle"
              : EMPTY_STRING
          }`}
          disabled={isCreditCardButtonDisable}
          onClick={() => handleButtonClick(globalConstants.CREDIT_CARD)}
        >
          Credit Card
        </button>
      )}
    </div>
  );
};

export default ToggleButton;
