import { updateObject } from "../../shared/utility";
import {
  globalConstants,
  customerConstants,
  defaultGridState,
} from "../../constants";

//-------InitialState of the reducer
const initialState = {
  allCustomers: [],
  customerPaginationData: { ...defaultGridState },
  duplicateCustomers: [],
  customerById: {},
  isCustomerLogsModal: false,
  customerDropdownKey: 1, // remount the customer dropdown after add/edit customer to update the async select options
};

//-------Export user Reducer
export const customer = (state = initialState, action) => {
  switch (action.type) {
    //-------Get customers success case
    case customerConstants.GET_ALL_CUSTOMERS + globalConstants.SUCCESS:
      return updateObject(state, {
        allCustomers: action.data || [],
        customerPaginationData: { ...defaultGridState },
      });
    case customerConstants.GET_PAGINATION_CUSTOMERS + globalConstants.SUCCESS:
      const { customers, ...customerPaginationData } = action.data;
      return updateObject(state, {
        allCustomers: customers,
        customerPaginationData: customerPaginationData,
      });
    //-------Get customers failure case
    case customerConstants.GET_ALL_CUSTOMERS + globalConstants.FAILURE:
    case customerConstants.GET_PAGINATION_CUSTOMERS + globalConstants.FAILURE:
      return updateObject(state, {
        allCustomers: [],
        customerPaginationData: { ...defaultGridState },
      });
    //-------Get all customers failure case
    case customerConstants.BULK_UPLOAD_CUSTOMERS + globalConstants.SUCCESS:
      return updateObject(state, {
        duplicateCustomers: action.data,
      });
    //-------Get GET_CUSTOMER_BY_ID success case
    case customerConstants.GET_CUSTOMER_BY_ID + globalConstants.SUCCESS:
      return updateObject(state, {
        customerById: action.data || [],
        allCustomers: [action.data],
      });
    //-------EMPTY_CUSTOMER_BY_ID success case
    case customerConstants.EMPTY_CUSTOMER_BY_ID + globalConstants.SUCCESS:
      return updateObject(state, {
        customerById: {},
      });
    case customerConstants.SET_IS_CUSTOMER_LOGS_MODAL + globalConstants.SUCCESS:
      return updateObject(state, {
        isCustomerLogsModal: action.data,
      });
    case customerConstants.SET_CUSTOMER_DROPDOWN_KEY:
      return updateObject(state, {
        customerDropdownKey: action.data || state.customerDropdownKey + 1,
      });
    //-------Default State

    default:
      return state;
  }
};
