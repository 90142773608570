import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";

import {
  buttonNameConstants,
  buttonTypeConstants,
  inventoryConstants,
} from "../../../../constants";
import ProductImage from "../../../../assets/images/productimage.png";
import {
  getPresetStockPrice,
  getProductDetailCardTags,
  getShortestSku,
  handleEmptyOnFocus,
  handleViewEbaySales,
  handleViewPriceCharting,
  isPriceChanged,
  isSpinnerEnabled,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import EditableReactDataTableComponent from "../../../../shared/components/table/EditableReactDataTableComponent";
import Button from "../../../../shared/components/Buttons/Button";
import { inventoryActions } from "../../../../redux/actions";
import { connect } from "react-redux";
import { SidebarToggleIcon } from "../../../../assets/icons/iconsProvider";

const EditPriceModal = ({
  mainTableGridState,
  tradeDiscount,
  spinnerArray,
  currentStore,
  isEditPriceModal,
  inventoryProducts,
  toggleEditPriceModal,
  updatePriceChangeProductPrices,
  emptyInventoryProductsList,
}) => {
  //-------tradeDiscount Reducer
  const { markupPresets, tradeinMargin } = tradeDiscount;

  const [columns, setColumns] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productUrl, setProductUrl] = useState();
  const [gridState, setGridState] = useState({
    pageSize: 500,
    pageIndex: 0,
    sortBy: [],
  });

  const handleHideModal = () => {
    setProductsList([]);
    emptyInventoryProductsList([]);
    toggleEditPriceModal();
  };

  const handleMarkupPriceClick = (e, row) => {
    const markupPrice = e.target.textContent.replace("$", "");
    const updatedProductList = productsList.map((item) => {
      if (item?.id === row?.id) {
        return {
          ...item,
          price: {
            ...item.price,
            unit_sell_price: markupPrice,
          },
        };
      } else {
        return item;
      }
    });
    setProductsList(updatedProductList);
  };

  const handleChangeStockPrice = (value, sku) => {
    const updatedProductList = productsList?.map((item) => {
      if (getShortestSku(item.sku) === getShortestSku(sku)) {
        const marketPrice = Number(item?.price?.marketPrice);
        const newValue = Number(value);
        return {
          ...item,
          price: {
            ...item.price,
            unit_sell_price: newValue,
            percentageDifference: ((marketPrice - newValue) / newValue) * 100,
            cashDifference: marketPrice - newValue,
          },
        };
      } else {
        return item;
      }
    });
    setProductsList(updatedProductList);
  };

  const columnHeaders = [
    {
      id: "condition",
      name: "Condition",
      selector: (row) => row.price.type,
      cell: (row) => {
        return row?.price?.type;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "tags",
      name: "Tags",
      selector: (row) => row.tags,
      cell: (row) => {
        return getProductDetailCardTags(
          row.tags?.filter((tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG),
          row.sku
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "cogs",
      name: "COGS",
      selector: (row) => row.price.unit_purchase_price,
      cell: (row) => {
        return `$${parseToThousandSeperatorDecimalNumber(
          row.price.unit_purchase_price
        )}`;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "stockPrice",
      name: "Stock Price",
      selector: (row) => row?.price?.unit_sell_price,
      cell: (row) => {
        return (
          <input
            key={row?.price?.unit_sell_price}
            min={0}
            type="number"
            defaultValue={row?.price?.unit_sell_price}
            onFocus={handleEmptyOnFocus}
            className="input-textfield editable-inventory-cell-max-size"
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              handleChangeStockPrice(inputValue, row.sku);
            }}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "marketPrice",
      name: "Market Price",
      selector: (row) => row?.price?.marketPrice,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">
            {`$${parseToThousandSeperatorDecimalNumber(
              row?.price?.marketPrice || 0
            )}`}
          </span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "markupprice",
      name: "Markup Price",
      selector: (row) => row?.price?.marketPrice,
      cell: (row) => {
        return (
          <span
            className="inventory-cell-max-size text-decoration-underline cursor-pointer"
            onClick={(e) => handleMarkupPriceClick(e, row)}
          >
            {`$${getPresetStockPrice(
              row?.productType,
              row?.price?.marketPrice,
              markupPresets,
              row,
              true,
              row.subcategory,
              row.category_name
            )}`}
          </span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "difference%",
      name: "Difference (%)",
      selector: (row) => row.marketPrice,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size 
            ${row.price.isDismiss && "price-changes-dismissed "}
            ${row.price?.percentageDifference < 0 ? " text-red" : "text-purple"}
            `}
          >
            {`${parseToThousandSeperatorDecimalNumber(
              row.price?.percentageDifference
            )}%`}
          </span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "difference$",
      name: "Difference ($)",
      selector: (row) => row.cashDifference,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size 
              ${row.price.isDismiss && "price-changes-dismissed"}
              ${row.price?.cashDifference < 0 ? " text-red" : "text-purple"}
              `}
          >
            {`$${parseToThousandSeperatorDecimalNumber(
              row.price?.cashDifference
            )}`}
          </span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
  ];

  const handleSubmit = () => {
    const dataToSend = {
      changeProductPrice: productsList?.map((item) => ({
        id: item.id,
        product_name: item.product_name,
        unit_sell_price: item.price.unit_sell_price,
      })),
      removeProducts: inventoryProducts?.map((item) => item.id),
    };
    updatePriceChangeProductPrices(
      currentStore?.id,
      mainTableGridState,
      dataToSend,
      handleHideModal
    );
  };

  useEffect(() => {
    setColumns([...columnHeaders]);
  }, [productsList]);

  useEffect(() => {
    setProductsList(inventoryProducts);
  }, [inventoryProducts]);

  return (
    <Modal
      show={isEditPriceModal}
      size="xl"
      onHide={
        isSpinnerEnabled([], inventoryConstants.ADD_INVENTORY)
          ? ""
          : handleHideModal
      }
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Price Changes - Edit
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body d-flex ">
        <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
          <Col xs={12} className="add-inventory-hero-wrapper px-0">
            <Row className="w-100 m-0">
              <Col md={8} className="d-flex gap-3">
                <div className="add-inventory-product-image price-change-product-image ">
                  <img
                    src={
                      productsList?.length && productsList[0]?.imgUrl
                        ? productsList[0]?.imgUrl
                        : ProductImage
                    }
                  />
                </div>
                <div className="d-flex flex-column align-items-start price-changes-content-wrapper gap-2">
                  <span>
                    {productsList?.length && productsList[0]?.product_name
                      ? productsList[0]?.product_name
                      : "Product Name"}
                  </span>
                  <div className="d-flex flex-column price-change-product-properties">
                    <span>{`Type: ${
                      productsList?.length && productsList[0]?.productType
                        ? productsList[0]?.productType
                        : "Product Type"
                    }`}</span>
                    <span>{`Category: ${
                      productsList?.length && productsList[0]?.category_name
                        ? productsList[0]?.category_name
                        : "Product Category"
                    }`}</span>
                    <span>{`Sub-Category: ${
                      productsList?.length && productsList[0]?.subcategory
                        ? productsList[0]?.subcategory
                        : "Product Sub-Category"
                    }`}</span>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex flex-column align-items-center gap-2"
              >
                <Button
                  type="button"
                  label={buttonNameConstants.VIEW_EBAY_SALES}
                  className="w-100"
                  handleClick={() =>
                    handleViewEbaySales(
                      productsList[0]?.product_name,
                      productsList[0]?.category_name,
                      productsList[0]?.subcategory
                    )
                  }
                  buttonType={buttonTypeConstants.LIGHT_PURPLE}
                  isDisabled={!productsList?.length}
                />
                <Button
                  type="button"
                  label={buttonNameConstants.VIEW_PRICE_CHARTING}
                  className="w-100"
                  handleClick={() =>
                    handleViewPriceCharting(
                      {
                        productObject: {
                          "console-name": productsList[0]?.category_name,
                          "product-name": productsList[0]?.product_name,
                        },
                      },
                      setProductUrl
                    )
                  }
                  isDisabled={!productsList?.length}
                  buttonType={buttonTypeConstants.LIGHT_PURPLE}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className=" px-0 mt-4">
            <EditableReactDataTableComponent
              columns={columns}
              gridState={gridState}
              data={productsList}
              setGridState={setGridState}
              total={5}
              className="price-changes-edit-modal"
            />
          </Col>
          <Col md={6} className="d-flex justify-content-end align-items-center">
            <Button
              label={buttonNameConstants.CANCEL}
              className="w-75"
              type="button"
              handleClick={() => handleHideModal()}
              buttonType={buttonTypeConstants.GHOST_BUTTON}
              isDisabled={isSpinnerEnabled(
                spinnerArray,
                inventoryConstants.UPDATE_INVENTORY
              )}
            />
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <Button
              label={buttonNameConstants.SAVE}
              className="w-75"
              type="submit"
              handleClick={handleSubmit}
              isDisabled={
                isSpinnerEnabled(
                  spinnerArray,
                  inventoryConstants.UPDATE_INVENTORY
                ) || !isPriceChanged(inventoryProducts, productsList)
              }
            />
          </Col>
        </Row>
        {productUrl && (
          <Row className="m-0 w-50">
            <Col md={12}>
              {productUrl && (
                <iframe
                  title="Product Iframe"
                  src={productUrl}
                  className="iframe-website"
                />
              )}
            </Col>
          </Row>
        )}
      </Modal.Body>
      {productUrl && (
        <button
          type="button"
          onClick={() => {
            setProductUrl("");
          }}
          className="iframe-toogle-button"
        >
          <SidebarToggleIcon className={"iframe-toggle-icon"} />
        </button>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  inventoryProducts: state.inventory.inventoryProducts,
});

const mapDispatchToProps = (dispatch) => ({
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  updatePriceChangeProductPrices: (storeId, gridState, data, handleHideModal) =>
    dispatch(
      inventoryActions.updatePriceChangeProductPrices(
        storeId,
        gridState,
        data,
        handleHideModal
      )
    ),
  emptyInventoryProductsList: () =>
    dispatch(inventoryActions.emptyInventoryProductsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPriceModal);
