export const datepickerConstants = {
  ALL: "All",
  DAY: "day",
  SHORT: "short",
  YEAR: "year",
  WEEK: "week",
  YYYY: "YYYY",
  MONTH: "month",
  DAILY: "daily",
  MONTHS: "months",
  WEEKLY: "weekly",
  CUSTOM: "custom",
  YEARLY: "yearly",
  MONTHLY: "monthly",
  NUMERIC: "numeric",
  BIWEEKLY: "biweekly",
  END_DATE: "End Date:",
  MMMM_YYYY: "MMMM YYYY",
  START_DATE: "Start Date:",
  SET_END_DATE: "Set End Date",
  PREVIOUS_DATE_FORMAT: "MM/DD/yyyy",
  DAILY_NEXT_BUTTON: "DAILY_NEXT_BUTTON",
  MONTH_NEXT_BUTTON: "MONTH_NEXT_BUTTON",
  DATE_TIME_FORMAT: "YYYY-MM-DDTHH:mm:ssZ",
  WEEKLY_NEXT_BUTTON: "WEEKLY_NEXT_BUTTON",
  YEARLY_NEXT_BUTTON: "YEARLY_NEXT_BUTTON",
  BIWEEKLY_NEXT_BUTTON: "BIWEEKLY_NEXT_BUTTON",
  DAILY_PREVIOUS_BUTTON: "DAILY_PREVIOUS_BUTTON",
  MONTH_PREVIOUS_BUTTON: "MONTH_PREVIOUS_BUTTON",
  YEARLY_PREVIOUS_BUTTON: "YEARLY_PREVIOUS_BUTTON",
  WEEKLY_PREVIOUS_BUTTON: "WEEKLY_PREVIOUS_BUTTON",
  BIWEEKLY_PREVIOUS_BUTTON: "BIWEEKLY_PREVIOUS_BUTTON",
  DATE_TIME_PICKER_DEFAULT_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
};

export const DATEPICKER_OPTIONS = [
  { value: datepickerConstants.DAILY, label: "Daily" },
  { value: datepickerConstants.WEEKLY, label: "Weekly" },
  { value: datepickerConstants.BIWEEKLY, label: "Biweekly" },
  { value: datepickerConstants.MONTHLY, label: "Monthly" },
  { value: datepickerConstants.YEARLY, label: "Yearly" },
  { value: datepickerConstants.CUSTOM, label: "Custom" },
];

export const ITEM_HISTORY_DATEPICKER_OPTIONS = [
  { value: datepickerConstants.ALL, label: "All" },
  ...DATEPICKER_OPTIONS,
];
