import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import SelectDropDown from "../selectDropdown/SelectDropdown";
import {
  PRODUCT_TYPES_ENUMS,
  SET_BY_MARGIN_ENUMS,
} from "../../../system/globalEnums";
import {
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  getCustomTypesOptionList,
  getDefaultFilterType,
} from "../../utility";

const TradeFilters = ({
  setBy,
  customTypes,
  customCategories,
  customSubCategories,
  setFilterProductType,
  setFilterProductCategory,
}) => {
  const defaultAllTypeOptions = getDefaultFilterType(
    customTypes,
    PRODUCT_TYPES_ENUMS.ALL
  );
  const defaultVideogameTypeOptions = getDefaultFilterType(
    customTypes,
    PRODUCT_TYPES_ENUMS.VIDEO_GAME
  );

  const [productCategory, setProductCategory] = useState();
  const [productSubCategory, setProductSubCategory] = useState();
  const [productType, setProductType] = useState(defaultAllTypeOptions);

  const [categoryOptions, setCategoryOptions] = useState(
    getCustomCategoriesOptionList(
      customTypes,
      productType?.value,
      productType.label
    )
  );

  useEffect(() => {
    setCategoryOptions(
      productType.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME &&
        setBy !== SET_BY_MARGIN_ENUMS.PRODUCT.value
        ? [
            {
              value: PRODUCT_TYPES_ENUMS.ALL,
              label: PRODUCT_TYPES_ENUMS.ALL,
            },
            ...getCustomCategoriesOptionList(
              customCategories,
              productType?.value,
              productType?.label
            ),
          ]
        : getCustomCategoriesOptionList(
            customCategories,
            productType?.value,
            productType?.label
          )
    );

    const defaultproductCategory =
      productType.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME
        ? {
            value: PRODUCT_TYPES_ENUMS.ALL,
            label: PRODUCT_TYPES_ENUMS.ALL,
          }
        : getCustomCategoriesOptionList(
            customCategories,
            productType?.value,
            productType?.label
          )[0];
    setProductCategory(defaultproductCategory);
    setFilterProductCategory(defaultproductCategory);
  }, [productType, customCategories]);

  useEffect(() => {
    if (setBy === SET_BY_MARGIN_ENUMS.PRODUCT.value) {
      setProductType(defaultVideogameTypeOptions);
      setFilterProductType(defaultVideogameTypeOptions);
    } else {
      setProductType(defaultAllTypeOptions);
      setFilterProductType(defaultAllTypeOptions);
    }
  }, [customTypes, setBy]);
  return (
    <Row className="m-0 px-0">
      {setBy === SET_BY_MARGIN_ENUMS.TYPE.value ? (
        ""
      ) : setBy === SET_BY_MARGIN_ENUMS.CATEGORY.value ? (
        <Col md={12} className="px-md-0 mb-3">
          <label>Select Type</label>
          <SelectDropDown
            menuPortalTarget={""}
            menuPlacement={"bottom"}
            placeHolder="Select Type..."
            options={getCustomTypesOptionList(customTypes)}
            defaultPageSize={getCustomTypesOptionList(customTypes)[0]}
            value={productType}
            handleChange={(e) => {
              setProductType(e);
              setFilterProductType(e);
            }}
          />
        </Col>
      ) : setBy === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value ? (
        <>
          <Col md={6} className="ps-md-0 mb-3">
            <label>Select Type</label>
            <SelectDropDown
              menuPortalTarget={""}
              menuPlacement={"bottom"}
              placeHolder="Select Type..."
              options={getCustomTypesOptionList(customTypes)}
              defaultPageSize={getCustomTypesOptionList(customTypes)[0]}
              value={productType}
              handleChange={(e) => {
                setProductType(e);
                setFilterProductType(e);
              }}
            />
          </Col>
          <Col md={6} className="pe-md-0 mb-3">
            <label>Select Category</label>
            <SelectDropDown
              menuPortalTarget={""}
              menuPlacement={"bottom"}
              placeHolder="Select Category..."
              options={categoryOptions}
              defaultPageSize={categoryOptions[0]}
              value={productCategory}
              handleChange={(e) => {
                setProductCategory(e);
                setFilterProductCategory(e);
              }}
              isSearchable={true}
            />
          </Col>
        </>
      ) : (
        ""
      )}
    </Row>
  );
};

export default TradeFilters;
