import React from "react";
import { Col } from "react-bootstrap";

import { EMPTY_STRING } from "../../../constants";

const SalesSummaryDatarow = ({
  dataRowType = 3,
  title = EMPTY_STRING,
  value = EMPTY_STRING,
  className = "",
  fontClassName = "sales-summary-heading",
}) => {
  return dataRowType === 1 ? (
    <Col md={12} className={className}>
      <div className="d-flex justify-content-between align-items-center p-3 footer-border-dark ">
        <span className={fontClassName}>{title}</span>
        <span className={fontClassName}>{value}</span>
      </div>
    </Col>
  ) : dataRowType === 2 ? (
    <Col md={12} className={className}>
      <div className="d-flex justify-content-between align-items-center p-3 header-border-dark-border ">
        <span className={fontClassName}>{title}</span>
        <span className={fontClassName}>{value}</span>
      </div>
    </Col>
  ) : dataRowType === 3 ? (
    <Col md={12} className={className}>
      <div className="d-flex justify-content-between align-items-center p-3 detail-div-border">
        <span className={fontClassName}>{title}</span>
        <span className={fontClassName}>{value}</span>
      </div>
    </Col>
  ) : (
    ""
  );
};

export default SalesSummaryDatarow;
