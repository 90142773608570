import moment from "moment";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BarcodeReader from "react-barcode-reader";
import { useDebouncedCallback } from "use-debounce";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import Topbar from "../../../shared/components/topbar/Topbar";
import AddInventoryItem from "./AddInventory/AddInventoryItem";
import Button from "../../../shared/components/Buttons/Button";
import ImportInventory from "./ImportInventory/ImportInventory";
import ShrinkItemModal from "./ShrinkItemModal/ShrinkItemModal";
import {
  PRINT_LABEL_DESIGN_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../system/globalEnums";
import ItemImage from ".././../../assets/images/productimage.png";
import PrintLabelModal from "./printingLabelModal/PrintLabelModal";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import AddBatchInventory from "./AddBatchInventory/AddBatchInventory";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomGridHeader from "../../../shared/components/agGrid/CustomGridHeader";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import AGGridToolTip from "../../../shared/components/toolTipComponent/AGGridToolTip";
import FilterDropdowns from "../../../shared/components/FilterDropdowns/FilterDropdowns";
import InventoryOverviewCard from "../../../shared/components/inventoryOverviewCard/InventoryOverviewCard";
import {
  inventoryActions,
  itemOrganizationActions,
  printerActions,
  systemActions,
  tradeInDiscountActions,
} from "../../../redux/actions";
import {
  AddIcon,
  TickIcon,
  EditIcon,
  PrintIcon,
  CrossIcon,
  SearchIcon,
  DeleteIcon,
  BlueBoxIcon,
  ImportCSVIcon,
  ExportCSVIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";
import {
  ROLES,
  toastType,
  toastMessages,
  globalConstants,
  defaultGridState,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  EMPTY_STRING,
} from "../../../constants";
import {
  downloadCSV,
  customToast,
  exportTagFunc,
  handleKeyDown,
  getShortestSku,
  LoadingIndicator,
  getDefaultFilterType,
  exportStringArrayFunc,
  downloadImage,
  isButtonDisable,
  isSpinnerEnabled,
  prepareFilterForPaginationCall,
  resizeAndDarkenImageBlob,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
  beautifyStringForImport,
  getDefaultFilterCategory,
  exportUpcFunc,
  getRowId,
} from "../../../shared/utility";
import { printZebraData } from "../../../shared/Printers/ReceiptPrinters/printReceipt";
import { inventoryService, storeService } from "../../../services";
import ClickableTooltip from "../../../shared/components/toolTipComponent/ClickableTooltip";
import ServerPaginationTable from "../../../shared/components/agGrid/ServerPaginationTable";
import SummaryCardSkeleton from "../../../shared/Skeletons/inventorySkeletons/SummaryCardSkeleton";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";

//-------Inventory Items component start here
const Items = (props) => {
  const {
    role,
    inventory,
    currentStore,
    spinnerArray,
    addCustomItem,
    isAddModalOpen,
    toggleAddModal,
    quantityShrink,
    deleteInventory,
    updateInventory,
    activateSpinner,
    itemOrganization,
    emptyAllInventory,
    deactivateSpinner,
    defaultZebraPrinter,
    getInventorySummary,
    importCsvFileInventory,
    getPaginatedInventory,
    isBatchInventoryModal,
    setIsBatchInventoryModal,
    isDraftConfirmationModal,
    activateImportInventorySpinner,
  } = props;

  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  //-------Inventory Reducer
  const { allInventory, inventorySummary, priceChartingProductInfo } =
    inventory;

  //------navigate links
  const navigate = useNavigate();
  const { state } = useLocation();

  const gridRef = useRef();
  const rowBeingEditRef = useRef({});
  const rowBeingEditParamRef = useRef({});
  const tooltipRef = useRef();

  //-------usestate
  const [gridState, setGridState] = useState(
    state?.gridState || defaultGridState
  );
  const [searchText, setSearchText] = useState(
    gridState?.searchQuery || EMPTY_STRING
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToDeleted, setRowsToDeleted] = useState([]);
  const [singlePrintArray, setSinglePrintArray] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [ClearSelectedRows, setclearSelectedRows] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState(
    gridState?.searchQuery || EMPTY_STRING
  );
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [itemEditShrinkModal, setItemEditShrinkModal] = useState(false);
  const [isEditBatchInventory, setIsEditBatchInventory] = useState(false);
  const [importInventoryModal, setImportInventoryModal] = useState(false);
  const [addInventoryItemModal, setAddInventoryItemModal] = useState(false);
  const [itemDeleteShrinkModal, setItemDeleteShrinkModal] = useState(false);
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(
      customTypes,
      gridState?.productType || PRODUCT_TYPES_ENUMS.ALL
    )
  );
  const [searchProductCategory, setSearchProductCategory] = useState(
    getDefaultFilterCategory(customCategories, gridState?.productCategory)
  );
  const [searchProductSubCategory, setSearchProductSubCategory] = useState(
    getDefaultFilterCategory(customSubCategories, gridState?.productSubCategory)
  );
  const storeInventorySummary = inventorySummary;

  const toggleItemDeleteShrinkModal = () => {
    setItemDeleteShrinkModal(!itemDeleteShrinkModal);
  };

  const toggleItemEditShrinkModal = () => {
    setItemEditShrinkModal(!itemEditShrinkModal);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  const handleConfirmYesButtonClick = () => {
    toggleConfirmationModal();
    toggleItemDeleteShrinkModal();
  };

  const handleDeleteNoButtonClick = () => {
    handleDeleteInventory();
    toggleItemDeleteShrinkModal();
  };
  const handleDeleteYesButtonClick = () => {
    handleDeleteInventory();

    const dataToShrink = rowsToDeleted?.filter((item) => {
      if (item?.price?.quantity > 0 || item?.price?.quantity < 0) {
        return {
          ...item,
          shrinkQuantity: item.price.quantity,
        };
      }
    });
    if (dataToShrink?.length) {
      quantityShrink(dataToShrink);
    }

    setRowsToDeleted([]);
    toggleItemDeleteShrinkModal();
  };

  const handleEditNoButtonClick = () => {
    toggleItemEditShrinkModal();
    handleUpdateInventory(false);
  };
  const handleEditYesButtonClick = () => {
    toggleItemEditShrinkModal();
    handleUpdateInventory(true);
  };

  const toggleEditBatchInventory = () => {
    setIsEditBatchInventory(!isEditBatchInventory);
  };

  const handleDeleteInventory = () => {
    const deleteItemsIds = rowsToDeleted.map((item) => item.id);
    deleteInventory(deleteItemsIds, currentStore?.id, gridState);
    setSelectedRows([]);
    setclearSelectedRows(true);
  };

  //-------Handle Add button click
  const handleExport = async (allData) => {
    activateImportInventorySpinner({
      isVisible: true,
      message: "Exporting Inventory...",
    });
    await inventoryService.getAllInventory(currentStore?.id).then(
      (response) => {
        downloadCSV(
          filterColumnforExport(response),
          inventoryConstants.EXPORT_FILE_NAME
        );
        activateImportInventorySpinner({ isVisible: false, message: "" });
      },
      (error) => {
        customToast(error, toastType.ERROR);
        activateImportInventorySpinner({ isVisible: false, message: "" });
      }
    );
  };
  const handleAddIventoryItemClick = () => {
    toggleAddModal(true);
    toggleAddInventoryItemModal();
  };

  //-------Toggle Add Inventoty Item Modal
  const toggleAddInventoryItemModal = () =>
    setAddInventoryItemModal(!addInventoryItemModal);

  //-------Toggle Print Label Modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };

  const handleClearFilter = () => {
    updateGridState(defaultGridState);
    setSearchText("");
    setDebouncedSearchText("");
  };

  //------Toggle Import Inventory Modal
  const toggleImportInventoryModal = () => {
    importInventoryModal && handleClearFilter();
    setImportInventoryModal(!importInventoryModal);
  };

  const handlePrintLabelButtonClick = (props) => {
    setSelectedRows([]);
    setclearSelectedRows(true);
    setSinglePrintArray([props.data]);
    togglePrintLabelModal();
    // if (props.hideTooltipCallback) {
    //   props.hideTooltipCallback();
    // }
  };

  //-------handle Edit Submit Click
  const handleEditSubmitClick = (params) => {
    const { price, originalQuantity } = rowBeingEditRef.current;
    if (price.quantity < originalQuantity) {
      toggleItemEditShrinkModal();
      return;
    }
    handleUpdateInventory(false);
  };

  //-------handle update inventory
  const handleUpdateInventory = (isStolen) => {
    const {
      id,
      margin,
      price,
      originalQuantity,
      imgUrl,
      store,
      originalPrice,
      upc,
      product_name,
      productType,
      category_name,
      subcategory,
      cardRarity,
      serialNumber,
      trackQuantity,
    } = rowBeingEditRef.current;
    if (id) {
      const updateInventoryObject = {
        id: id,
        margin: margin || 0,
        imgUrl: imgUrl,
        upc: upc,
        product_name: product_name,
        productType: productType,
        category_name: category_name,
        subcategory: subcategory,
        cardRarity: cardRarity,
        trackQuantity: trackQuantity,
        price: {
          type: price.type,
          quantity: price.quantity,
          unit_purchase_price: price.unit_purchase_price,
          unit_sell_price: price.unit_sell_price,
          marketPrice: price.marketPrice || 0,
          profit: price.profit || 0,
        },
        serialNumber: serialNumber,
      };

      if (isStolen) {
        const ShrinkRowData = {
          ...rowBeingEditRef.current,
          price: {
            ...rowBeingEditRef.current.price,
            quantity: originalQuantity,
          },
          shrinkQuantity: originalQuantity - price.quantity,
        };
        delete ShrinkRowData["originalQuantity"];
        quantityShrink([ShrinkRowData]);
      }
      updateInventory(store?.id, updateInventoryObject, true, gridState);
      rowBeingEditRef.current = {};
    }
  };

  const handlePrintLabelClick = () => {
    setSinglePrintArray([]);
    togglePrintLabelModal();
  };

  //-------Handle Delete button click
  const handleDeleteClick = (props) => {
    setRowsToDeleted([props.data]);
    toggleConfirmationModal();
    // if (props.hideTooltipCallback) {
    //   props.hideTooltipCallback();
    // }
  };

  //-------Handle Edit button click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.INVENTORY_EDIT, {
      state: {
        data: row,
        isEdit: true,
        currentStore: currentStore,
        gridState: gridState,
      },
    });
  };

  const updateGridState = (gridState) => {
    getPaginatedInventory(currentStore?.id, gridState);
    setGridState(gridState);
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", alignItems: "center" },
      resizable: false,
      tooltipComponent: AGGridToolTip,
      headerTooltipComponent: AGGridToolTip,
      headerComponentParams: {
        gridState: gridState,
        updateGridState: updateGridState,
      },
    };
  }, [gridState]);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      "highlighted-row": (params) => {
        return (
          params?.data?.id && params?.data?.id === rowBeingEditRef.current?.id
        );
      },
    };
  }, []);

  const handleRefreshCells = (params) => {
    params.api.refreshCells({
      columns: [
        "id",
        "price.unit_sell_price",
        "price.unit_purchase_price",
        "price.quantity",
      ],
      rowNodes: [params.node],
      force: true,
    });
  };

  const handleCancelEdit = (params) => {
    rowBeingEditRef.current = {};
    redrawTopRows(params);
    rowBeingEditParamRef.current = {};
    handleRefreshCells(params);
  };

  //-------Get store logo  in zpl code form
  const getStoreLogo = async () => {
    if (!currentStore?.logo) {
      customToast(toastMessages.STORE_LOGO_NOT_FOUND, toastType.ERROR);
    }

    const downloadedImage = await downloadImage(currentStore?.logo);
    const resizedImage = await resizeAndDarkenImageBlob(downloadedImage, 95);
    const formData = new FormData();
    formData.append("file", resizedImage);
    return storeService.getStoreLogoZplCode(formData).then(
      (response) => {
        return response.data
          .replace("^XA", "")
          .replace("^XZ", "")
          .replace("^FO0,0", "");
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  const handleStandardLabelPrint = async (params) => {
    activateSpinner(inventoryConstants.LABELS_PRINTING);
    customToast("Labels Printing", toastType.SUCCESS);
    const storeLogo = currentStore?.showOnLabels ? await getStoreLogo() : false;
    const labelToPrint = {
      sku: params.data.sku,
      title: params.data.product_name,
      stockQuantity: params.data.price.quantity,
      printQuantity: 1,
      price: {
        type: params.data.price.type,
        unit_sell_price: params.data.price.unit_sell_price,
      },
      category_name: params.data.category_name,
      product_name: params.data.product_name,
      productType: params.data.productType,
      tags: params.data.tags,
    };

    printZebraData(
      [labelToPrint],
      PRINT_LABEL_DESIGN_ENUMS.STANDARD.value,
      storeLogo,
      defaultZebraPrinter,
      deactivateSpinner
    );
  };

  function actionCellRenderer(params) {
    if (params.data?.id) {
      if (rowBeingEditRef.current.id === params.data.id) {
        return (
          <>
            <button
              id={`modalTool${params.data.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleEditSubmitClick(params)}
            >
              <TickIcon className="cursor-pointer text-primary" />
            </button>
            <button
              id={`modalTool${params.data.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleCancelEdit(params)}
            >
              <CrossIcon className="cursor-pointer" />
            </button>
          </>
        );
      } else {
        return (
          <div
            onMouseLeave={() => {
              tooltipRef.current?.close();
            }}
          >
            <button
              type="button"
              onClick={() => handleStandardLabelPrint(params)}
              className={`action-Button-Wrapper ${
                defaultZebraPrinter ? "" : "disabled-transaprent-button"
              }`}
              disabled={defaultZebraPrinter ? false : true}
            >
              <PrintIcon className="cursor-pointer" />
            </button>

            <>
              <ClickableTooltip
                anchorSelect={`#tool${params.data.id}`}
                openOnClick={true}
                tooltipRef={tooltipRef}
              >
                <Button
                  className="w-100"
                  IconImage={EditIcon}
                  label={buttonNameConstants.EDIT}
                  handleClick={() => handleEditClick(params.data)}
                  buttonType={buttonTypeConstants.ACTION_BUTTON}
                />
                <Button
                  className="w-100"
                  IconImage={DeleteIcon}
                  label={buttonNameConstants.DELETE}
                  handleClick={() => handleDeleteClick(params)}
                  buttonType={buttonTypeConstants.ACTION_BUTTON}
                />
                <Button
                  label={buttonNameConstants.PRINT_LABEL}
                  className="w-100"
                  buttonType={buttonTypeConstants.ACTION_BUTTON}
                  IconImage={PrintIcon}
                  handleClick={() => handlePrintLabelButtonClick(params)}
                />
              </ClickableTooltip>
              <button
                id={`tool${params.data.id}`}
                className="action-Button-Wrapper"
                onClick={() => {
                  tooltipRef.current?.isOpen
                    ? tooltipRef.current?.close()
                    : tooltipRef.current?.open({
                        anchorSelect: `tool${params.data.id}`,
                      });
                }}
              >
                <ActionDotsIcons className="cursor-pointer" />
              </button>
            </>
          </div>
        );
      }
    } else {
      return <DropdownSkeleton width="5em" height="1.5em" />;
    }
  }

  const redrawTopRows = useCallback((params) => {
    params.api.redrawRows({ rowNodes: [params.node] });
  }, []);

  // Column Definitions: Defines the columns to be displayed.
  const colDefs = useMemo(
    () => [
      {
        headerName: "Sku",
        lockPosition: "left",
        field: "sku",
        cellStyle: {},
        minWidth: 180,
        cellRenderer: (row) => {
          const shortestSku = getShortestSku(row.data.sku);
          return row?.data?.sku ? (
            <div className="d-flex justify-content-start align-items-center">
              <div className="product-image">
                <img src={row.data.imgUrl || ItemImage} />
              </div>
              <span className="inventory-cell-max-size product-sku">
                <TableCustomHeader
                  id={`sku-number-in-array-${shortestSku}`}
                  label={
                    <span className="inventory-cell-max-size product-sku">
                      {shortestSku.length <= 6
                        ? shortestSku
                        : shortestSku.slice(-6) + "..."}
                    </span>
                  }
                  tooltopText={
                    <div className="d-flex flex-column ">
                      {row.data.sku.map((s, index) => (
                        <p
                          className="sku-wrapper text-nowrap text-start fw-normal m-0"
                          key={index}
                        >
                          {s}
                        </p>
                      ))}
                    </div>
                  }
                  className="sku-tooltip"
                />
              </span>
            </div>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerTooltip: "Product Name",
        headerName: "Product...",
        field: "product_name",
        cellRenderer: (row) =>
          row?.data?.product_name ? (
            <span
              className="inventory-cell-max-size product-edit-anchor-link cursor-pointer"
              onClick={() => handleEditClick(row.data)}
            >
              <TableCustomHeader
                id={`product-name-in-array-${getShortestSku(row.data.sku)}`}
                label={
                  <span className="inventory-cell-max-size">
                    {row.data.product_name}
                  </span>
                }
                tooltopText={
                  <p className="text-start fw-normal m-0">
                    {row.data.product_name}
                  </p>
                }
                className="sku-tooltip"
              />
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),

        cellStyle: {
          overflow: "visible",
        },
      },
      {
        headerName: "Type",
        field: "productType",
        cellRenderer: (row) => {
          return row?.data?.productType ? (
            row.data.productType
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerName: "Category",
        field: "category_name",
        cellRenderer: (params) =>
          params?.data?.category_name ? (
            <span className="inventory-cell-max-size">
              {params.data.category_name}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Condition",
        field: "price.type",
        cellRenderer: (params) =>
          params?.data?.price?.type ? (
            <span className="inventory-cell-max-size">
              {params?.data?.price?.type}
            </span>
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerName: "Tags",
        field: "tags",
        cellRenderer: (row) =>
          row?.data?.tags ? (
            row.data.tags?.filter(
              (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
            )?.length || 0
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          ),
      },
      {
        headerTooltip: "Unit Purchase Price",
        headerName: "Unit P...",
        field: "price.unit_purchase_price",
        cellRenderer: (params) => {
          return params.data?.price ? (
            params.data.id === rowBeingEditRef.current?.id ? (
              <input
                min={0}
                type="number"
                defaultValue={params.data.price.unit_purchase_price}
                className="input-textfield input-textfield-without-icon  editable-table-row-fields inventory-editable-row-fields"
                onChange={(e) => {
                  rowBeingEditRef.current = {
                    ...rowBeingEditRef.current,
                    price: {
                      ...rowBeingEditRef.current.price,
                      unit_purchase_price: e.target.value,
                    },
                  };
                }}
              ></input>
            ) : (
              <span className="inventory-cell-max-size">
                $
                {parseToThousandSeperatorDecimalNumber(
                  params.data.price.unit_purchase_price
                )}
              </span>
            )
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerTooltip: "Unit Sell Price",
        headerName: "Unit S...",
        field: "price.unit_sell_price",
        cellRenderer: (params) => {
          return params.data?.price ? (
            params.data.id === rowBeingEditRef?.current?.id ? (
              <input
                min={0}
                type="number"
                defaultValue={params.data.price.unit_sell_price}
                className="input-textfield input-textfield-without-icon  editable-table-row-fields inventory-editable-row-fields"
                onChange={(e) => {
                  rowBeingEditRef.current = {
                    ...rowBeingEditRef.current,
                    price: {
                      ...rowBeingEditRef.current.price,
                      unit_sell_price: e.target.value,
                    },
                  };
                }}
              ></input>
            ) : (
              <span className="inventory-cell-max-size">
                $
                {parseToThousandSeperatorDecimalNumber(
                  params.data.price.unit_sell_price
                )}
              </span>
            )
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        headerName: "Quantity",
        field: "price.quantity",
        cellRenderer: (params) => {
          return params.data?.price ? (
            params.data.id === rowBeingEditRef?.current.id ? (
              <input
                type="number"
                min={-9999}
                onKeyDown={handleKeyDown}
                defaultValue={
                  params.data.trackQuantity ? params.data.price.quantity : ""
                }
                className="input-textfield input-textfield-without-icon  editable-table-row-fields inventory-editable-row-fields"
                onChange={(e) => {
                  rowBeingEditRef.current = {
                    ...rowBeingEditRef.current,
                    price: {
                      ...rowBeingEditRef.current.price,
                      quantity: e.target.value,
                    },
                  };
                }}
                disabled={
                  params?.data?.serialNumber || !params.data.trackQuantity
                }
              ></input>
            ) : (
              <span className="inventory-cell-max-size">
                {params.data.trackQuantity
                  ? parseToThousandSeperatorWholeNumber(
                      params.data.price.quantity
                    )
                  : ""}
              </span>
            )
          ) : (
            <DropdownSkeleton width="5em" height="1.5em" />
          );
        },
      },
      {
        maxWidth: 150,
        headerName: "",
        field: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        lockPosition: "right",
        cellRenderer: actionCellRenderer,
        // tooltipField: "id",
        // tooltipComponentParams: {
        //   type: "actionTooltip",
        //   color: "#f4f4f4",
        //   className: "actionTooltip",
        //   ActionButtons: ActionButtons,
        // },
        sortable: false,
      },
    ],
    [defaultZebraPrinter, gridState]
  );

  const getRowStyle = (params) => {
    return null;
  };

  //-------Handle searchfield change
  const handleFilterData = () => {
    const filterList = gridData?.filter((row) => {
      return (
        String(row.searchColumnData)
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    return filterList;
  };

  const handleDeleteAllClick = () => {
    setRowsToDeleted(selectedRows);
    toggleConfirmationModal();
  };

  useEffect(() => {
    if (currentStore?.id) {
      if (role !== ROLES.EMPLOYEE) {
        getInventorySummary(currentStore.id);
      }
    }
    // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    return () => {
      emptyAllInventory();
    };
  }, []);

  useEffect(() => {
    if (defaultZebraPrinter) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [defaultZebraPrinter]);

  const filterColumnforExport = (allData) => {
    return allData.map((row) => {
      const exportObject = {
        "Product Name": beautifyStringForImport(row.product_name),
        "Product Type": row.productType,
        Category: row.category_name,
        Subcategory: row.subcategory,
        Condition: row.price.type,
        "Serial Number": row.serialNumber,
        Quantity: Number(row.price.quantity),
        "Cost of Goods": row.price.unit_purchase_price,
        "Sell Price": row.price.unit_sell_price,
        SKU: exportStringArrayFunc(row.sku),
        UPC: exportUpcFunc(row.upc),
        Tags: exportTagFunc(row.tags),
      };
      return exportObject;
    });
  };

  useEffect(() => {
    if (ClearSelectedRows) {
      setclearSelectedRows(false);
    }
  }, [ClearSelectedRows]);
  useEffect(() => {
    setAddInventoryItemModal(isAddModalOpen);
  }, [isAddModalOpen]);

  const gridData = useMemo(() => {
    let filteredData = allInventory?.inventory || [];
    setGridState({
      ...gridState,
      pageIndex: allInventory?.pageNumber || defaultGridState?.pageIndex,
      totalRecords: allInventory.totalRecords || defaultGridState.totalRecords,
      pageEndRecordNumber:
        allInventory.pageEndRecordNumber ||
        defaultGridState.pageEndRecordNumber,
      pageStartRecordNumber:
        allInventory.pageStartRecordNumber ||
        defaultGridState.pageStartRecordNumber,
    });
    setIsSearchLoading(false);

    return filteredData;
  }, [allInventory]);
  const clearSearchText = () => {
    handleSearchInputChange("");
  };

  const handleSearchInputChange = (value) => {
    setIsSearchLoading(true);
    setDebouncedSearchText(value);
    handleDebouncedSearch(value);
  };

  const handleDebouncedSearch = useDebouncedCallback((value) => {
    // setSearchText(value);
    updateGridState({ ...gridState, searchQuery: value, pageIndex: 1 });
  }, 1000);

  const onSelectionChanged = (data) => {
    const selectedNodes = data.api.getSelectedRows();
    setSelectedRows(selectedNodes);
  };

  const handleScan = (data) => {
    handleSearchInputChange(data);
  };

  const handleError = () => {};

  const onCellClicked = useCallback((params) => {
    if (
      params.column.colId === "id" ||
      params.data.id === rowBeingEditRef?.current?.id
    ) {
    } else {
      if (rowBeingEditParamRef.current?.data?.id) {
        handleRefreshCells(rowBeingEditParamRef.current);
        redrawTopRows(rowBeingEditParamRef.current);
      }
      rowBeingEditParamRef.current = params;
      rowBeingEditRef.current = {
        ...params.data,
        originalQuantity: params.data.price.quantity,
        originalPrice: params.data.price.unit_sell_price,
      };
      handleRefreshCells(params);

      redrawTopRows(params);
    }
  }, []);

  useEffect(() => {
    setSearchProductType(
      getDefaultFilterType(
        customTypes,
        gridState?.productType || PRODUCT_TYPES_ENUMS.ALL
      )
    );
  }, [customTypes]);

  useEffect(() => {
    if (currentStore?.id) {
      updateGridState({
        ...gridState,
        pageIndex: 1,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
        productType: searchProductType?.label,
        productCategory: searchProductCategory?.label,
        productSubCategory: searchProductSubCategory?.label,
      });
    }
  }, [
    currentStore,
    searchProductType,
    searchProductCategory,
    searchProductSubCategory,
  ]);
  const skeletonArray = Array(10).fill({});
  return (
    <>
      {!isBatchInventoryModal && !addInventoryItemModal && (
        <div>
          <BarcodeReader onError={handleError} onScan={handleScan} />
        </div>
      )}
      <Topbar title="Inventory Details"></Topbar>
      <Row className="m-0">
        <Col
          md={4}
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <Button
            label={buttonNameConstants.EXPORT_CSV}
            IconImage={ExportCSVIcon}
            className="import-export-button"
            handleClick={() => handleExport()}
          />
          <Button
            label={buttonNameConstants.IMPORT_CSV}
            IconImage={ImportCSVIcon}
            handleClick={() => toggleImportInventoryModal()}
          />
        </Col>
        <Col md={3}></Col>
        <Col
          md={5}
          className="d-flex justify-content-end align-items-center gap-2"
        >
          <Button
            label={buttonNameConstants.ADD_BATCH}
            IconImage={AddIcon}
            handleClick={() => {
              setIsBatchInventoryModal(!isBatchInventoryModal);
            }}
            buttonType={buttonTypeConstants.LIGHT_PURPLE}
          />
          <Button
            label={buttonNameConstants.ADD_INVENTORY}
            IconImage={AddIcon}
            handleClick={handleAddIventoryItemClick}
          />
        </Col>
        <Col
          md={12}
          className="inventory-overview-card-wrapper d-flex flex-wrap gap-3"
        >
          {role !== ROLES.EMPLOYEE && (
            <>
              {!isSpinnerEnabled(
                spinnerArray,
                inventoryConstants.INVENTORY_HISTORY
              ) ? (
                <>
                  <InventoryOverviewCard
                    label="In Stock Quantity"
                    data={
                      storeInventorySummary
                        ? parseToThousandSeperatorWholeNumber(
                            storeInventorySummary?.quantity
                          )
                        : 0
                    }
                  />

                  <InventoryOverviewCard
                    label="Total Price"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      storeInventorySummary ? storeInventorySummary?.sale : 0
                    )}`}
                  />

                  <InventoryOverviewCard
                    label="Total Net"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      storeInventorySummary?.sale -
                        storeInventorySummary?.purchase || 0
                    )}`}
                  />
                  <InventoryOverviewCard
                    label="Total Cost"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      storeInventorySummary
                        ? storeInventorySummary?.purchase
                        : 0
                    )}`}
                  />
                </>
              ) : (
                <>
                  <SummaryCardSkeleton />
                  <SummaryCardSkeleton />
                  <SummaryCardSkeleton />
                  <SummaryCardSkeleton />
                </>
              )}
            </>
          )}
        </Col>

        <Col md={6}>
          <div>
            <SearchField
              isFocus={true}
              onChange={(e) => handleSearchInputChange(e.target.value)}
              IconImage={SearchIcon}
              label="Search"
              placeHolder="Type or Scan"
              value={debouncedSearchText}
              CrossIcon={debouncedSearchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
              BlueBoxIcon={BlueBoxIcon}
              LoadingIndicator={
                isSearchLoading ||
                (isButtonDisable(
                  spinnerArray,
                  inventoryConstants.GET_ALL_INVENTORY
                ) &&
                  searchText)
                  ? LoadingIndicator
                  : false
              }
            />
          </div>
        </Col>
        <Col md={6} className="ps-0">
          <div className={`w-100`}>
            <FilterDropdowns
              customTypes={customTypes}
              customCategories={customCategories}
              customSubCategories={customSubCategories}
              setSearchProductType={setSearchProductType}
              setSearchProductCategory={setSearchProductCategory}
              setSearchProductSubCategory={setSearchProductSubCategory}
              defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
              isDisabledAll={isButtonDisable(
                spinnerArray,
                inventoryConstants.GET_ALL_INVENTORY
              )}
              gridState={gridState}
            />
          </div>
        </Col>
      </Row>
      {!isBatchInventoryModal &&
        !addInventoryItemModal &&
        !importInventoryModal && (
          <Row
            className="m-0 w-100 h-100 user-screen-wrapper pt-3"
            onMouseLeave={() => {
              tooltipRef.current?.close();
            }}
          >
            <ServerPaginationTable
              colDefs={colDefs}
              gridRef={gridRef}
              gridData={
                isSpinnerEnabled(spinnerArray, [
                  inventoryConstants.GET_ALL_INVENTORY,
                  inventoryConstants.UPDATE_INVENTORY,
                ])
                  ? skeletonArray
                  : gridData
              }
              getRowId={getRowId}
              gridState={gridState}
              getRowStyle={getRowStyle}
              selectedRows={selectedRows}
              onCellClicked={onCellClicked}
              rowClassRules={rowClassRules}
              defaultColDef={defaultColDef}
              updateGridState={updateGridState}
              onSelectionChanged={onSelectionChanged}
              paginationPageSize={gridState.pageSize}
              handleDeleteAllClick={handleDeleteAllClick}
              pageSizeOptions={gridState.pageSizeOptions}
              handlePrintLabelClick={handlePrintLabelClick}
              isDisablePaginationActions={isButtonDisable(
                spinnerArray,
                inventoryConstants.GET_ALL_INVENTORY
              )}
              tableHeight={`calc(100vh - ${
                role !== ROLES.EMPLOYEE && !selectedRows?.length
                  ? "350"
                  : role !== ROLES.EMPLOYEE && selectedRows?.length
                  ? "395"
                  : role === ROLES.EMPLOYEE && !selectedRows?.length
                  ? "290"
                  : "335"
              }px)`}
              isDataLoading={isSpinnerEnabled(spinnerArray, [
                inventoryConstants.GET_ALL_INVENTORY,
                inventoryConstants.UPDATE_INVENTORY,
              ])}
            />
          </Row>
        )}
      {addInventoryItemModal && (
        <AddInventoryItem
          customTypes={customTypes}
          addCustomItem={addCustomItem}
          customCategories={customCategories}
          handleClearFilter={handleClearFilter}
          customSubCategories={customSubCategories}
          addInventoryItemModal={addInventoryItemModal}
          toggleAddInventoryItemModal={toggleAddInventoryItemModal}
        />
      )}
      {isBatchInventoryModal && !isDraftConfirmationModal && (
        <AddBatchInventory
          customTypes={customTypes}
          handleClearFilter={handleClearFilter}
          customCategories={customCategories}
        />
      )}
      <PrintLabelModal
        printLabelModal={printLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={selectedRows}
        singlePrintArray={singlePrintArray}
      />
      <ImportInventory
        currentStore={currentStore}
        importInventoryModal={importInventoryModal}
        importCsvFileInventory={importCsvFileInventory}
        toggleImportInventoryModal={toggleImportInventoryModal}
      />
      <ShrinkItemModal
        itemShrinkModal={itemDeleteShrinkModal}
        handleNoButtonClick={handleDeleteNoButtonClick}
        handleYesButtonClick={handleDeleteYesButtonClick}
        toggleItemShrinkModal={toggleItemDeleteShrinkModal}
      />
      <ShrinkItemModal
        itemShrinkModal={itemEditShrinkModal}
        handleNoButtonClick={handleEditNoButtonClick}
        handleYesButtonClick={handleEditYesButtonClick}
        toggleItemShrinkModal={toggleItemEditShrinkModal}
      />
      <ConfirmationModal
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
};
//-------Inventory Items component ends here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  role: state.authentication.user.role,
  spinnerArray: state.system.spinnerArray,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  isAddModalOpen: state.inventory.isAddModalOpen,
  isBatchInventoryModal: state.inventory.isBatchInventoryModal,
  isDraftConfirmationModal: state.transaction.isDraftConfirmationModal,
  defaultZebraPrinter: state.printer.defaultZebraPrinter,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPaginatedInventory: (storeId, gridState) =>
    dispatch(inventoryActions.getPaginatedInventory(storeId, gridState)),
  getInventorySummary: (storeId) =>
    dispatch(inventoryActions.getStoreInventorySummary(storeId)),
  deleteInventory: (data, storeId, gridState) =>
    dispatch(inventoryActions.deleteInventory(data, storeId, gridState)),
  toggleAddModal: (value) => dispatch(inventoryActions.toggleAddModal(value)),
  quantityShrink: (data) => dispatch(inventoryActions.quantityShrink(data)),
  addCustomItem: (data, toastMessage, handleHideModal) =>
    dispatch(
      inventoryActions.addCustomItem(data, toastMessage, handleHideModal)
    ),
  updateInventory: (storeId, data, isLabelNotification, gridState) =>
    dispatch(
      inventoryActions.updateInventory(
        storeId,
        data,
        isLabelNotification,
        gridState
      )
    ),
  importCsvFileInventory: (data, response) =>
    dispatch(inventoryActions.importCsvFileInventory(data, response)),
  emptyAllInventory: () => dispatch(inventoryActions.emptyAllInventory()),
  updateProductTypes: (storeId, data, handleHideModal) =>
    dispatch(
      inventoryActions.updateProductTypes(storeId, data, handleHideModal)
    ),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  setIsBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type: inventoryConstants.SET_IS_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  activateImportInventorySpinner: (data) =>
    dispatch(inventoryActions.activateImportInventorySpinner(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Items);
