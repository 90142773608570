export const categoriesDropdownStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: "3em",
    borderRadius: "0.75em",
    boxShadow: "none",
    backgroundColor: "#fcfafc",
    cursor: "text !important",
    borderBottom: state.isFocused ? "2px solid #6a18ed" : "2px solid#eae8ec",
    borderColor: state.isFocused ? "#6a18ed" : "#eae8ec",
    "&:hover": {
      border: "1px solid #6a18ed",
      borderBottom: "2px solid #6a18ed",
      "dropdown-icon": {
        color: "red",
      },
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: "9999",
    borderRadius: ".75em",
    overflow: "hidden",
    padding: ".75em",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "20em",
    zIndex: "9999",
    "::-webkit-scrollbar": {
      width: ".3em",
      height: ".3em",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#b7b5ba",
      borderRadius: "100px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#6a18ed",
    },
  }),
  option: (base, state) => ({
    ...base,
    borderRadius: "0.5em",
    marginBottom: ".25em",
    marginTop: ".25em",
    backgroundColor: state.isSelected ? "#6a18ed" : "transparent",
    color: state.isSelected ? "#fcfcfc" : "#6b6970",
    "&:hover": {
      color: "#222024",
      backgroundColor: "rgb(106, 24, 237, 0.3)",
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .3s",
    color: state.isFocused ? "#6a18ed" : "#6b6970",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      opacity: "0.5",
      paddingLeft: "2em",
      position: "absolute",
    };
  },
  input: (provided) => ({
    ...provided,
    maxWidth: "8em",
    overflow: "hidden",
    position: "relative",
    marginLeft: "2em", // Space for the icon
  }),
  singleValue: (provided) => ({
    ...provided,
    maxWidth: "8em",
    overflow: "hidden",
    position: "absolute",
    marginLeft: "2em",
    paddingRight: ".1em",
  }),
  clearIndicator: (provided, state) => ({
    ...provided, // Change color on focus
    "&:hover": {
      color: "#6a18ed", // Change color on hover
    },
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: ".3em",
    paddingLeft: ".3em",
    div: {
      padding: "0px",
    },
  }),
};
