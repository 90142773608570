import React from "react";

import IconSelectField from "../../../shared/components/selectField/IconSelectField";
import { getCustomCategoriesOptionList } from "../../../shared/utility";
import { globalConstants } from "../../../constants";

const CustomItemCategorySelectField = ({
  setFieldValue,
  customCategories,
  values,
  isDisabled = false,
  ...props
}) => {
  const handleCategoryChange = (option, setFieldValue) => {
    setFieldValue("category", option.value);
    setFieldValue("subCategory", globalConstants.EMPTY_STRING);
  };

  return (
    <IconSelectField
      label="Category"
      options={getCustomCategoriesOptionList(customCategories, values.type)}
      name="category"
      placeHolder="Category..."
      isClearable={false}
      isDisabled={isDisabled}
      customOnChange={(option) => handleCategoryChange(option, setFieldValue)}
    />
  );
};

export default CustomItemCategorySelectField;
