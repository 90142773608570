import { Country, State, City } from "country-state-city";
import { countryCodesConstants } from "./global.constant";

export const customerConstants = {
  GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
  GET_PAGINATION_CUSTOMERS: "GET_PAGINATION_CUSTOMERS",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  BULK_UPLOAD_CUSTOMERS: "BULK_UPLOAD_CUSTOMERS",
  EMPTY_CUSTOMER_BY_ID: "EMPTY_CUSTOMER_BY_ID",
  GET_CUSTOMER_BY_ID: "GET_CUSTOMER_BY_ID",
  ADD_NEW_CUSTOMER_BUTTON: "ADD_NEW_CUSTOMER_BUTTON",
  ADD_PRODUCT_NOTIFICATION: "ADD_PRODUCT_NOTIFICATION",
  SET_CUSTOMER_DROPDOWN_KEY: "SET_CUSTOMER_DROPDOWN_KEY",
  SET_IS_CUSTOMER_LOGS_MODAL: "SET_IS_CUSTOMER_LOGS_MODAL",
  CUSTOMERS_IMPORTED_SUCCESSFULLY: " Customers were successfully imported",
  CUSTOMERS_NOT_IMPORTED: " Customers were not imported.",
  ID_TYPES: [
    { value: "US Driver License", label: "US Driver’s License" },
    { value: "Other", label: "Other" },
  ],
  DRIVING_LICENSE_SUBSTRING: [
    "@",
    "ANSI",
    "DAC",
    "DCS",
    "DAD",
    "DBA",
    "DBD",
    "DBB",
    "DBC",
    "DAY",
    "DAU",
    "DAG",
    "DAI",
    "DAJ",
    "DAK",
    "DAQ",
    "DCF",
    "DCG",
    "DDG",
    "DDF",
    "DDE",
    "DAH",
    "DAZ",
    "DCI",
    "DCJ",
    "DCK",
    "DBN",
    "DBG",
    "DBS",
    "DCU",
  ],
  VALIDATE_SUBSTRING: [
    "DAC",
    "DCS",
    "DBB",
    "DBC",
    "DAY",
    "DAU",
    "DAG",
    "DAI",
    "DAJ",
    "DAK",
    "DAQ",
    "DAZ",
  ],
  STATE_OPTIONS: [
    ...State.getStatesOfCountry(countryCodesConstants.USA),
    ...State.getStatesOfCountry(countryCodesConstants.CANADA),
  ].map((state) => ({
    label: state.name,
    value: state.name,
  })),
  CITY_OPTIONS: [
    ...City.getCitiesOfCountry(countryCodesConstants.USA),
    ...City.getCitiesOfCountry(countryCodesConstants.CANADA),
  ].map((city) => ({ label: city.name, value: city.name })),
};
