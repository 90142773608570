import React, { useState, useRef, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../shared/components/inputTextField/InputTextField";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import BarcodeReader from "react-barcode-reader";
import { City, State } from "country-state-city";
import { Parse, IsExpired, GetVersion } from "aamva-parser";

import ScanIdModal from "../CustomerModals/ScanIdModal";
import { inventoryService } from "../../../services";
import { useLocation, useNavigate } from "react-router";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import pokemanPic from "../../../assets/images/pokemanPic.svg";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import SelectField from "../../../shared/components/selectField/SelectField";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";
import InputTextArea from "../../../shared/components/inputTextArea/InputTextArea";
import SearchInventory from "../../Inventory/Items/SearchInventory/SearchInventory";
import SelectFieldCity from "../../../shared/components/selectField/SelectFieldCity";
import InputNumberField from "../../../shared/components/inputNumberField/InputNumberField";
import InputGenericField from "../../../shared/components/inputGenericField/InputGenericField";
import { addCustomerValidationSchema } from "../../../shared/validationSchema/validationSchema";
import {
  toastType,
  EMPTY_STRING,
  toastMessages,
  globalConstants,
  customerConstants,
  routesPathConstants,
  buttonNameConstants,
  buttonTypeConstants,
  countryCodesConstants,
  LOGS_TYPES,
} from "../../../constants";
import {
  systemActions,
  customerActions,
  logErrorsActions,
} from "../../../redux/actions";
import {
  CopyIcon,
  DeleteIcon,
  DollarIcon,
} from "../../../assets/icons/iconsProvider";
import {
  customToast,
  isSpinnerEnabled,
  capitalizeEveryWord,
  parseToDecimalNumber,
  parseLiscenceScannedData,
  handleCustomerLicenseScanError,
} from "../../../shared/utility";
import CustomerLogsModal from "../CustomerModals/CustomerLogsModal";
import { success } from "../../../redux/actions/actionsUtilities";
const Customers = (props) => {
  //-------useref
  const asyncSelectRef = useRef();
  const {
    store,
    user,
    logErrors,
    spinnerArray,
    addNewCustomer,
    updateCustomer,
    activateSpinner,
    itemOrganization,
    deactivateSpinner,
    setIsCustomerLogsModal,
  } = props;

  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;
  const formikRef = useRef();
  const { currentStore } = store;
  const [copiedSku, setCopiedSku] = useState(null);
  const { state } = useLocation();
  const [isScanning, setIsScanning] = useState(false);
  const [isShowScanButton, setIsShowScanButton] = useState(true);
  const [notificationAlert, setNotificationAlert] = useState({
    isSmsAlert: state?.data?.isSmsAlert || false,
    isEmailAlert: state?.data?.isEmailAlert || false,
  });
  const navigate = useNavigate();
  const initialValues = {
    firstName: state?.data?.firstName || globalConstants.EMPTY_STRING,
    lastName: state?.data?.lastName || globalConstants.EMPTY_STRING,
    email: state?.data?.email || globalConstants.EMPTY_STRING,
    mobile: state?.data?.mobile || globalConstants.EMPTY_STRING,
    driverLicense: state?.data?.driverLicense || globalConstants.EMPTY_STRING,
    line1: state?.data?.line1 || globalConstants.EMPTY_STRING,
    line2: state?.data?.line2 || globalConstants.EMPTY_STRING,
    city: state?.data?.city || globalConstants.EMPTY_STRING,
    state: state?.data?.state || globalConstants.EMPTY_STRING,
    zip: state?.data?.zip || globalConstants.EMPTY_STRING,
    currentBalance: parseToDecimalNumber(
      state?.data?.currentBalance || 0,
      true
    ),
    companyCustomerId: state?.data?.companyCustomerId || 0,
    note: state?.data?.note || EMPTY_STRING,
    driverLicenseIdType:
      state?.data?.driverLicenseIdType || customerConstants.ID_TYPES[0].value,
  };
  const handleCancelButton = () => {
    navigate(routesPathConstants.CUSTOMERS);
  };
  const handleSubmit = (data) => {
    if (state?.isEdit) {
      updateCustomer({
        ...data,
        id: state?.data?.id,
        isSmsAlert: notificationAlert.isSmsAlert,
        isEmailAlert: notificationAlert.isEmailAlert,
        productNotification: productNotification,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        companyId: currentStore?.companyId,
      });
    } else {
      addNewCustomer({
        ...data,
        isSmsAlert: notificationAlert.isSmsAlert,
        isEmailAlert: notificationAlert.isEmailAlert,
        productNotification: productNotification,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        companyId: currentStore?.companyId,
      });
    }
  };
  const handleTextSwitchChange = (id, value) => {
    setNotificationAlert({ ...notificationAlert, isSmsAlert: value });
  };

  const handleEmailSwitchChange = (id, value) => {
    setNotificationAlert({ ...notificationAlert, isEmailAlert: value });
  };
  const [productNotification, setProductNotification] = useState(
    state?.data?.productNotification || []
  );
  const handleDelete = (cardId) => {
    setProductNotification(
      productNotification.filter((card) => card.product_id !== cardId)
    );
  };

  //-------handle copy sku number to clipboard
  const handleCopyToClipboard = (skuName) => {
    const textArea = document.createElement("textarea");
    textArea.value = skuName;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedSku(skuName);
    setTimeout(() => {
      setCopiedSku(null);
    }, 3000);
  };

  const handleScan = (scannedData) => {
    // const datae = `@ANSI 636014090102DL00410292ZC03330024DLDAQY5597134DCSBAINSDDENDACSIMRANDDFNDADSINGHDDGNDCACDCBNONEDCDNONEDBD12082020DBB01012000DBA01012025DBC1DAU076 INDAYBRODAG1234 MAIN STDAISAN JOSEDAJCADAK951480000  DCF12/08/202066815/AAFD/25DCGUSADAW205DAZBLKDCK20343Y55971340401DDAFDDB08292017DDJ11012021DDK1ZCZCABRNZCBBLKZCCZCD`;
    const preparedData = parseLiscenceScannedData(scannedData, IsExpired);

    if (preparedData.isDataParsed) {
      const usLicenseData = Parse(preparedData.data);

      const state = State.getStatesOfCountry(countryCodesConstants.USA).find(
        (state) => state.isoCode === usLicenseData.state
      );

      formikRef.current.setValues((prevVal) => ({
        ...prevVal,
        city: capitalizeEveryWord(usLicenseData.city),
        lastName: usLicenseData.lastName,
        state: state?.name,
        firstName: usLicenseData.firstName,
        line1: usLicenseData.streetAddress,
        zip: usLicenseData.postalCode?.slice(0, 5),
        driverLicense: usLicenseData.driversLicenseId,
      }));
      toggleScanningModal();
    } else {
      const errorMessgae = `scannedPayload: ${scannedData} , error Type: ${preparedData.data}`;
      handleCustomerLicenseScanError(
        user,
        errorMessgae,
        logErrors,
        currentStore
      );
    }
  };

  const toggleScanningModal = () => {
    setIsScanning(!isScanning);
  };

  const handleError = () => {};

  const handleIdTypeChange = (value) => {
    if (value === customerConstants?.ID_TYPES[1].value) {
      setIsShowScanButton(false);
    } else {
      setIsShowScanButton(true);
    }
  };

  const handleLicenseScanning = () => {
    toggleScanningModal();
  };

  //-------Add New Product to Cart
  const handleMenuProductClick = async (product) => {
    activateSpinner(customerConstants.ADD_RPODUCT_NOTIFICATION);
    const isProductAlreadyAdded = productNotification.some(
      (card) => card.product_id === product.product_id
    );

    if (isProductAlreadyAdded) {
      customToast(toastMessages.PRODUCT_ALREADY_ADDED, toastType.ERROR);
    } else {
      const inventoryProducts = await inventoryService
        .getInventoryByPricechartingId(currentStore?.id, {
          id: product.product_id,
          categoryName: product.category_name,
          productName: product.product_name,
        })
        .then(
          (response) => {
            return response;
          },
          (error) => {
            customToast(error, toastType.ERROR);
          }
        );

      if (inventoryProducts?.length > 0) {
        const sumOfQuantities = inventoryProducts?.reduce((total, product) => {
          return total + Number(product.price.quantity);
        }, 0);
        if (sumOfQuantities === 0) {
          setProductNotification([...productNotification, product]);
        } else {
          customToast(
            toastMessages.PRODUCT_ALREADY_FOUND_IN_INVENTORY,
            toastType.ERROR
          );
        }
      } else {
        setProductNotification([...productNotification, product]);
      }
    }
    deactivateSpinner(customerConstants.ADD_RPODUCT_NOTIFICATION);
  };

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      navigate(routesPathConstants.CUSTOMERS);
    }
  }, [currentStore]);

  return (
    <>
      {isScanning && (
        <div>
          <BarcodeReader onError={handleError} onScan={handleScan} />
        </div>
      )}
      <Topbar
        title={state?.isEdit ? "Edit Customer " : "New Customer"}
        exportButton={isShowScanButton}
        label={buttonNameConstants.SCAN_ID}
        handleClick={handleLicenseScanning}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.CUSTOMERS,
        }}
        isGhostButton={{
          isVisible: state?.isEdit,
          handleButtonClick: () => {
            setIsCustomerLogsModal(true);
          },
          buttonLabel: buttonNameConstants.ACTIVITY_LOG,
        }}
      ></Topbar>
      <Formik
        initialValues={initialValues}
        validate={addCustomerValidationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ values }) => (
          <Form className="">
            <Row className="m-0 w-100 container-scrollbar pe-2 user-info-wrapper">
              <Col md={8} className="">
                <Row className="m-0  p-0">
                  <Row className="p-0">
                    <p className="form-portion-heading">Personal Information</p>
                    <Col md={6} className="mb-3 ">
                      <InputTextField
                        name="firstName"
                        label="First Name"
                        placeHolder="First Name"
                      />
                    </Col>
                    <Col md={6} className="mb-3 ">
                      <InputTextField
                        name="lastName"
                        label="Last Name"
                        placeHolder="Last Name"
                      />
                    </Col>
                    <Col md={6} className="mb-3 ">
                      <InputTextField
                        name="email"
                        label="Email"
                        placeHolder="Email"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <InputGenericField
                        name="mobile"
                        type="tel"
                        placeHolder="Phone Number"
                        label="Phone Number"
                        inputMask="(999) 999-9999"
                      />
                    </Col>
                    <Col md={6}>
                      <SelectField
                        label="ID Type"
                        name="driverLicenseIdType"
                        placeHolder={"Select ID Type"}
                        options={customerConstants.ID_TYPES}
                        isSearchable={false}
                        onChangeValue={handleIdTypeChange}
                      />
                    </Col>
                    <Col md={6} className="mb-3  ">
                      <InputTextField
                        name="driverLicense"
                        label="ID Number"
                        placeHolder="ID Number"
                      />
                    </Col>
                  </Row>
                  <Row className="p-0 mt-3">
                    <p className="form-portion-heading ">Address</p>
                    <Col md={6} className="mb-3 ">
                      <InputTextField
                        name="line1"
                        label="Street 1"
                        placeHolder="Enter Street 1"
                      />
                    </Col>
                    <Col md={6} className="mb-3 ">
                      <InputTextField
                        name="line2"
                        label="Street 2"
                        placeHolder="Enter Street 2"
                      />
                    </Col>
                    <Col md={6} className="mb-3 ">
                      <SelectFieldCity
                        cityValue={
                          values?.city
                            ? { value: values.city, label: values.city }
                            : ""
                        }
                        defaultValue={state?.data?.city}
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      {/* <SelectFieldState /> */}
                      <SelectField
                        label="State"
                        name="state"
                        placeHolder={"Select State"}
                        options={customerConstants.STATE_OPTIONS}
                      />
                    </Col>
                    <Col md={6} className="mb-3  ">
                      <InputTextField
                        name="zip"
                        placeHolder="00000"
                        label="Zip"
                      />
                    </Col>
                  </Row>
                  <Row className="p-0 mt-3">
                    <p className="form-portion-heading ">Notes</p>
                    <Col md={12} className="mb-3 ">
                      <InputTextArea
                        maxLength={1500}
                        name="note"
                        placeHolder="Enter Note"
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={8} className="mt-4 mb-3 pb-5  d-flex gap-2 ">
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                        className="w-100"
                        handleClick={() => {
                          handleCancelButton();
                        }}
                        isDisabled={
                          isSpinnerEnabled(
                            spinnerArray,
                            customerConstants.ADD_CUSTOMER
                          ) ||
                          isSpinnerEnabled(
                            spinnerArray,
                            customerConstants.UPDATE_CUSTOMER
                          )
                        }
                      ></Button>
                      <Button
                        type="submit"
                        className="w-100"
                        label={
                          state?.isEdit
                            ? buttonNameConstants.UPDATE
                            : buttonNameConstants.SAVE
                        }
                        isDisabled={
                          isSpinnerEnabled(
                            spinnerArray,
                            customerConstants.ADD_CUSTOMER
                          ) ||
                          isSpinnerEnabled(
                            spinnerArray,
                            customerConstants.UPDATE_CUSTOMER
                          )
                        }
                      ></Button>
                    </Col>
                  </Row>
                  {/* <Col md={7}></Col> */}
                </Row>
              </Col>
              <Col md={4}>
                <Row className="p-0">
                  <p className="form-portion-heading p-0">Customer Credit</p>
                  <Col md={12} className="mb-3 p-0">
                    <InputNumberField
                      name="currentBalance"
                      label="Customer Credit"
                      placeHolder="Customer Credit"
                      IconImage={DollarIcon}
                    />
                  </Col>
                </Row>
                {!isScanning && (
                  <>
                    <p className="form-portion-heading mt-5 p-0">
                      Product Notifications
                    </p>
                    <Row className="Notification-Container">
                      <Col md={4} className="p-0 d-flex align-items-center">
                        <p className="alert-setting-text m-0">
                          Alert Settings:
                        </p>
                      </Col>
                      <Col className="p-0 mt-3 mt-md-0 d-flex justify-content-start justify-content-md-end gap-3 align-items-center">
                        <SwitchButton
                          onSwitchChange={handleTextSwitchChange}
                          label={"Text"}
                          id="textSwitch"
                          isSwitchOn={notificationAlert.isSmsAlert}
                          isDisabled={!values.mobile}
                        />
                        <SwitchButton
                          onSwitchChange={handleEmailSwitchChange}
                          label={"Email"}
                          id="emailSwitch"
                          isSwitchOn={notificationAlert.isEmailAlert}
                          isDisabled={!values.email}
                        />
                      </Col>
                      {/* <Col className="p-0"></Col> */}
                      <Col md={12} className="p-0">
                        <hr className="mt-3" />
                      </Col>
                      <Col md={12} className="p-0">
                        <SearchInventory
                          asyncSelectRef={asyncSelectRef}
                          isFilterRequire={false}
                          handleMenuProductClick={handleMenuProductClick}
                          dropdownIndicator={false}
                          placeHolder="Add Product By Search"
                          defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
                          customTypes={customTypes}
                          customCategories={customCategories}
                        />
                      </Col>
                      <Col
                        md={12}
                        className="p-0 mt-2 notification-card-div container-scrollbar "
                      >
                        {productNotification?.map((card) => (
                          <div
                            key={card.product_id}
                            className="Pokeman-Card-Div"
                          >
                            <Col md={2} className="img-col">
                              <img
                                src={card.imgUrl || pokemanPic}
                                alt={card.product_name}
                              />
                            </Col>
                            <Col className="ps-3">
                              <p className="m-0 sku-name">
                                {card.product_name}
                              </p>
                              <p className="m-0 d-flex justify-content-start align-items-center my-1">
                                <span className="sku-number">
                                  {card.product_id}
                                </span>
                                <button
                                  type="button"
                                  className="cursor-pointer copy-button"
                                  onClick={() =>
                                    handleCopyToClipboard(card.product_id)
                                  }
                                >
                                  <CopyIcon className="icon-image " />
                                </button>

                                {copiedSku === card.product_id && (
                                  <span>Copied!</span>
                                )}
                              </p>
                              <p className="m-0 sku-type">{`${card.productType} [ ${card.category_name} ]`}</p>
                            </Col>
                            <Col
                              md={1}
                              className="cursor-pointer card-delete-button"
                              onClick={() => handleDelete(card.product_id)}
                            >
                              <DeleteIcon className="icon-image ms-0" />
                            </Col>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <ScanIdModal
        isScanning={isScanning}
        toggleScanningModal={toggleScanningModal}
      />
      {state?.isEdit && <CustomerLogsModal customer={state?.data} />}
    </>
  );
};
// reducer
const mapStateToProps = (state) => ({
  store: state.store,
  customer: state.customer,
  user: state.authentication.user,
  spinnerArray: state.system.spinnerArray,
  itemOrganization: state.itemOrganization,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  logErrors: (data) => dispatch(logErrorsActions.logErrors(data)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  addNewCustomer: (data) =>
    dispatch(customerActions.addNewCustomer(data, true)),
  updateCustomer: (data) =>
    dispatch(customerActions.updateCustomer(data, true)),
  setIsCustomerLogsModal: (data) =>
    dispatch(success(data, customerConstants.SET_IS_CUSTOMER_LOGS_MODAL)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
