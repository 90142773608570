import React from "react";

import DropdownSkeleton from "../../Skeletons/DropdownSkeleton/DropdownSkeleton";

const CustomCellRendererWithSkeleton = (props) => {
  const {
    params,
    cellComponent,
    componentRenderCondition = params?.data?.id,
  } = props;

  return componentRenderCondition ? (
    cellComponent
  ) : (
    <DropdownSkeleton width="5em" height="1.5em" />
  );
};

export default CustomCellRendererWithSkeleton;
