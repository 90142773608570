import React from "react";

import SalesSummaryDatarow from "./SalesSummaryDatarow";
import { parseToThousandSeperatorDecimalNumber } from "../../utility";

const SalesSummaryDataTable = ({ atAGlance }) => {
  return (
    <>
      <SalesSummaryDatarow
        dataRowType={1}
        title="Sales"
        value={""}
        className="mt-3"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Gross Sales"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.grossSales || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Returns"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalReturns || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Discounts"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalDiscounts || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Net Sales"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.netSales || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Taxes"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalTaxes || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Gift Card Sales"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.giftCardSales || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Store Credit Payments"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalStorecreditPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading"
      />
      <SalesSummaryDatarow
        dataRowType={1}
        title="Total Sales"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalSales || 0
        )}`}
        className=""
      />{" "}
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total Sales Transactions"
        value={atAGlance?.totalSaleTransactions || 0}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Average Order Value"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.averageOrderValue || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total COGS"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalCogs || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={1}
        title="Total Profit"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalProfit || 0
        )}`}
        className=""
      />{" "}
      <SalesSummaryDatarow
        dataRowType={1}
        title="Trade-Ins"
        value={``}
        className=""
      />{" "}
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total Trade Transaction"
        value={atAGlance?.totalTradeTransactions || 0}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total Trade-In Stock Price"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalTradeInStockPrice || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total Trade-In Purchases"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalTradeInPurchases || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total Store Credit Payments"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalTradeStorecreditPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading ms-4"
      />
      <SalesSummaryDatarow
        dataRowType={1}
        title="Total Cash Payments"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalTradeCashPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading ms-4"
      />
      <SalesSummaryDatarow
        dataRowType={1}
        title="Payments"
        value={``}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Total Collected"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalPayments || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Cash"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalCashPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading ms-4"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Credit/Debit Card"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalCreditCardPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading ms-4"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Gift Card"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalGiftcardPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading ms-4"
      />
      <SalesSummaryDatarow
        dataRowType={3}
        title="Store Credit"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.totalStorecreditPayments || 0
        )}`}
        className=""
        fontClassName="sales-summary-detail-heading ms-4"
      />{" "}
      <SalesSummaryDatarow
        dataRowType={1}
        title="Fees"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.fees || 0
        )}`}
        className=""
      />
      <SalesSummaryDatarow
        dataRowType={1}
        title="Net Total"
        value={`$${parseToThousandSeperatorDecimalNumber(
          atAGlance?.netTotal || 0
        )}`}
        className=""
      />
    </>
  );
};

export default SalesSummaryDataTable;
