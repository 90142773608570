import React, { useState } from "react";
import { connect } from "react-redux";

import {
  SearchIcon,
  CrossIcon,
  BlueBoxIcon,
} from "../../../assets/icons/iconsProvider";
import {
  customerConstants,
  EMPTY_STRING,
  systemConstants,
} from "../../../constants";
import { isButtonDisable, LoadingIndicator } from "../../utility";
import { useDebouncedCallback } from "use-debounce";
import SearchField from "../searchField/SearchField";

const ServerSearchTable = (props) => {
  const {
    gridState,
    spinnerArray,
    updateGridState,
    serverCallConstant,
    isServerSearchLoading,
    setIsServerSearchLoading,
  } = props;
  const [debouncedSearchText, setDebouncedSearchText] = useState(EMPTY_STRING);

  const handleDebouncedSearch = useDebouncedCallback((value) => {
    updateGridState({ ...gridState, searchQuery: value, pageIndex: 1 });
  }, 1000);
  const handleSearchInputChange = (value) => {
    setIsServerSearchLoading(true);
    setDebouncedSearchText(value);
    handleDebouncedSearch(value);
  };
  const clearSearchText = () => {
    setDebouncedSearchText("");
    updateGridState({ ...gridState, searchQuery: "" });
  };

  return (
    <SearchField
      onChange={(e) => handleSearchInputChange(e.target.value)}
      IconImage={SearchIcon}
      label="Search"
      placeHolder="Type or Scan"
      value={debouncedSearchText}
      CrossIcon={debouncedSearchText ? CrossIcon : ""}
      clearSearchText={clearSearchText}
      BlueBoxIcon={BlueBoxIcon}
      LoadingIndicator={
        isServerSearchLoading ||
        (isButtonDisable(spinnerArray, serverCallConstant) &&
          debouncedSearchText)
          ? LoadingIndicator
          : false
      }
    />
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  isServerSearchLoading: state.system.isServerSearchLoading,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setIsServerSearchLoading: (data) =>
    dispatch({ type: systemConstants.SET_IS_SERVER_SEARCH_LOADING, data }),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ServerSearchTable);
