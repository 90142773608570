import React from "react";

import IconSelectField from "../../../shared/components/selectField/IconSelectField";
import { getCustomSubCategoriesOptionList } from "../../../shared/utility";

const CustomItemSubcategorySelectField = ({
  setFieldValue,
  values,
  customTypes,
  isDisabled = false,
  customCategories,
  customSubCategories,
  ...props
}) => {
  return (
    <IconSelectField
      label="Sub-Category"
      options={getCustomSubCategoriesOptionList(
        customSubCategories,
        values.category,
        null,
        values.type,
        false,
        customCategories,
        customTypes
      )}
      placeHolder="Sub-Category..."
      name="subCategory"
      isDisabled={isDisabled}
    />
  );
};

export default CustomItemSubcategorySelectField;
