export const itemOrganizationConstants = {
  GET_CUSTOM_CATEGORIES: "GET_CUSTOM_CATEGORIES",
  ADD_CUSTOM_CATEGORIES: "ADD_CUSTOM_CATEGORIES",
  UPDATE_CUSTOM_CATEGORIES: "UPDATE_CUSTOM_CATEGORIES",
  ADD_CUSTOM_PRODUCT_TYPES: "ADD_CUSTOM_PRODUCT_TYPES",
  GET_CUSTOM_PRODUCT_TYPES: "GET_CUSTOM_PRODUCT_TYPES",
  DELETE_CUSTOM_CATEGORIES: "DELETE_CUSTOM_CATEGORIES",
  GET_CUSTOM_SUB_CATEGORIES: "GET_CUSTOM_SUB_CATEGORIES",
  ADD_CUSTOM_SUB_CATEGORIES: "ADD_CUSTOM_SUB_CATEGORIES",
  UPDATE_CUSTOM_PRODUCT_TYPES: "UPDATE_CUSTOM_PRODUCT_TYPES",
  DELETE_CUSTOM_PRODUCT_TYPES: "DELETE_CUSTOM_PRODUCT_TYPES",
  DELETE_CUSTOM_SUB_CATEGORIES: "DELETE_CUSTOM_SUB_CATEGORIES",
  UPDATE_CUSTOM_SUB_CATEGORIES: "UPDATE_CUSTOM_SUB_CATEGORIES",
  UPDATING_PRODUCT_TYPE: "Updating Product Type.",
  UPDATING_CATEGORY: "Updating Category.",
  UPDATING_SUBCATEGORY: "Updating Subcategory.",
};
