import { STATS_SCREEN_CONSTANTS } from "../constants";
import { getRequest, putRequest } from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get at a glance
const getAtAGlance = (
  selectedStore,
  start,
  end,
  category,
  productType,
  duration,
  screenName
) => {
  return getRequest(
    `at-a-glance/${selectedStore}?startDate=${start}&endDate=${end}&duration=${duration}&screenName=${screenName}${
      productType ? `&productType=${productType}` : ""
    }${category ? `&category=${category}` : ""}`
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get at a glance
const getStoreStats = (start, end) => {
  return getRequest(`get-store-stats?startDate=${start}&endDate=${end}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get at a glance
const getStoreTransactionHistory = (storeId, type, start, end, duration) => {
  return getRequest(
    `get-transaction/${storeId}?type=${type}&startDate=${start}&endDate=${end}&duration=${duration}`
  ).then(
    (response) => response?.data?.data?.sellTradeHistory ?? [],
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get at a glance
const getStatsByCategory = (selectedStore, type, start, end) => {
  return getRequest(
    `by-category-stats/${selectedStore.value}?startDate=${start}&endDate=${end}`
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const editPurchasingPower = (storeId, data) => {
  return putRequest(`store-budget/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export dasboard Services
export const dashboardService = {
  getAtAGlance,
  getStoreStats,
  getStatsByCategory,
  getStoreTransactionHistory,
  editPurchasingPower,
};
