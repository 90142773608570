import Select from "react-select";
import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import {
  customToast,
  importTagFunc,
  importStringArrayFunc,
  createMappedArray,
  handleColumnMapping,
  handleFileLoaded,
  getTypeOrCategoryIdByName,
  getTypeOrCategoryObject,
  isProductSystemWithSerialNumber,
  importUpcFunc,
} from "../../../../shared/utility";
import { importSelectFieldDropdownStyle } from "../../../../assets/scss/style";
import Button from "../../../../shared/components/Buttons/Button";
import { UploadCsvIcon } from "../../../../assets/icons/iconsProvider";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  inventoryConstants,
  toastMessages,
  toastType,
  EMPTY_STRING,
} from "../../../../constants";
import moment from "moment";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import { inventoryActions, storeActions } from "../../../../redux/actions";
import { inventoryService } from "../../../../services";
import { SYSTEM_SUBCATEGORY } from "../../../../shared/DefaultCategories";

const { INVALID_IMPORT_VALUES } = inventoryConstants;

const ImportInventory = (props) => {
  const {
    currentStore,
    importInventoryModal,
    toggleImportInventoryModal,
    importCsvFileInventory,
    activateImportInventorySpinner,
    importCsvFileResponse,
    customTypes,
    customCategories,
    customSubCategories,
  } = props;

  //-------useState
  const [csvData, setCsvData] = useState("");
  const [columnMapping, setColumnMapping] = useState({});
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const predefinedColumns = [
    "SKU",
    "Product Name",
    "Product Type",
    "Category",
    "Subcategory",
    "UPC",
    "Tags",
    "Condition",
    "Serial Number",
    "Quantity",
    "Cost of Goods",
    "Sell Price",
  ];
  const requiredColumns = [
    "Product Name",
    "Product Type",
    "Category",
    "Condition",
    "Quantity",
    "Cost of Goods",
    "Sell Price",
  ];

  const handleHideModal = () => {
    toggleImportInventoryModal();
    setTimeout(() => {
      setCsvData("");
      setColumnMapping({});
    }, 500);
  };

  const handleStartImport = async () => {
    const arrayAfterMapping = createMappedArray(
      csvData,
      predefinedColumns,
      columnMapping
    );
    let validateResponse = validateCsvData(
      arrayAfterMapping,
      customTypes,
      customCategories,
      customSubCategories
    );
    if (validateResponse.isInvalid) {
      customToast(validateResponse.message, toastType.ERROR);
      return;
    }

    const arrayAfterCleaning = cleanCsvData([
      ...validateResponse.validInventory,
    ]);

    const arrayToSend = arrayAfterCleaning.map((product, index) => {
      return {
        sku: product["SKU"]
          ? importStringArrayFunc(product["SKU"])
          : [inventoryConstants.CSV_IMPORT_FILE],
        upc: importUpcFunc(product["UPC"] ? product["UPC"] : EMPTY_STRING),
        product_id: inventoryConstants.CSV_IMPORT_FILE,
        product_name: product["Product Name"],
        category_name: product["Category"],
        subcategory: product["Subcategory"]
          ? product["Subcategory"]
          : EMPTY_STRING,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
        date_added: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        price: {
          unit_purchase_price: product["Cost of Goods"],
          unit_sell_price: product["Sell Price"],
          quantity: product["Quantity"],
          type: product["Condition"],
          marketPrice: 0,
        },
        productType: product["Product Type"],
        additionalCheckList: [],
        tags: importTagFunc(product["Tags"]),
        cardRarity: "",
        cardNumber: "",
        apiSource: inventoryConstants.CSV_IMPORT_FILE,
        tcgPlayerUrl: EMPTY_STRING,
        epid: EMPTY_STRING,
        description: EMPTY_STRING,
        serialNumber: product["Serial Number"]
          ? product["Serial Number"]
          : EMPTY_STRING,
        trackQuantity: true,
      };
    });
    let batches = createBatchesArray(arrayToSend);

    handleHideModal();
    activateImportInventorySpinner({
      isVisible: true,
      message: "Importing Inventory...",
    });

    let responseData = {
      reqInvalidInventory: [],
      reqNewInventory: [],
      reqExistingInventory: [],
      updatedInventory: [],
    };

    // do while is for retrying failed batches
    let count = 0;
    do {
      if (count !== 0) {
        batches = createBatchesArray(responseData.reqInvalidInventory);
        responseData.reqInvalidInventory = [];
      }

      count++;
      for (let index = 0; index < batches.length; index++) {
        const batch = batches[index];
        await inventoryService.importFileInventory(batch).then(
          (response) => {
            responseData = {
              reqInvalidInventory: [
                ...(responseData?.reqInvalidInventory || []),
                ...(response?.reqInvalidInventory || []),
              ],
              reqNewInventory: [
                ...(responseData?.reqNewInventory || []),
                ...(response?.reqNewInventory || []),
              ],
              reqExistingInventory: [
                ...(responseData?.reqExistingInventory || []),
                ...(response?.reqExistingInventory || []),
              ],
              updatedInventory: [
                ...(responseData?.updatedInventory || []),
                ...(response?.updatedInventory || []),
              ],
            };
          },
          (error) => {
            responseData = {
              ...responseData,
              reqInvalidInventory: [
                ...responseData.reqInvalidInventory,
                ...batch.map((inv) => ({
                  ...inv,
                  errorMessage: inventoryConstants.IMPORT_INVENTORY_ERROR,
                })),
              ],
            };
            // customToast(error, toastType.ERROR);
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } while (
      responseData.reqInvalidInventory.some((inv) => {
        return inv.errorMessage === inventoryConstants.IMPORT_INVENTORY_ERROR;
      }) &&
      count < inventoryConstants.IMPORT_FAILED_BATCHES_RETRIES
    );
    importCsvFileResponse(
      currentStore,
      responseData,
      undefined,
      validateResponse
    );
  };

  return (
    <Modal
      centered
      animation={true}
      backdrop="static"
      onHide={handleHideModal}
      show={importInventoryModal}
      size={csvData ? "lg" : "md"}
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Import Inventory
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        {csvData ? (
          <>
            <p className="p-0 m-0">* = Required Field</p>
            <Row className="m-0 px-0">
              <div className="column-mapping-table-wrapper container-scrollbar">
                <Row className="m-0 py-3 border-bottom px-0 import-inventory-column-header-wrapper">
                  <Col md={6} className="d-flex align-items-center">
                    <span className="import-inventory-columns-heading">
                      Inventory Columns
                    </span>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <span className="import-inventory-columns-heading">
                      Your Column Header
                    </span>
                  </Col>
                </Row>
                {predefinedColumns.map((predefinedColumn, index) => (
                  <Row key={index} className="m-0 py-2 border-bottom px-0">
                    <Col md={6} className="d-flex align-items-center">
                      <span className="predefinded-column-label">
                        {predefinedColumn}
                        {requiredColumns.includes(predefinedColumn) && "*"}
                      </span>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Select
                        options={Object.keys(csvData[0]).map((csvColumn) => ({
                          value: csvColumn,
                          label: csvColumn,
                        }))}
                        value={{
                          value: columnMapping[predefinedColumn] || "",
                          label:
                            columnMapping[predefinedColumn] || "Select Column",
                        }}
                        onChange={(selectedOption) =>
                          handleColumnMapping(
                            predefinedColumn,
                            selectedOption.value,
                            setColumnMapping
                          )
                        }
                        styles={importSelectFieldDropdownStyle}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                      />
                    </Col>
                  </Row>
                ))}
              </div>
              <Col
                md={12}
                className="mt-4 d-flex justify-content-end align-items-center gap-3 px-0"
              >
                <Button
                  type="button"
                  label={buttonNameConstants.BACK}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  handleClick={() => setCsvData("")}
                />
                <Button
                  type="button"
                  label={buttonNameConstants.START_IMPORT}
                  handleClick={() => handleStartImport()}
                  isDisabled={requiredColumns.find((col) => {
                    return !columnMapping.hasOwnProperty(col);
                  })}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <label
                className="d-flex flex-column justify-content-center align-items-center gap-2 upload-csv-wrapper"
                style={{ height: "fit-content" }}
              >
                <UploadCsvIcon />
                Click or drag file to this area to upload
                <CSVReader
                  inputId="CSVReader"
                  key={fileInputKey}
                  inputStyle={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    opacity: 0,
                  }}
                  onFileLoaded={(data, fileInfo) =>
                    handleFileLoaded(
                      data,
                      fileInfo,
                      predefinedColumns,
                      setCsvData,
                      setColumnMapping
                    )
                  }
                  parserOptions={{ header: true, skipEmptyLines: true }}
                  accept=".csv"
                />
              </label>
            </Col>
            <Col md={7}></Col>
            <Col
              md={5}
              className="mt-3 d-flex justify-content-end align-items-center"
            >
              <Button
                type="button"
                label={buttonNameConstants.CANCEL}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                handleClick={() => handleHideModal()}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customTypes: state.itemOrganization.customTypes,
  customCategories: state.itemOrganization.customCategories,
  customSubCategories: state.itemOrganization.customSubCategories,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateImportInventorySpinner: (data) =>
    dispatch(inventoryActions.activateImportInventorySpinner(data)),
  importCsvFileResponse: (
    currentStore,
    responseData,
    undefined,
    validateResponse
  ) =>
    dispatch(
      inventoryActions.importCsvFileResponse(
        currentStore,
        responseData,
        undefined,
        validateResponse
      )
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ImportInventory);

const validateCsvData = (
  dataArray,
  customTypes,
  customCategories,
  customSubCategories
) => {
  let isInvalid = false;
  let invalidInventory = [];
  let tempInv = {};
  let handleRes = (message) => {
    return {
      isInvalid,
      message,
    };
  };
  const handleAddInvalidInventory = (index, errorMessage) => {
    tempInv = dataArray.splice(index, 1);
    invalidInventory.push({
      ...tempInv[0],
      errorMessage: errorMessage,
    });
  };
  const validatePriceFunc = (value) => {
    return String(value).includes("$")
      ? !(
          String(value).includes("$") &&
          String(value).startsWith("$") &&
          String(value).length > 1 &&
          !String(value).slice(1).includes("$")
        )
      : value !== null &&
          value !== "null" &&
          value !== EMPTY_STRING &&
          isNaN(value);
  };

  // filtering out duplicate skus products
  // let skusArr = dataArray.map((row) => row.SKU);
  // skusArr.forEach((sku, index) => {
  //   if (sku !== "" && skusArr.indexOf(sku) !== skusArr.lastIndexOf(sku)) {
  //     // as the original dataArray is spliced so to point to the correct index
  //     handleAddInvalidInventory(
  //       index - invalidInventory.length,
  //       "Invalid Value: File has two records with same SKU."
  //     );
  //   }
  // });

  let notNullMessage = [];
  let invalidValueMessage = [];
  let invalidItemOrganizationMessage = [];
  let initialInvalidArrLength = invalidInventory.length;
  let lowerCaseCondition = null;
  let tempProductType = EMPTY_STRING;
  let tempCategory = EMPTY_STRING;
  let tempSubcategory = EMPTY_STRING;
  const { INVALID_IMPORT_VALUES } = inventoryConstants;
  [...dataArray].forEach((inv, index) => {
    notNullMessage = [];
    invalidValueMessage = [];
    invalidItemOrganizationMessage = [];

    // validate: required field is not empty (Product Name, Product Type, Category, Quantity, Cost of Goods, Sell Price)
    if (inv["Product Name"] === EMPTY_STRING) {
      notNullMessage.push("Product Name");
    }
    if (inv["Product Type"] === EMPTY_STRING) {
      notNullMessage.push("Product Type");
    }
    if (inv["Category"] === EMPTY_STRING) {
      notNullMessage.push("Category");
    }
    if (inv["Condition"] === EMPTY_STRING) {
      notNullMessage.push("Condition");
    }
    if (inv["Quantity"] === EMPTY_STRING) {
      notNullMessage.push("Quantity");
    }

    // validate: value of the field is not invalid
    if (isNaN(inv["Quantity"])) {
      invalidValueMessage.push("Quantity");
    }
    if (validatePriceFunc(inv["Cost of Goods"])) {
      invalidValueMessage.push("Cost of Goods");
    }
    if (validatePriceFunc(inv["Sell Price"])) {
      invalidValueMessage.push("Sell Price");
    }
    lowerCaseCondition = String(inv["Condition"]).toLocaleLowerCase();
    if (
      !Object.values(PRODUCT_CONDITIONS_ENUMS).includes(inv["Condition"]) &&
      !Object.values(INVALID_IMPORT_VALUES).includes(lowerCaseCondition)
    ) {
      invalidValueMessage.push("Condition");
    }
    if (
      isProductSystemWithSerialNumber({
        serialNumber: inv["Serial Number"],
        subcategory: inv["Subcategory"],
      }) &&
      Number(inv["Quantity"]) !== 1
    ) {
      invalidValueMessage.push("Quantity");
    }

    // validate productType, category, subcategory relation (productType, category is required and subcategory is optional)
    tempProductType = getTypeOrCategoryObject(
      customTypes,
      getTypeOrCategoryIdByName(customTypes, inv["Product Type"])
    );
    tempCategory = getTypeOrCategoryObject(
      customCategories,
      getTypeOrCategoryIdByName(
        customCategories,
        inv["Category"],
        inv["Product Type"]
      )
    );
    tempSubcategory = getTypeOrCategoryObject(
      customSubCategories,
      getTypeOrCategoryIdByName(
        customSubCategories,
        inv["Subcategory"],
        inv["Product Type"],
        inv["Category"]
      )
    );
    if (!tempProductType) {
      invalidItemOrganizationMessage.push("Invalid Product Type");
    } else if (
      !tempCategory ||
      tempCategory.productTypeId !== tempProductType.id
    ) {
      invalidItemOrganizationMessage.push("Invalid Category");
    } else if (
      inv["Subcategory"] &&
      !(
        tempSubcategory &&
        tempSubcategory.parentCategoryId === SYSTEM_SUBCATEGORY &&
        tempProductType.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
      ) &&
      (!tempSubcategory ||
        tempSubcategory.parentCategoryId !== tempCategory.id ||
        tempSubcategory.parentProductTypeId !== tempProductType.id)
    ) {
      invalidItemOrganizationMessage.push("Invalid Subcategory");
    }

    // create error message and update valid and invalid inventory array
    if (
      notNullMessage.length ||
      invalidValueMessage.length ||
      invalidItemOrganizationMessage.length
    ) {
      handleAddInvalidInventory(
        index - (invalidInventory.length - initialInvalidArrLength),
        (notNullMessage.length
          ? "Missing Required Field: " + notNullMessage.join("; ") + ". "
          : EMPTY_STRING) +
          (invalidValueMessage.length
            ? "Invalid Value: " + invalidValueMessage.join("; ") + ". "
            : EMPTY_STRING) +
          (invalidItemOrganizationMessage.length
            ? "Item Organization Error: " +
              invalidItemOrganizationMessage.join("; ") +
              ". "
            : EMPTY_STRING)
      );
    }
  });

  return {
    isInvalid: false,
    message: "",
    validInventory: dataArray,
    invalidInventory,
  };
};

const cleanCsvData = (dataArray) => {
  // cleaning CSV Data
  let condition = EMPTY_STRING;
  let updatedCondition = EMPTY_STRING;
  let productType = EMPTY_STRING;
  let updatedProductType = EMPTY_STRING;
  const cleanPriceFunc = (value) => {
    let cleanedValue = value;
    if (
      value === "0" ||
      value === null ||
      value === "null" ||
      value === EMPTY_STRING
    ) {
      cleanedValue = 0;
    } else if (
      String(value).includes("$") &&
      String(value).startsWith("$") &&
      !String(value).slice(1).includes("$")
    ) {
      cleanedValue = cleanedValue.replace("$", EMPTY_STRING);
    }

    return cleanedValue;
  };
  dataArray.forEach((data) => {
    // converts the condition (completeInBox, CIB, complete to Complete)
    condition = data.Condition.toLowerCase();
    updatedCondition = "";
    switch (true) {
      case condition === INVALID_IMPORT_VALUES.COMPLETE_IN_BOX ||
        condition === INVALID_IMPORT_VALUES.CIB ||
        condition === INVALID_IMPORT_VALUES.COMPLETE:
        updatedCondition = PRODUCT_CONDITIONS_ENUMS.COMPLETE;
        break;
      case condition.includes(INVALID_IMPORT_VALUES.LOOSE):
        updatedCondition = PRODUCT_CONDITIONS_ENUMS.LOOSE;
        break;
      case condition.includes(INVALID_IMPORT_VALUES.NEW):
        updatedCondition = PRODUCT_CONDITIONS_ENUMS.NEW;
        break;
      default:
        updatedCondition = data.Condition;
        break;
    }
    data.Condition = updatedCondition;

    // converts the product type to other if it's not video game or trading card
    // productType = data["Product Type"]
    //   .replaceAll(" ", EMPTY_STRING)
    //   .toLowerCase();
    // updatedProductType = "";
    // switch (true) {
    //   case productType.includes(INVALID_IMPORT_VALUES.VIDEOGAME):
    //     updatedProductType = PRODUCT_TYPES_ENUMS.VIDEO_GAME;
    //     break;
    //   case productType.includes(INVALID_IMPORT_VALUES.TRADINGCARD):
    //     updatedProductType = PRODUCT_TYPES_ENUMS.TRADING_CARD;
    //     break;
    //   default:
    //     updatedProductType = PRODUCT_TYPES_ENUMS.OTHER;
    //     break;
    // }
    // data["Product Type"] = updatedProductType;

    // converts null to 0 or remove $ sign for sell price and COGS price
    data["Sell Price"] = cleanPriceFunc(data["Sell Price"]);
    data["Cost of Goods"] = cleanPriceFunc(data["Cost of Goods"]);

    // converts consecutive apostrophes ('') into single apostrophes (apostrophes: ''_'' not quotations: ‘‘_’’)
    data["Product Name"] = String(data["Product Name"]).replaceAll("''", "'");
  });

  return dataArray;
};

const createBatchesArray = (arrayToSend) => {
  const batches = [];
  if (arrayToSend.length > inventoryConstants.IMPORT_BATCH_INVENTORY_SIZE) {
    const batchSize = inventoryConstants.IMPORT_BATCH_INVENTORY_SIZE;
    const numberOfBatches = Math.ceil(arrayToSend.length / batchSize);
    for (let i = 0; i < numberOfBatches; i++) {
      const batch = arrayToSend.slice(i * batchSize, (i + 1) * batchSize);
      batches.push(batch);
    }
  } else {
    batches.push(arrayToSend);
  }
  return batches;
};
