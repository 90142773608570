import { Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";

import { inventoryService } from "../../../../services";
import Button from "../../../../shared/components/Buttons/Button";
import {
  customToast,
  formatDate,
  handleCashOfferValues,
  handleTradeOfferValues,
} from "../../../../shared/utility";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  toastType,
  transactionConstants,
  inventoryConstants,
} from "../../../../constants";
import { systemActions, transactionActions } from "../../../../redux/actions";
import { addQuickTradeInValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";

const AddQuickTradeIn = (props) => {
  const {
    quickTradeInModal,
    toggleQuickTradeInModal,
    addItemInCart,
    currentStore,
    activateSpinner,
    deactivateSpinner,
    generateTempSku,
    transaction,
    tradeDiscount,
  } = props;
  const { tempSku } = transaction;
  //-------initial values for custom item
  // const globalTradeinMargin = {
  //   cashMarginPercentage: 30,
  //   tradeinMarginPercentage: 40,
  // };
  //-------preset prices
  const { tradeinMargin } = tradeDiscount;
  const globalTradeinMargin = tradeinMargin?.globalMargin;
  const ratio =
    globalTradeinMargin?.tradeinMarginPercentage /
    globalTradeinMargin?.cashMarginPercentage;
  const initialValues = {
    // productType: inventoryConstants.CUSTOM_PRODUCT_TYPES[0].value,
    cashOffer: globalConstants.EMPTY_STRING,
    tradeOffer: globalConstants.EMPTY_STRING,
    totalItems: globalConstants.EMPTY_STRING,
  };

  //-------handle hide modal
  const handleHideModal = () => {
    toggleQuickTradeInModal();
  };

  const handleQuickTradeInSubmit = async (values) => {
    generateTempSku();
    const valuesObject = {
      product_id: transactionConstants.QUICK_TRADE,
      product_name: transactionConstants.QUICK_TRADE,
      category_name: transactionConstants.QUICK_TRADE,
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
      date_added: formatDate(new Date()),
      price: {
        unit_purchase_price: "",
        unit_sell_price: 1,
        quantity: 1,
        totalItems: values.totalItems,
        type: inventoryConstants.VIDEO_GAME_CONDITION[1].value,
        marketPrice: 0,
      },
      productType: PRODUCT_TYPES_ENUMS.OTHER,
      upc: "",
      additionalCheckList: [],
      tags: [],
      cardRarity: "",
      cardNumber: "",
      apiSource: globalConstants.EMPTY_STRING,
      imgUrl: "",
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      epid: globalConstants.EMPTY_STRING,
      subcategory: globalConstants.EMPTY_STRING,
      description: globalConstants.EMPTY_STRING,
      serialNumber: globalConstants.EMPTY_STRING,
      trackQuantity: true,
    };

    activateSpinner(transactionConstants.ADD_CUSTOM_ITEM);

    const miscellaneousItem = {
      ...valuesObject,
      max: 9999,
      sku: [`${transactionConstants.QUICK_TRADE} ${tempSku}`],
      cashOffer: values.cashOffer,
      tradeOffer: values.tradeOffer,
      ratio: ratio,
    };
    addItemInCart(miscellaneousItem);

    handleHideModal();
    deactivateSpinner(transactionConstants.ADD_CUSTOM_ITEM);
  };

  return (
    <Modal
      show={quickTradeInModal}
      size="md"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Quick Trade-In
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleQuickTradeInSubmit}
          validate={addQuickTradeInValidationSchema}
        >
          {({ resetForm, setFieldValue }) => (
            <Form>
              <Row className="m-0">
                <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                  <span className="addTrade-gray-text">Total Items: </span>
                  <InputNumberField
                    name="totalItems"
                    placeHolder="0"
                    className="add-inventory-price-field "
                    isConvertToDecimal={false}
                    step={"1"}
                    isHandleKeyDown={true}
                  />
                </div>
                <Col xs={12} className="d-flex flex-column mt-3 mb-3 gap-3">
                  <div className="add-inventory-stock-price-wrapper">
                    <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                      <span className="addTrade-gray-text">Cash Offer: </span>
                      <InputNumberField
                        name="cashOffer"
                        placeHolder="0"
                        className="add-inventory-price-field input-background"
                        IconImage={DollarIcon}
                        handleChange={(e) =>
                          handleCashOfferValues(e, setFieldValue, ratio)
                        }
                        isConvertToDecimal={true}
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                      <span className="addTrade-gray-text">Trade Offer: </span>
                      <InputNumberField
                        name="tradeOffer"
                        placeHolder="0"
                        className="add-inventory-price-field input-background "
                        IconImage={DollarIcon}
                        handleChange={(e) =>
                          handleTradeOfferValues(e, setFieldValue, ratio)
                        }
                        isConvertToDecimal={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      resetForm();
                      handleHideModal();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.ADD}
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  transaction: state.transaction,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  generateTempSku: () => dispatch(transactionActions.generateTempSku()),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(AddQuickTradeIn);
