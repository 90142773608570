//-------Export System Constants
export const systemConstants = {
  REDUX_TRACE_LIMIT: 25,
  MAX_NOTIFICATION_NUMBER: 99,
  TOGGLE_MODAL: "TOGGLE_MODAL",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  SPINNER_ACTIVATE: "SPINNER_ACTIVATE",
  SPINNER_DEACTIVATE: "SPINNER_DIACTIVATE",
  TOGGLE_REDIRECT_PAGE: "TOGGLE_REDIRECT_PAGE",
  TOGGLE_NESTED_SIDEBAR: "TOGGLE_NESTED_SIDEBAR",
  SET_ACTIVE_SIDEBAR_BTN: "SET_ACTIVE_SIDEBAR_BTN",
  SET_IS_SERVER_SEARCH_LOADING: "SET_IS_SERVER_SEARCH_LOADING",
  SET_ACTIVE_NESTED_SIDEBAR_BTN: "SET_ACTIVE_NESTED_SIDEBAR_BTN",
};

export const toastType = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
};

export const toastMessages = {
  ID_IS_EXPIRED: "ID is expired.",
  NETWORK_ERROR: "Network error",
  ITEM_NOT_FOUND: "Item not found",
  NO_RECORD_FOUND: "No record found",
  NO_DEVICE_FOUND: "No device found",
  POIID_REQUIRED: "Poi Id is required",
  TAG_ALREADY_FOUND: "Tag already added!",
  ID_NOT_RECOGNIZED: "ID Not Recognized.",
  DELETED_SUCCESSFULLY: "Deleted successfully",
  SOMETHING_WENT_WRONG: "Something went wrong.",
  STORE_LOGO_NOT_FOUND: "Store Logo Not Found",
  ONLY_CSV_ALLOWED: "Only CSV file is Allowed",
  PROBLEM_MESSAGE_SENT: "Submitted successfully",
  TOTAL_COST: "Total cost must be greater than 0",
  PLEASE_SELECT_CUSTOMER: "Please Select Customer",
  VALID_MOBILE: "Please enter a valid Phone Number",
  VALID_EMAIL: "Please enter a valid Email Address",
  PRODUCT_ALREADY_ADDED: "Product is already added",
  SALE_TAX_UPDATED: "Current sale tax rate updated",
  PLEASE_SELECT_A_CUSTOMER: "Please select customer",
  NO_PRINTER_DEVICE_FOUND: "No Printer Device Found",
  TYPE_ADDED_SUCCESSFULLY: "Type added successfully",
  SERIAL_NUMBER_REQUIRED: "Serial Number is required",
  DRAFT_SAVED_SUCCESSFULLY: "Draft saved successfully",
  ADMIN_ADDED_SUCCESSFULLY: "Admin added successfully",
  PLEASE_SELECT_PRINTER: "Please select printer first",
  PLEASE_SELECT_CATEGORIES: "Please select categories",
  RECEIPT_SENT_SUCCESSFULLY: "Receipt sent successfully",
  STORE_ADDED_SUCCESSFULLY: "Store added successfully.",
  RESET_CART_WARNING: "Are you sure you want to reset?",
  ITEM_DELETED_SUCCESSFULLY: "Item Deleted Successfully",
  TYPE_UPDATED_SUCCESSFULLY: "Type updated successfully",
  TYPE_DELETED_SUCCESSFULLY: "Type deleted successfully",
  DEVICE_ADDED_SUCCESSFULLY: "Device Added Successfully",
  PLEASE_SELECT_ALL_OPTIONS: "Please select all options",
  PLEASE_SELECT_DEVICE: "Please select device to proceed",
  CLEAR_BATCH_WARNING: "Delete all items from the batch?",
  GLOBAL_MARGIN_ADDED: "Global margin added successfully",
  PLEASE_SELECT_LABEL_DESIGN: "Please Select Label Design",
  ADMIN_UPDATED_SUCCESSFULLY: "Admin updated successfully",
  ADMIN_DELETED_SUCCESSFULLY: "Admin deleted successfully",
  COMPANY_ADDED_SUCCESSFULLY: "Company added successfully",
  PLEASE_SELECT_SOME_PRODUCT: "Please select some product",
  DRAFT_DELETED_SUCCESSFULLY: "Draft deleted successfully",
  PORDUCT_ADDED_SUCCESSFULLY: "Product Added Successfully",
  INVENTORY_FILE_NOT_IMPORTED: " Items were not imported.",
  MONTHLY_BUDGET_ADDED: "Monthly Budget Saved Successfully",
  STORE_DELETED_SUCCESSFULLY: "Store deleted successfully.",
  STORE_UPDATED_SUCCESSFULLY: "Store updated successfully.",
  CATEGORY_ADDED_SUCCESSFULLY: "Category added successfully",
  EMPLOYEE_ADDED_SUCCESSFULLY: "Employee added successfully",
  CUSTOMER_ADDED_SUCCESSFULLY: "Customer added successfully",
  PORDUCTS_ADDED_SUCCESSFULLY: "Products Added Successfully",
  DEVICE_DELETED_SUCCESSFULLY: "Device deleted Successfully",
  DEVICE_UPDATED_SUCCESSFULLY: "Device updated Successfully",
  CATEGORY_MARGIN_ADDED: "Category margin added successfully",
  TOTAL_CASH_OFFER: "Total cash offer must be greater than 0",
  RETURN_HAS_BEEN_SUCCESSFULL: " Return has been Successful",
  GLOBAL_MARKUP_UPDATED: "Global Markup updated successfully",
  BARCODE_DOESNOT_EXIST: "Barcode doesn’t exist in Inventory",
  SHARINK_UPDATED_SUCCESSFULLY: "Shrink updated Successfully",
  COMPANY_UPDATED_SUCCESSFULLY: "Company updated successfully",
  TRANSACTION_DRAFT_SUCCESSFULLY: "Transaction Added To Draft",
  COMPANY_DELETED_SUCCESSFULLY: "Company deleted successfully",
  PRODUCT_PRICES_UPDATED: "Product prices updated successfully",
  TOTAL_TRADE_OFFER: "Total trade offer must be greater than 0",
  CANNOT_BE_EXPORT_ARRAY_EMPTY: "Cannot download an empty CSV.",
  PARK_TRADE_SUCCESSFULLY: "Trade data has been saved to draft",
  CUSTOMER_DELETED_SUCCESSFULLY: "Customer deleted successfully",
  CUSTOMER_UPDATED_SUCCESSFULLY: "Customer updated successfully",
  CATEGORY_UPDATED_SUCCESSFULLY: "Category updated successfully",
  EMPLOYEE_DELETED_SUCCESSFULLY: "Employee deleted successfully",
  EMPLOYEE_UPDATED_SUCCESSFULLY: "Employee updated successfully",
  CATEGORY_DISCOUNT_ADDED: "Category discount added successfully",
  CUSTOM_SALE_KEYWORD_RESERVED: "Custom sale Category is reserved",
  INVENTORY_UPDATED_SUCCESSFULLY: "Inventory updated Successfully",
  STORE_PURCHASING_POWER_UPDATED: "Store purchasing power updated",
  PRODUCT_DISMISSED_SUCCESSFULLY: "Product dismissed successfully",
  MARKUP_UPDATE_SUCCESSFULLY: "Custom Markup updated successfully",
  CSV_DUPLICATES_SKUS_NOT_ALLOWED: "Duplicate skus are not allowed",
  THRESHOLD_UPDATED_SUCCESSFULLY: "Threshold updated successfully.",
  MARKUP_DELETED_SUCCESSFULLY: "Csutom Markup deleted successfully",
  LOGO_SIZE_ERROR: "Logo width must be less than or equal to 256px.",
  SUB_CATEGORY_ADDED_SUCCESSFULLY: "Sub-Category added successfully",
  TRADE_PAGE_LEAVE_MESSAGE: "Do you want to Park the current trade ?",
  IMAGE_SIZE_ERROR: "Image width must be less than or equal to 256px.",
  VALID_CUSTOMER_REQUIRED: "Valid Customer Identification is required.",
  TRANSACTION_CANCEL_SUCCESSFULLY: "Transaction Cancelled Successfully",
  TRANSACTION_ADDED_SUCCESSFULLY: "Transaction Completed Successfully",
  CART_ITEM_DELETE_ERROR: "Sale total cannot be less than paid amount",
  SET_DEFAULT_PRINTER_SUCCESSFULLY: "Set default printer successfully",
  PLEASE_SET_DEFAULT_ZEBRA_PRINTER: "Please set default zebra printer",
  CUSTOM_CATEGORY_DELETED_SUCCESSFULLY: "Category deleted succesfully",
  SUB_CATEGORY_UPDATED_SUCCESSFULLY: "Sub-Category updated successfully",
  SUB_CATEGORY_DELETED_SUCCESSFULLY: "Sub-Category deleted successfully",
  SUCCESSFULLY_ADD_NEW_PRINTER: "Successfully added new receipt printer.",
  PLEASE_SET_DEFAULT_RECEIPT_PRINTER: "Please set default receipt printer",
  PRODUCT_ALREADY_FOUND_IN_INVENTORY: "Product already found in inventory",
  INVENTORY_FILE_IMPORTED_SUCCESSFULLY: " Items were successfully imported.",
  AMOUNT_TO_PAY_LESS: `Enter an "Amount to Pay" value less than Total Amount`,
  CATEGORY_UPDATE_SUCCESSFULLY: "Custom Trade-In Margin updated Successfully",
  CASH_GREATER_THAN_TRADE: "Cash margin should be greater than trade margin.",
  CATEGORY_DELETED_SUCCESSFULLY: "Custom Trade-In Margin deleted successfully",
  SYSTEM_QUANTITY_MUST_BE_1: "Quantity of System with serial number must be 1",
  RECEIPT_DETAILS_UPDATED_SUCCESSFULLY: "Receipt details updated successfully.",
  AMOUNT_TO_PAY_GREATER: `Enter an "Amount to Pay" value greater than 0 to proceed`,
  AMOUNT_TO_PAY_EQUALGREATER: `Enter an "Amount to Pay" value equal or greater than 0 to proceed`,
  CUSTOM_MARKUP_ADDED_SUCCESSFULLY: "Custom Markup Added successfully",
  PRODUCT_SERIAL_NUMBER_SHOULD_BE_UNIQUE: "Serial Number must be unique.",
  UPDATE_CUSTOMER_ERROR:
    "Cannot remove current Customer because Store Credit payment was applied. Please contact the correct Customer if you need to refund this transaction and repeat it with the correct Customer selected.",
  CUSTOM_TRADE_IN_MARGIN_ADDED_SUCCESSFULLY:
    "Custom Trade-In Margin Added successfully",
  NOT_AUTHORIZED_TO_PERFORM_ACTION:
    "You are unauthorized to perform this action",
  CLEAR_PARTIAL_PAYMENT:
    "You must clear all Partial Payments before returning to the previous screen",
  PLEASE_CONTACT_AADMINISTRATOR:
    "Unexpected error Please contact your administrator",
  USER_INFORMATION_UPDATED_SUCCESSFULLY:
    "User information updated successfully",
  DUPLICATE_EMAIL_RECORDS:
    "File cannot be imported because of duplicate records, Email must be unique",
  DUPLICATE_NUMBER_RECORDS:
    "File cannot be imported because of duplicate records, Number must be unique",
  MAX_GREATER_THAN_MIN:
    "Min price should be less than max price. Please correct the prices.",
  START_DATE_MUST_BE_LESS_THAN_END_DATE:
    "Start Date must be less than end date",
  END_DATE_MUST_BE_GREATER_THAN_START_DATE:
    "End Date must be greater than start date",
  CATEGORY_DISCOUNT_DELETED_SUCCESSFULLY:
    "Category discount deleted successfully",
  CATEGORY_DISCOUNT_UPDATE_SUCCESSFULLY:
    "Category discount updated successfully",
  TRANSACTION_PAGE_LEAVE_MESSAGE:
    "Do you want to Park the current transaction ?",
  ADD_BATCH_INVENTORY_OR_TRADE_LEAVE_MESSAGE: "Save Batch?",
};

export const sessionStorageConstants = {
  STORE_INDEX: "STORE_INDEX",
};

export const categoryDropdownConstants = {
  BOX_ICON: "BOX_ICON",
  BEND_ICON: "BEND_ICON",
  CLOCK_ICON: "CLOCK_ICON",
  SEARCH_ICON: "SEARCH_ICON",
  DEFAULT_ICON: "DEFAULT_ICON",
  POS_DEVICE_ICON: "POS_DEVICE_ICON",
  TRANSACTION_ICON: "TRANSACTION_ICON",
  INVENTORY_LIST_ICON: "INVENTORY_LIST_ICON",
};

export const ROLES = {
  ADMIN: "admin",
  EMPLOYEE: "employee",
  STORE_OWNER: "store-owner",
  STORE_MANAGER: "store-manager",
};

export const skeletonArray = Array(10).fill({});
