// TagList.js
import React, { useEffect, useState } from "react";
import ProductTag from "./ProductTag";
import { AddIcon } from "../../../assets/icons/iconsProvider";
import { customToast } from "../../utility";
import {
  inventoryConstants,
  toastMessages,
  toastType,
} from "../../../constants";
import Select from "react-select";
import { inputTagDropDownStyle } from "../../../assets/scss/style";

const ProductTagList = (props) => {
  const {
    tagsList,
    onSelectTagsChange,
    selectedManual,
    selectedBox,
    selectedController,
    selectedWires,
    suggestedArrayList,
    productDetail,
    customProductTags = false,
    isShowLabel = true,
    className = "",
  } = props;
  const prepareTags = tagsList?.map((tag, index) => ({
    id: index + 1,
    label: tag,
    selected: tag === inventoryConstants.PRODUCT_TAGS.WITH_BOX ? true : false,
  }));
  const [tags, setTags] = useState(
    productDetail?.tags?.length > 0 ? productDetail?.tags : prepareTags
  );
  const [newTag, setNewTag] = useState("");

  const handleTagClick = (id) => {
    const newTagList = tags.map((tag) =>
      tag.id === id ? { ...tag, selected: !tag.selected } : tag
    );

    setTags(newTagList);
    onSelectTagsChange(newTagList?.filter((tag) => tag.selected === true));
  };

  //-------Selected manual
  useEffect(() => {
    if (!customProductTags) {
      if (tags?.length > 0) {
        const updatedTags = tags.map((tag) =>
          tag?.label === selectedManual?.value
            ? {
                ...tag,
                selected:
                  selectedManual?.value ===
                  inventoryConstants?.PRODUCT_TAGS.MISSING_MANUAL
                    ? !selectedManual?.isChecked
                    : selectedManual?.isChecked,
              }
            : tag
        );

        setTags(updatedTags);
        onSelectTagsChange(
          updatedTags?.filter((tag) => tag?.selected === true)
        );
      }
    }
  }, [selectedManual?.isChecked]);

  useEffect(() => {
    if (!customProductTags) {
      if (tags.length > 0) {
        const updatedTags = tags.map((tag) =>
          tag?.label === selectedController?.value
            ? {
                ...tag,
                selected: !selectedController?.isChecked,
              }
            : tag
        );
        setTags(updatedTags);
        onSelectTagsChange(
          updatedTags?.filter((tag) => tag?.selected === true)
        );
      }
    }
  }, [selectedController?.isChecked]);

  //-------Selected Wires
  useEffect(() => {
    if (!customProductTags) {
      if (tags.length > 0) {
        const updatedTags = tags.map((tag) =>
          tag?.label === selectedWires?.value
            ? {
                ...tag,
                selected: !selectedWires?.isChecked,
              }
            : tag
        );
        setTags(updatedTags);
        onSelectTagsChange(
          updatedTags?.filter((tag) => tag?.selected === true)
        );
      }
    }
  }, [selectedWires?.isChecked]);

  //-------Selected box
  useEffect(() => {
    if (!customProductTags) {
      if (tags.length > 0) {
        const updatedTags = tags.map((tag) =>
          tag?.label === selectedBox?.value
            ? {
                ...tag,
                selected: selectedBox?.isChecked,
              }
            : tag
        );
        setTags(updatedTags);
        onSelectTagsChange(
          updatedTags?.filter((tag) => tag?.selected === true)
        );
      }
    }
  }, [selectedBox?.isChecked]);

  const handleAddTag = (clickTag) => {
    let trimmedNewTag = "";
    if (clickTag) {
      trimmedNewTag = clickTag;
    } else {
      trimmedNewTag = newTag.trim();
    }

    if (trimmedNewTag !== "") {
      const normalizedTag = trimmedNewTag.startsWith("#")
        ? trimmedNewTag
        : `#${trimmedNewTag}`;

      // Combine all default tags into a single array
      const allDefaultTags = [
        ...(suggestedArrayList?.systemsDefaultTags?.complete || []),
        ...(suggestedArrayList?.systemsDefaultTags?.loose || []),
        ...(suggestedArrayList?.systemsDefaultTags?.boxOnly || []),
        ...(suggestedArrayList?.gamesDefaultTags?.complete || []),
        ...(suggestedArrayList?.gamesDefaultTags?.loose || []),
        ...(suggestedArrayList?.gamesDefaultTags?.boxOnly || []),
        ...(suggestedArrayList?.gamesDefaultTags?.manualOnly || []),
        inventoryConstants.CUSTOM_ITEM_TAG,
      ];

      if (
        tags.some(
          (tag) =>
            tag.label.toLowerCase() === normalizedTag.toLowerCase() ||
            allDefaultTags.includes(normalizedTag.toLowerCase()) ||
            allDefaultTags.includes(normalizedTag)
        )
      ) {
        customToast(toastMessages.TAG_ALREADY_FOUND, toastType.ERROR);
      } else {
        // Add new tags
        const newTagList = [
          ...tags,
          { id: Date.now(), label: normalizedTag, selected: true },
        ];
        setTags(newTagList);
        setNewTag("");
        onSelectTagsChange(newTagList?.filter((tag) => tag.selected === true));
      }
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === ";" || e.key === "Semicolon") {
      e.preventDefault();
      handleAddTag(false);
    }
  };

  const updateTags = (existingTags, newTags) => {
    const updatedTags = [...existingTags];
    newTags.forEach((tag) => {
      const existingTag = updatedTags.find((t) => t.label === tag.label);
      if (!existingTag && !tag.selected) {
        updatedTags.push(tag);
      }
    });

    return updatedTags;
  };

  useEffect(() => {
    if (!customProductTags) {
      if (productDetail?.tags?.length > 0) {
        const updatedTags = updateTags(productDetail?.tags, prepareTags);
        setTags(updatedTags);
        onSelectTagsChange(
          updatedTags?.filter((tag) => tag?.selected === true)
        );
      } else {
        setTags(prepareTags);
      }
    }
  }, [tagsList]);
  const handleChange = (selectedOption) => {
    if (selectedOption) {
      handleAddTag(selectedOption?.value);
    }
  };
  const handleInputChange = (query, { action }) => {
    if (action === "input-change") {
      setNewTag(query);
    }
  };

  return (
    <div className={`d-flex gap-3 ${className}`}>
      {isShowLabel && <span>Tags</span>}
      <div className="add-tag-wrapper">
        <Select
          options={
            suggestedArrayList?.customTags?.map((t) => ({
              value: t,
              label: t,
            })) || []
          }
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          isSearchable
          onKeyDown={handleInputKeyDown}
          placeholder={"Input tag..."}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={inputTagDropDownStyle}
          onChange={handleChange}
          noOptionsMessage={() => {
            return "No Custom Tags Found";
          }}
          onInputChange={handleInputChange}
        />

        <button type="button" onClick={() => handleAddTag(false)}>
          <AddIcon />
        </button>
      </div>
      <div className="tag-list container-scrollbar">
        {tags &&
          tags
            .filter((tag) => tag.label !== inventoryConstants.CUSTOM_ITEM_TAG)
            .map((tag) => (
              <ProductTag
                key={tag.id}
                label={tag.label}
                selected={tag.selected}
                onClick={() => handleTagClick(tag.id)}
              />
            ))}
      </div>
    </div>
  );
};

export default ProductTagList;
