import React from "react";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  toastMessages,
  toastType,
} from "../../../../constants";
import { Col, Modal, Row } from "react-bootstrap";
import Button from "../../../../shared/components/Buttons/Button";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import {
  customToast,
  handleEmailOrSMSMessage,
} from "../../../../shared/utility";
import { EMAIL_MESSAGE_TYPES } from "../../../../system/globalEnums";
import { Formik } from "formik";
import InputGenericField from "../../../../shared/components/inputGenericField/InputGenericField";

const SendReceiptModal = ({
  sendEmailOrSMS,
  transactionData,
  isReceiptModal,
  toggleIsReceiptModal,
}) => {
  const initialValues = {
    email: transactionData?.customer?.email || globalConstants.EMPTY_STRING,
    mobile: transactionData?.customer?.mobile || globalConstants.EMPTY_STRING,
  };

  const handleSubmit = () => {};

  return (
    <Modal
      show={isReceiptModal}
      onHide={toggleIsReceiptModal}
      size="md"
      animation={true}
      centered
      backdrop="static"
      className={"nested-modal-z-index"}
      backdropClassName={"nested-modal-z-index"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <span className="modal-sale-transaction-id">Send Receipt</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {" "}
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values }) => (
            <Row>
              <Col md={12} className="d-flex flex-column gap-2 my-3">
                <div className="w-100 d-flex align-items-center gap-2">
                  <span className="text-nowrap receipt-label">
                    Email a receipt:
                  </span>
                  <Col md={5} className=" ">
                    <InputTextField
                      className="background-gray"
                      name="email"
                      // label="Email"
                      placeHolder="Email"
                    />
                  </Col>
                  <Col md={4} className="marginLeft-auto ">
                    <Button
                      type="button"
                      label={buttonNameConstants.EMAIL}
                      handleClick={() => {
                        handleEmailOrSMSMessage(
                          EMAIL_MESSAGE_TYPES.TRANSACTION_RECEIPT,
                          true,
                          values,
                          transactionData,
                          customToast,
                          toastMessages,
                          toastType,
                          sendEmailOrSMS
                        );
                      }}
                      className="w-100 email-send-buttons"
                      isDisabled={!values.email}
                      // isDisabled={!transactionData?.customer?.email}
                    />
                  </Col>
                </div>
                <div className="w-100 d-flex justify-content-between align-items-center gap-2">
                  <span className="text-nowrap receipt-label">
                    Text a receipt:
                  </span>
                  <Col md={5} className="">
                    <InputGenericField
                      name="mobile"
                      type="tel"
                      placeHolder="Phone Number"
                      // label="Phone Number"
                      inputMask="(999) 999-9999"
                      className="background-gray"
                    />
                  </Col>
                  <Col md={4} className="marginLeft-auto">
                    <Button
                      type="button"
                      label={buttonNameConstants.TEXT}
                      handleClick={() => {
                        handleEmailOrSMSMessage(
                          EMAIL_MESSAGE_TYPES.TRANSACTION_RECEIPT,
                          false,
                          values,
                          transactionData,
                          customToast,
                          toastMessages,
                          toastType,
                          sendEmailOrSMS
                        );
                      }}
                      className="w-100 email-send-buttons"
                      // isDisabled={!transactionData?.customer?.mobile}
                      isDisabled={!values.mobile}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center gap-2 w-100">
          <Button
            type="button"
            label={buttonNameConstants.BACK}
            handleClick={() => toggleIsReceiptModal()}
            buttonType={buttonTypeConstants.LIGHT_PURPLE}
            className="w-100"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SendReceiptModal;
