import React from "react";
import { Modal } from "react-bootstrap";
import { EMPTY_STRING } from "../../../constants";

const CustomBodyModal = (props) => {
  const {
    children,
    size = "lg",
    show = false,
    animation = true,
    onHide = () => {},
    title = EMPTY_STRING,
    className = EMPTY_STRING,
    dialogClassName = EMPTY_STRING,
    backdropClassName = EMPTY_STRING,
  } = props;

  return (
    <Modal
      centered
      size={size}
      show={show}
      onHide={onHide}
      backdrop="static"
      animation={animation}
      className={className}
      dialogClassName={dialogClassName}
      backdropClassName={backdropClassName}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomBodyModal;
