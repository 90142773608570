import React, { useCallback } from "react";
import { Field, useFormikContext } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";

//-------CheckboxField component start here
const CheckboxField = (props) => {
  const { name, label, isLabelLeft = false, handleChange = false } = props;
  const { setFieldValue } = useFormikContext();

  const onChange = useCallback(
    (event) => {
      setFieldValue(name, event.target.checked);
      if (handleChange) {
        handleChange(name, event.target.checked);
      }
    },
    [setFieldValue]
  );

  //-------Return CheckboxField Component
  return (
    <div>
      <div className="checkbox-field-wrapper">
        {isLabelLeft ? <label>{label}</label> : ""}
        <Field
          name={name}
          className="checkbox-field"
          type="checkbox"
          onChange={onChange}
        />
        {isLabelLeft ? "" : <label>{label}</label>}
      </div>
    </div>
  ); //-------CheckboxField component end here
};

//-------Export CheckboxField Component
export default CheckboxField;
