import { ROLES } from "./system.constant";

export let routesPathConstants = {
  LOGIN: "/login",
  UNKNOWN_PATH: "*",
  DEFAULT_PATH: "/",
  DASHBOARD: "/Dashboard",
  CUSTOMERS: "/Customers",
  EMPLOYEES: "/Employees",
  SETTINGS: "/Settings/Users",
  INVENTORY: "/Inventory/Items",
  SETTINGS_USERS: "/Employees",
  ADD_NEW_USER: "/Employees/Add",
  REPORT_ISSUE: "/ReportIssue",
  INVENTORY_ITEMS: "/Inventory/Items",
  SETTINGS_DEVICES: "/Settings/Devices",
  INVENTORY_EDIT: "/Inventory/Items/Edit",
  SETTINGS_DISCOUNT: "/Settings/Discount",
  SETTINGS_TRADE_INS: "/Settings/TradeIns",
  SETTINGS_EMPLOYEES: "/Settings/Employees",
  SETTINGS_DEVICES_ADD: "/Settings/Devices/Add",
  SELL_AND_TRADE_DRAFTS: "/SellAndTrade/Drafts",
  INVENTORY_ITEMS_HISTORY: "/Inventory/History",
  INVENTORY_ITEMS_SUMMARY: "/Inventory/Summary",
  SELL_AND_TRADE: "/SellAndTrade/NewTransaction",
  SETTINGS_DEVICES_EDIT: "/Settings/Devices/Edit",
  SELL_AND_TRADE_HISTORY: "/SellAndTrade/History",
  SETTINGS_PRICE_CHANGES: "/Settings/PriceChanges",
  SETTINGS_USERS_EDIT_USER: "/Employees/Edit",
  SETTINGS_PRESET_PRICES: "/Settings/PriceMarkups",
  CHANGE_PRICE: "/Inventory/PriceChanges/ChangePrice",
  INVENTORY_ITEMS_STOCK_AUDIT: "/Inventory/StockAudit",
  SELL_AND_TRADE_NEW_RETURN: "/SellAndTrade/NewReturn",
  SETTINGS_DISCOUNT_CREATE: "/Settings/Discount/Create",
  SETTINGS_PRINTER_SETTINGS: "/Settings/Printer-Settings",
  SETTINGS_ITEM_ORGANIZATION: "Settings/ItemOrganization",
  INVENTORY_ITEMS_PRICE_CHANGES: "/Inventory/PriceChanges",
  SETTINGS_RECEIPT_AND_LABELS: "/Settings/StoreSettings",
  SETTINGS_SHOPIFY_CONNECTION: "/Settings/ShopifyConnection",
  SELL_AND_TRADE_NEW_TRANSACTION: "/SellAndTrade/NewTransaction",
  SELL_AND_TRADE_NEW_TRADE: "/SellAndTrade/NewTransaction/NewTrade",
  SELL_AND_TRADE_NEW_TRANSACTION_PAYMENT:
    "/SellAndTrade/NewTransaction/Payment",
  SELL_AND_TRADE_NEW_TRADE_PAYMENT:
    "/SellAndTrade/NewTransaction/NewTrade/Payment",

  // Misc routes
  BILLING: "/Billing",
  PAYOUTS: "/Payouts",
  PRINTER_SETTINGS: "/PrinterSetting",
  USER_INFORMATION: "/UserInformation",

  // Admin tab routes
  ADMIN: "/Admin/Stores",
  ADMIN_STORES: "/Admin/Stores",
  ADMIN_DEVICES: "/Admin/Devices",
  ADMIN_COMPANIES: "/Admin/Companies",
  ADMIN_STORES_ADD: "/Admin/Stores/Add",
  ADMIN_SYSTEMUSERS: "/Admin/Superadmins",
  ADMIN_STORES_EDIT: "/Admin/Stores/Edit",
  ADMIN_DEVICES_ADD: "/Admin/Devices/Add",
  ADMIN_DEVICES_EDIT: "/Admin/Devices/Edit",
  ADMIN_COMPANIES_ADD: "/Admin/Companies/Add",
  ADMIN_COMPANIES_EDIT: "/Admin/Companies/Edit",
  ADMIN_SYSTEMUSERS_ADD: "/Admin/Superadmins/Add",
  ADMIN_SYSTEMUSERS_EDIT: "/Admin/Superadmins/Edit",

  //Customer
  CUSTOMERS_ADD: "/Customers/Add",
  CUSTOMERS_EDIT: "/Customers/Edit",

  //------ Analytices
  ANALYTICS_SALES_SUMMARY: "/Analytics/SalesSummary",
};

export const routesPathConstantsSetter = (updatedRoutesArray) => {
  updatedRoutesArray.forEach((routeObject) => {
    routesPathConstants[`${routeObject.key}`] = routeObject.value;
  });
};

export const STORE_OWNER_NOT_ALLOWED_ROUTES = Object.values(
  routesPathConstants
).filter((route) => route.toLowerCase().includes(ROLES.ADMIN.toLowerCase()));

export const STORE_MANAGER_NOT_ALLOWED_ROUTES = [
  routesPathConstants.BILLING,
  routesPathConstants.PAYOUTS,
];

export const EMPLOYEE_NOT_ALLOED_ROUTES = [
  routesPathConstants.DASHBOARD,
  routesPathConstants.BILLING,
  routesPathConstants.PAYOUTS,
  routesPathConstants.SETTINGS_USERS,
  routesPathConstants.SETTINGS_USERS_EDIT_USER,
  routesPathConstants.ADD_NEW_USER,
  routesPathConstants.SETTINGS_DEVICES,
  routesPathConstants.SETTINGS_DEVICES_ADD,
  routesPathConstants.SETTINGS_DEVICES_EDIT,
  routesPathConstants.SETTINGS_DISCOUNT,
  routesPathConstants.SETTINGS_DISCOUNT_CREATE,
  routesPathConstants.SETTINGS_TRADE_INS,
  routesPathConstants.SETTINGS_ITEM_ORGANIZATION,
  routesPathConstants.SETTINGS_PRESET_PRICES,
  routesPathConstants.SETTINGS_RECEIPT_AND_LABELS,
];

export const EMPLOYEE_NOT_ALLOWED_NESTED_SIDEBAR_TABS = [
  routesPathConstants.SETTINGS_DEVICES,
  routesPathConstants.SETTINGS_DISCOUNT,
  routesPathConstants.SETTINGS_TRADE_INS,
  routesPathConstants.SETTINGS_ITEM_ORGANIZATION,
  routesPathConstants.SETTINGS_PRESET_PRICES,
  routesPathConstants.SETTINGS_RECEIPT_AND_LABELS,
];
