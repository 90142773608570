import { useCallback } from "react";
import { toast, Slide } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Base64 } from "base64-string";

import {
  GENRE_SUB_CATEGORY,
  globalConstants,
  inventoryConstants,
  toastMessages,
  toastType,
  tradeInDiscountConstant,
  transactionConstants,
  EMPTY_STRING,
  IMAGE_FILE_TYPES,
  datepickerConstants,
  toggleSwitchButtonConstants,
  customerConstants,
  LOGS_TYPES,
} from "../constants";
import moment from "moment";
import {
  CUSTOM_FILTER_ENUMS,
  DATE_FORMAT,
  EMAIL_MESSAGE_TYPES,
  NOTIFICATION_TYPES,
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
  SET_BY_MARGIN_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../system/globalEnums";
import {
  ItemOrganizationService,
  inventoryService,
  storeService,
} from "../services";
import TableCustomHeader from "./components/table/TableCustomHeader";
import {
  DEFAULT_TAGS,
  defaultCategories,
  defaultSubCategories,
  defaultTradingCardCategories,
  defaultTypes,
  SYSTEM_SUBCATEGORY,
} from "./DefaultCategories";
import { isVideoGame, isTradingCard } from "./inventoryUtility";
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from "../system/storage";
import { emailRegex, phoneRegex } from "./validationSchema/validationSchema";
//-------Stringfy the Object
export const stringifyObject = (object) => {
  return JSON.stringify(object);
};

//-------Parse Json Object
export const parseJsonObject = (object) => {
  return JSON.parse(object);
};

//-------Updtae Object
export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

//-------Custom Toast
export const customToast = (
  AllErrorMessages,
  type = toastType.INFO,
  time = 4000,
  position
) => {
  const options = {
    autoClose: time,
    type,
    hideProgressBar: true,
    position,
    pauseOnHover: true,
    transition: Slide,
    className: "primary",
  };
  if (Array.isArray(AllErrorMessages)) {
    AllErrorMessages.forEach((message) => {
      toast(message, options);
    });
  } else {
    toast(AllErrorMessages, options);
  }
};

export const stringToPascal = (text) =>
  text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });

function convertArrayOfObjectsToCSV(array) {
  if (array.length === 0) {
    return "";
  }

  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}

export function downloadCSV(
  array,
  fileName,
  date = `${new Date().toISOString().slice(8, 10)}-${new Date()
    .toISOString()
    .slice(5, 7)}-${new Date().toISOString().slice(0, 4)}`
) {
  if (array.length === 0) {
    customToast(toastMessages.CANNOT_BE_EXPORT_ARRAY_EMPTY, toastType.ERROR);
    return;
  }
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = `${fileName ? fileName : "export"}-${date}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${encodeURI(csv).replaceAll(
      "#",
      "%23"
    )}`;
  }

  link.setAttribute("href", csv);
  link.setAttribute("download", filename);
  link.click();
}

// "2023-12-29T09:47:59.2060000Z" to "MM/DD/YYYY"
export function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-US", options);
}

export function formatTime(dateString) {
  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Format the time only, excluding the date
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
}
//-------convert value to usd
export const convertToUsd = (number) => {
  if (number) {
    return (number / 100).toFixed(2);
  } else return 0;
};

export const usdToOriginal = (usdValue) => {
  const number = parseFloat(usdValue);
  if (!isNaN(number)) {
    return (number * 100).toFixed(2);
  } else return parseToThousandSeperatorDecimalNumber(0);
};
//-------slugify the sring
export const stringToSlug = (str) => {
  return str
    .toLowerCase()
    .replace(/[^\w\s-']/g, "") // Remove non-word characters (excluding spaces, dashes, and single quotes)
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/'/g, "%27") // Replace single quotes with %
    .trim(); // Trim leading/trailing whitespace
};

export const getCategoryDiscount = (category, categoryDiscounts) => {
  const validDiscounts = categoryDiscounts.filter((discount) => {
    return (
      discount.categoryName === category &&
      checkDiscountValidity(discount.startDate, discount.endDate)
    );
  });

  if (validDiscounts.length > 0) {
    // Sort valid discounts based on end date in descending order
    validDiscounts.sort(
      (a, b) =>
        moment.utc(b.endDate).valueOf() - moment.utc(a.endDate).valueOf()
    );
    return validDiscounts[0]; // Return the discount with the latest end date
  } else {
    return null; // No valid discount found
  }
};

export const checkDiscountValidity = (startDate, endDate) => {
  const currentUtcMoment = moment.utc();
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);
  const isValid = currentUtcMoment.isBetween(start, end, null, "[]");
  return isValid;
};

export const convertToFixedPrecision = (value) => {
  return parseFloat(Number(value).toFixed(2));
};

export const getGlobalRatio = (currentStore) => {
  return (
    currentStore?.globalTradeinMargin.cashMarginPercentage /
    currentStore?.globalTradeinMargin.tradeinMarginPercentage
  );
};

export const getStandardRatio = (currentStore, categoryName) => {
  return (
    currentStore?.categoryTradeinMargin.find(
      (category) => category.categoryName === categoryName
    )?.cashMarginPercentage /
      currentStore.categoryTradeinMargin.find(
        (category) => category.categoryName === categoryName
      )?.tradeinMarginPercentage || 0
  );
};

export const getSumOfPaymentHistory = (paymentHistory) => {
  return (
    paymentHistory.totalPaidAmount[transactionConstants.CASH] +
    paymentHistory.totalPaidAmount[transactionConstants.CREDIT_CARD] +
    paymentHistory.totalPaidAmount[transactionConstants.GIFT_CARD] +
    paymentHistory.totalPaidAmount[transactionConstants.STORE_CREDIT]
  );
};

export const subtotal = (inventoryInfo) => {
  if (Array.isArray(inventoryInfo) && inventoryInfo.length > 0) {
    return inventoryInfo.reduce((acc, item) => {
      return acc + item.price * (item.quantity || 0);
    }, 0);
  }
  return "0.00";
};
export const calcullateItems = (inventoryInfo) => {
  if (Array.isArray(inventoryInfo) && inventoryInfo.length > 0) {
    return inventoryInfo.reduce((acc, item) => {
      return acc + item?.price?.quantity;
    }, 0);
  }
  return "0.00";
};

export const calculateTax = (subtotal, taxRate) => {
  return (subtotal * taxRate) / 100;
};

export const convertToDecimalNumber = (
  amount,
  isString,
  isThousandSeparator = true,
  isWholeNumber = false,
  thousandSeparator = ","
) => {
  if (typeof amount === "string") {
    amount = amount.replace(new RegExp(`\\${thousandSeparator}`, "g"), "");
  }

  let number = Number(amount);

  let formattedNumber = number.toFixed(2);

  if (isWholeNumber) {
    formattedNumber = number.toFixed(0);
  }

  let [integerPart, decimalPart] = formattedNumber.split(".");

  if (isThousandSeparator) {
    integerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandSeparator
    );
  }

  let result = isWholeNumber ? integerPart : `${integerPart}.${decimalPart}`;

  return isString ? result : Number(result);
};

//------- parseToNumber
export const parseToNumber = (number) => {
  return convertToDecimalNumber(number, false, false);
};

//------- parseToDecimalNumber
export const parseToDecimalNumber = (number) => {
  return convertToDecimalNumber(number, true, false);
};

//------- parseToThousandSeperatorDecimalNumber
export const parseToThousandSeperatorDecimalNumber = (number) => {
  return convertToDecimalNumber(number, true, true);
};

//------- parseToThousandSeperatorWholeNumber
export const parseToThousandSeperatorWholeNumber = (number) => {
  return convertToDecimalNumber(number, true, true, true);
};
//-------highlight table selected row on double click
export const handleHighlightSelectedRow = (row) => {
  let selectedRow = document.getElementById("row-" + row.id);
  if (selectedRow) {
    selectedRow.classList.add("editable-table-selected-row");
  }
};

//-------De Highlight table selected row on double click
export const handleDehighlightSelectedRow = (selectedRowId) => {
  // removing edit styles of previous record if any
  let deSelectRow = document.getElementById("row-" + selectedRowId);
  if (deSelectRow) {
    deSelectRow.classList.remove("editable-table-selected-row");
  }
};

// Function to convert epoch format to standard date
export const standardDate = (value) => {
  return value ? moment.unix(value).format(DATE_FORMAT.DATE) : "";
};

export function calculateDuration(startDate, endDate) {
  const currentDateTime = moment();

  if (endDate === globalConstants.DEFAULT_ENDDATE) {
    return "-";
  }

  // const start = moment(startDate);
  const end = moment(endDate);
  if (end.isBefore(currentDateTime, "day")) {
    return "Expired";
  }

  let duration = moment.duration(end.diff(currentDateTime));

  let result = "";

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes(); // Get remaining minutes

  const weeks = Math.floor(days / 7);
  const daysLeft = days % 7;

  if (years > 0) {
    result += years + " year" + (years > 1 ? "s" : "") + " ";
  }

  if (months > 0) {
    result += months + " month" + (months > 1 ? "s" : "") + " ";
  }

  if (weeks > 0) {
    result += weeks + " week" + (weeks > 1 ? "s" : "") + " ";
  }

  if (daysLeft > 0) {
    result += daysLeft + " day" + (daysLeft > 1 ? "s" : "") + " ";
  }

  if (hours > 0) {
    // Add this condition to include hours in the result
    result += hours + " hour" + (hours > 1 ? "s" : "") + " ";
  }
  if (minutes > 0) {
    result += minutes + " minute" + (minutes > 1 ? "s" : "") + " "; // Include remaining minutes in the result
  }

  result += "left";

  return result.trim();
}

export const handleKeyDown = (e) => {
  if (["e", "E", "+", "."].includes(e.key)) {
    e.preventDefault();
  }
  if (e.key !== "Backspace" && e.target.value.length === 7) {
    e.preventDefault();
  }
};
export const getUnitPurchasePrice = (ObjectData, cartItem) => {
  if (
    (ObjectData.paymentHistory?.[transactionConstants.CASH] || 0) +
      (ObjectData.paymentHistory?.[transactionConstants.STORE_CREDIT] || 0) >
      0 &&
    ObjectData.currentAmountToPay !== ObjectData.totalAmountToPay
  ) {
    return convertToFixedPrecision(
      (cartItem.price.purchasePricePercentageEachItem *
        ObjectData.totalAmountToPay) /
        100 /
        cartItem.price.quantity
    );
  } else {
    if (ObjectData.userPaymentType === globalConstants.CASH) {
      return cartItem?.cashOffer;
    } else {
      return cartItem?.tradeOffer;
    }
  }
};

export const handleViewTCGPlayer = (productDataObject) => {
  if (productDataObject?.tcgPlayerUrl) {
    window.open(productDataObject.tcgPlayerUrl, "_blank");
  }
};

export const handleViewPriceCharting = (
  priceChartingProductInfo,
  setProductUrl
) => {
  if (priceChartingProductInfo) {
    setProductUrl(
      `${process.env.REACT_APP_PRICECHARTING_IFRAME_URL}${stringToSlug(
        priceChartingProductInfo?.productObject["console-name"]
      )}/${stringToSlug(
        priceChartingProductInfo?.productObject["product-name"]
      )}`
    );
  }
};

export const handleViewEbaySales = (productName, consoleName, subCategory) => {
  if (productName && consoleName) {
    let name = productName.split(" ").join("+");
    if (subCategory && subCategory === "Systems") {
      name = name;
    } else {
      name =
        productName.split(" ").join("+") +
        "+" +
        consoleName.split(" ").join("+");
    }

    name = encodeQueryParam(name, "&");

    const productUrl = `${process.env.REACT_APP_EBAY_IFRAME_URL}${name}&_ipg=200&LH_Sold=1`;
    window.open(productUrl, "_blank");
  }
};

export const getPricechartingProductImage = (
  productUrl,
  setPricechartingProductImage
) => {
  return inventoryService
    .getPricechartingProductImage({ url: productUrl })
    .then(
      (response) => {
        if (
          response &&
          typeof response === "string" &&
          response.includes("https")
        ) {
          setPricechartingProductImage(response);
        } else {
          setPricechartingProductImage("");
        }
      },
      (error) => {}
    );
};

export const batchPricechartingProductImage = (priceChartingProductInfo) => {
  return inventoryService
    .getPricechartingProductImage({
      url: `${process.env.REACT_APP_PRICECHARTING_IFRAME_URL}${stringToSlug(
        priceChartingProductInfo?.productObject[
          inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
        ]
      )}/${stringToSlug(
        priceChartingProductInfo?.productObject[
          inventoryConstants.PRICECHARTING_PRODUCT_NAME
        ]
      )}`,
    })
    .then(
      (response) => {
        return response;
      },
      (error) => {}
    );
};

export const uploadCustomProductImage = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return storeService.uploadLogo(formData).then(
    (response) => {
      return response?.data?.data;
    },
    (error) => {
      customToast(error, toastType.ERROR);
    }
  );
};

export const handleEmptyOnFocus = (e) => {
  if (e.target.value === "0" || e.target.value === "0.00") {
    e.target.value = "";
  }
};

export const handleZeroOnBlur = (e, value, onChangeFunction) => {
  if (e.target.value === "") {
    e.target.value = parseToNumber(value);
    onChangeFunction(parseToNumber(value));
  }
};

//------match tags of a poduct
export const isTagsEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
};

//------update batch inventory array
export const updateBatchInventoryList = async (
  currentStore,
  inventoryActions,
  addBatchInventory,
  temporaryProductDataObject,
  setAddBatchInventory,
  markupPresets,
  handlePercentageFlag,
  scrollToTop
) => {
  const productsInInventory = await inventoryActions.getInventoryProducts(
    currentStore?.id,
    {
      id: temporaryProductDataObject.productId,
      categoryName: temporaryProductDataObject.consoleName,
      productName: temporaryProductDataObject.productName,
    }
  );

  if (productsInInventory?.length > 0) {
    temporaryProductDataObject = {
      ...temporaryProductDataObject,
      productsAvailableInInventory: productsInInventory,
    };
  }

  temporaryProductDataObject = {
    ...temporaryProductDataObject,
    inStockPrice: getInStockPrice(temporaryProductDataObject, markupPresets),
  };

  temporaryProductDataObject = {
    ...temporaryProductDataObject,
    imgUrl:
      productsInInventory[0]?.imgUrl ||
      (await batchPricechartingProductImage({
        productObject: {
          "console-name": temporaryProductDataObject.consoleName,
          "product-name": temporaryProductDataObject.productName,
        },
      })),
  };
  setAddBatchInventory(
    handlePercentageFlag([
      ...addBatchInventory,
      {
        ...temporaryProductDataObject,
      },
    ])
  );

  // // Duplicate product is not allowed
  // if (temporaryProductDataObject.productId) {
  //   const isProductDuplicate = isProductExistInBatchList(
  //     addBatchInventory,
  //     temporaryProductDataObject
  //   );

  //   if (!isProductDuplicate) {
  //     setAddBatchInventory([
  //       ...addBatchInventory,
  //       {
  //         ...temporaryProductDataObject,
  //       },
  //     ]);
  //   } else {
  //     const updatedInventory = addBatchInventory.map((item) =>
  //       isProductExistInBatchList([item], temporaryProductDataObject)
  //         ? { ...item, inStockQuantity: item.inStockQuantity + 1 }
  //         : item
  //     );
  //     // customToast("Duplicate product is not allowed", toastType.ERROR);
  //     setAddBatchInventory([...updatedInventory]);
  //   }
  // }
  scrollToTop();
};

export const getInStockProducts = async (
  currentStore,
  inventoryActions,
  addBatchInventory,
  setAddBatchInventory,
  markupPresets,
  handlePercentageFlag,
  tradeinMargin,
  isTrade = false
) => {
  try {
    // Iterate through each item in the batch inventory
    const updatedBatchInventory = await Promise.all(
      addBatchInventory.map(async (item) => {
        if (item.isDataFetching) {
          // Fetch product data only if isDataFetching is true
          const productsInInventory =
            await inventoryActions.getInventoryProducts(currentStore?.id, {
              id: item.productId,
              categoryName: item.consoleName,
              productName: item.productName,
            });

          // If products are found in the inventory, update the item
          if (productsInInventory?.length > 0) {
            item.productsAvailableInInventory = productsInInventory;
          }

          item.inStockPrice = getInStockPrice(item, markupPresets);

          if (Number(item.inStockPrice < Number(item.marketPrice))) {
            var tradeInMarginToApply = getMarginDropdownValue(
              item.marketPrice,
              tradeinMargin,
              item.productType,
              {
                ...item,
                tags: item.tags,
                condition: item.productCondition,
                cardRarity: item?.rarity?.label || EMPTY_STRING,
                subcategory: getProductSubCategory(item.genre),
              }
            );

            const { cashOffer, tradeOffer } = calculateOfferPrices(
              Number(item.inStockPrice),
              tradeInMarginToApply.marginObject,
              tradeInMarginToApply.marginObject.cashMarginPercentage,
              tradeInMarginToApply.marginObject.tradeinMarginPercentage
            );
            if (isTrade) {
              item.cashOffer = cashOffer;
              item.tradeOffer = tradeOffer;
            } else {
              item.costOfGoods = cashOffer;
            }
          }

          // Set the product image, fallback to an API if it's not available
          item.imgUrl =
            productsInInventory[0]?.imgUrl ||
            item.imgUrl ||
            (await batchPricechartingProductImage({
              productObject: {
                "console-name": item.consoleName,
                "product-name": item.productName,
              },
            }));

          // Set isDataFetching to false once the data has been fetched
          item.isDataFetching = false;
        }
        return item; // Return the updated item or unchanged item
      })
    );

    // Once all items are processed, update the state
    setAddBatchInventory(handlePercentageFlag(updatedBatchInventory));
  } catch (error) {
    console.error("Error fetching in-stock products:", error);
    // Optionally, you can handle errors here, like setting a loading state or an error message
  }
};

//------update batch inventory array
export const addProductInBatch = async (
  currentStore,
  inventoryActions,
  addBatchInventory,
  temporaryProductDataObject,
  setAddBatchInventory,
  markupPresets,
  handlePercentageFlag,
  scrollToTop
) => {
  setAddBatchInventory(
    handlePercentageFlag([
      ...addBatchInventory,
      {
        ...temporaryProductDataObject,
        isDataFetching: true,
      },
    ])
  );
  scrollToTop();
};

//-------is product exist in batch list
const isProductExistInBatchList = (batchList, newProduct) => {
  return batchList.some((product) => {
    if (product.productId === newProduct.productId)
      if (product.productType === PRODUCT_TYPES_ENUMS.TRADING_CARD) {
        return (
          (product.productCondition === newProduct.productCondition &&
            product.rarity.label === newProduct.rarity?.label) ||
          (!product.rarity.label && !newProduct.rarity?.label)
        );
      } else {
        return (
          product.productCondition === newProduct.productCondition &&
          isTagsEqual(
            product.tags?.map((tag) => tag),
            newProduct.tags?.map((tags) => tags.label)
          )
        );
      }
    else {
      return false;
    }
  });
};

// Function to merge similar products based on specified criteria
export const mergeBatchProducts = (batchList) => {
  let temporaryProductList = [];

  batchList.forEach((product) => {
    if (isProductExistInBatchList(temporaryProductList, product)) {
      let existingProduct = temporaryProductList.find(
        (item) => item.productId === product.productId
      );
      existingProduct = {
        ...existingProduct,
        inStockPrice: parseToDecimalNumber(
          Math.max(
            Number(existingProduct.inStockPrice),
            Number(product.inStockPrice)
          )
        ),
        costOfGoods: parseToDecimalNumber(
          Math.max(
            Number(existingProduct.costOfGoods),
            Number(product.costOfGoods)
          )
        ),
        inStockQuantity:
          Number(existingProduct.inStockQuantity) +
          Number(product.inStockQuantity),
      };

      temporaryProductList = temporaryProductList.map((item) => {
        return item.productId === product.productId ? existingProduct : item;
      });
    } else {
      temporaryProductList.push(product);
    }
  });

  return temporaryProductList;
};

//-------chech if product already exist
export const findExistingStockProduct = (row) => {
  const findProduct = row?.productsAvailableInInventory?.filter((product) => {
    if (
      product.price.type === row.productCondition &&
      isTagsEqual(
        product.tags?.map((tag) => tag),
        row.tags?.map((tags) => tags.label)
      )
    ) {
      if (
        row?.apiSource === inventoryConstants.POKEMON_API ||
        row?.apiSource === inventoryConstants.SCRYFALL_API
      ) {
        if (product.cardRarity === row.rarity?.label) {
          return product;
        }
      } else {
        return product;
      }
    }
  });
  return findProduct;
};

//------- Show stock price according to condition
export const getInStockPrice = (row, markupPresets) => {
  const isProductExist = findExistingStockProduct(row);
  let updatedMarketPrice = 0;
  // if (
  //   isProductExist &&
  //   isProductExist.price.unit_sell_price > row.marketPrice
  // ) {
  //   updatedMarketPrice = isProductExist.price.unit_sell_price;
  // } else {
  //   if (row.productType === PRODUCT_TYPES_ENUMS.TRADING_CARD) {
  //     updatedMarketPrice = row.marketPrice;
  //   } else {
  //     if (row.productCondition === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
  //       updatedMarketPrice = row.suggestedSellPrice;
  //     } else if (row.productCondition === PRODUCT_CONDITIONS_ENUMS.LOOSE) {
  //       updatedMarketPrice = row.suggestedSellPriceLoose;
  //     } else if (row.productCondition === PRODUCT_CONDITIONS_ENUMS.NEW) {
  //       updatedMarketPrice = row.suggestedSellPriceNew;
  //     }
  //   }
  // }
  if (isProductExist?.length) {
    updatedMarketPrice = isProductExist[0]?.price.unit_sell_price;
  } else {
    updatedMarketPrice = getPresetStockPrice(
      row.productType,
      row.marketPrice,
      markupPresets,
      row
    );
  }

  return parseToDecimalNumber(updatedMarketPrice);
};

//-------get product from inventory if exist
export const getProductIfExist = (row, onChangeStockPrice) => {
  const isProductExist = findExistingStockProduct(row);

  if (isProductExist?.length) {
    return (
      <span className="lh-base fw-bold">
        {getTotalNumberOfInStockProducts(isProductExist)} in stock at{" "}
        <span
          className="text-decoration-underline  cursor-pointer"
          onClick={() =>
            onChangeStockPrice(
              parseToDecimalNumber(isProductExist[0].price.unit_sell_price),
              row.id
            )
          }
        >
          $
          {parseToThousandSeperatorDecimalNumber(
            isProductExist[0].price.unit_sell_price
          )}
        </span>
      </span>
    );
  } else {
    return <span className="lh-base fw-bold">No Stock Found.</span>;
  }
};

export const findProductByCondition = (
  products,
  condition,
  selectedTags,
  selectedRarity,
  apiSource,
  product_name,
  category_name,
  productType
) => {
  if (products?.length > 0) {
    return products.find((product) => {
      if (
        (product?.price?.type || product?.productCondition) === condition &&
        isTagsEqual(product.tags || [], selectedTags || []) &&
        (product?.product_name || product?.productName) === product_name &&
        (product?.category_name || product?.consoleName) === category_name &&
        product.productType === productType
      ) {
        if (
          apiSource === inventoryConstants.POKEMON_API ||
          apiSource === inventoryConstants.SCRYFALL_API
        ) {
          if (product.cardRarity === selectedRarity) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
  }
  return null;
};

export function matchString(string) {
  return string.substring(0, 7);
}

export const updateInventorySKU = (InventoryArray) => {
  const updatedInventory = InventoryArray.map((item) => {
    if (item.sku[0].includes(transactionConstants.BATCH_TRADE)) {
      return {
        ...item,
        sku: [transactionConstants.BATCH_TRADE],
      };
    } else if (item.sku[0].includes(transactionConstants.QUICK_TRADE)) {
      return {
        ...item,
        sku: [transactionConstants.QUICK_TRADE],
      };
    } else {
      return item; // Return the item unchanged if neither condition is met
    }
  });

  return updatedInventory;
};

export const onChangeStockPrice = (
  newPrice,
  setFieldValue,
  marketPrice,
  initialGlobalRatio,
  marginTypeObject
) => {
  newPrice = Number(newPrice);
  let cashMarginPercentage =
    initialGlobalRatio?.globalMarginObject?.cashMarginPercentage;
  let tradeinMarginPercentage =
    initialGlobalRatio?.globalMarginObject?.tradeinMarginPercentage;
  if (newPrice !== 0) {
    const priceToUse =
      marketPrice === 0 ? newPrice : Math.min(newPrice, marketPrice);
    const { cashOffer, tradeOffer } = calculateOfferPrices(
      priceToUse,
      marginTypeObject?.marginObject,
      cashMarginPercentage ??
        marginTypeObject?.marginObject?.cashMarginPercentage,
      tradeinMarginPercentage ??
        marginTypeObject?.marginObject?.tradeinMarginPercentage
    );
    setFieldValue("cashOffer", convertToFixedPrecision(cashOffer));
    setFieldValue("tradeOffer", convertToFixedPrecision(tradeOffer));
  }
};

export const convertPricesToNumbers = (prices) => {
  const result = {};

  for (const [key, value] of Object.entries(prices)) {
    const numericValue =
      value !== null && !isNaN(parseFloat(value)) ? parseFloat(value) : null;
    result[key] = numericValue;
  }

  return result;
};

//------change price base on tags
export const changePriceBaseOnTags = (
  selectedTags,
  productDataObject,
  setProductDataObject,
  priceChartingProductInfo,
  inventoryConstants,
  PRODUCT_CONDITIONS_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
  productCondition,
  productType
) => {
  if (priceChartingProductInfo?.productObject) {
    let productMarketPrice = 0;
    let productSuggestedSellPrice = 0;
    if (productType === PRODUCT_TYPES_ENUMS.TRADING_CARD) {
      productMarketPrice = Number(productDataObject.marketPrice);
      productSuggestedSellPrice = Number(productDataObject?.suggestedSellPrice);
    } else {
      if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
        productMarketPrice =
          priceChartingProductInfo.productObject[
            inventoryConstants.COMPLETE_IN_BOX_PRICE
          ] || 0;
        productSuggestedSellPrice =
          priceChartingProductInfo?.productObject[
            inventoryConstants.RETAIL_COMPLETE_IN_BOX_PRICE
          ] || 0;
      } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE) {
        productMarketPrice =
          priceChartingProductInfo.productObject[
            inventoryConstants.LOOSE_PRICE
          ] || 0;
        productSuggestedSellPrice =
          priceChartingProductInfo?.productObject[
            inventoryConstants.RETAIL_LOOSE_PRICE
          ] || 0;
      } else if (
        productCondition?.value === PRODUCT_CONDITIONS_ENUMS.BOX_ONLY
      ) {
        productMarketPrice =
          priceChartingProductInfo.productObject[
            inventoryConstants.BOX_ONLY_PRICE
          ] || 0;
      } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.NEW) {
        productMarketPrice =
          priceChartingProductInfo.productObject[
            inventoryConstants.NEW_PRICE
          ] || 0;
        productSuggestedSellPrice =
          priceChartingProductInfo?.productObject[
            inventoryConstants.RETAIL_NEW_PRICE
          ] || 0;
      } else if (
        productCondition?.value === PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY
      ) {
        productMarketPrice =
          priceChartingProductInfo.productObject[
            inventoryConstants.MANUAL_ONLY_PRICE
          ] || 0;
      }
    }
    let productBoxOnlyPrice =
      priceChartingProductInfo.productObject[
        inventoryConstants.BOX_ONLY_PRICE
      ] || 0;
    let productManualOnlyPrice = Number(
      priceChartingProductInfo.productObject[
        inventoryConstants.MANUAL_ONLY_PRICE
      ] || 0
    );
    let damaged = 0;
    let missingWires = false;
    let missingController = false;
    let box = false;
    let missingManual = false;
    let withManual = false;

    if (selectedTags) {
      if (selectedTags.includes(inventoryConstants.PRODUCT_TAGS.DAMAGED)) {
        damaged = inventoryConstants.DAMAGED_PERCENTAGE;
      }
      if (
        selectedTags.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
      ) {
        missingWires = true;
      }
      if (
        selectedTags.includes(
          inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
        )
      ) {
        missingController = true;
      }
      if (selectedTags.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)) {
        box = true;
      }
      if (
        selectedTags.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
      ) {
        missingManual = true;
      }
      if (selectedTags.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)) {
        withManual = true;
      }

      const productLoosePrice =
        priceChartingProductInfo.productObject[inventoryConstants.LOOSE_PRICE];

      const missingWiresDeduction =
        inventoryConstants.MISSING_WIRES_PERCENTAGE * productLoosePrice;
      const missingControllerDeduction =
        inventoryConstants.MISSING_CONTROLLER_PERCENTAGE * productLoosePrice;
      const totalDecreasePercentage = damaged;

      //-------Product Market Price after damaged percentage Decution
      let productPriceAfterDecrement =
        productMarketPrice - productMarketPrice * totalDecreasePercentage;

      //-------Product Suggested Sell Price after damaged percentage Decution
      let productsuggestedSellPriceDecrement =
        productSuggestedSellPrice -
        productSuggestedSellPrice * totalDecreasePercentage;

      //-------If with-manaul Tag is Selected Then Add Manual
      //-------Price in Product Market Price
      if (withManual) {
        productPriceAfterDecrement =
          productPriceAfterDecrement + productManualOnlyPrice;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement + productManualOnlyPrice;
      }

      //-------If missing-Manaul Tag is Selected Then Deduct Manual
      //-------Price from Product Market Price
      if (missingManual) {
        productPriceAfterDecrement =
          productPriceAfterDecrement - productManualOnlyPrice;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement - productManualOnlyPrice;
      }

      //-------If missing-wires Tag is Selected Then Deduct missing wires Percentage
      //-------Price from Product Market Price
      if (missingWires) {
        productPriceAfterDecrement =
          productPriceAfterDecrement - missingWiresDeduction;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement - missingWiresDeduction;
      }

      //-------If missing-controller Tag is Selected Then Deduct missing controller Percentage
      //-------Price from Product Market Price
      if (missingController) {
        productPriceAfterDecrement =
          productPriceAfterDecrement - missingControllerDeduction;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement - missingControllerDeduction;
      }

      //-------If Product is System and with-box tag is selected then
      //-------add price of box
      if (productDataObject.genre === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM) {
        if (box) {
          productPriceAfterDecrement =
            productPriceAfterDecrement + productBoxOnlyPrice;
          productsuggestedSellPriceDecrement =
            productsuggestedSellPriceDecrement + productBoxOnlyPrice;
        }
      }

      setProductDataObject({
        ...productDataObject,
        marketPrice: productPriceAfterDecrement,
        suggestedSellPrice: productsuggestedSellPriceDecrement,
      });
    } else {
      setProductDataObject({
        ...productDataObject,
        marketPrice: productMarketPrice,
        suggestedSellPrice: productSuggestedSellPrice,
      });
    }
  }
};

//------batch edit change price base on tags
export const batchEditChangePriceBaseOnTags = (
  selectedTagsList,
  productDataObject,
  setProductDataObject,
  productCondition,
  productType,
  tradeinMargin,
  markupPresets,
  isTradeBatch = false
) => {
  if (productDataObject) {
    let productMarketPrice = 0;
    let productSuggestedSellPrice = 0;
    if (productType === PRODUCT_TYPES_ENUMS.TRADING_CARD) {
      productMarketPrice = Number(productDataObject.marketPrice);
      productSuggestedSellPrice = Number(productDataObject?.suggestedSellPrice);
    } else {
      const conditionPriceData =
        getMarketPriceBaseOnCondition(productDataObject);
      productMarketPrice =
        conditionPriceData[productCondition?.value]?.marketPrice;
      productSuggestedSellPrice =
        conditionPriceData[productCondition?.value]?.suggestedSellPrice;
    }

    let productBoxOnlyPrice = productDataObject?.boxOnlyPrice || 0;
    let productManualOnlyPrice = Number(productDataObject?.manualPrice || 0);
    let damaged = 0;
    let missingWires = false;
    let missingController = false;
    let box = false;
    let missingManual = false;
    let withManual = false;

    const selectedTags = selectedTagsList?.map((tag) => tag.label) || [];

    if (selectedTags) {
      if (selectedTags.includes(inventoryConstants.PRODUCT_TAGS.DAMAGED)) {
        damaged = inventoryConstants.DAMAGED_PERCENTAGE;
      }
      if (
        selectedTags.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
      ) {
        missingWires = true;
      }
      if (
        selectedTags.includes(
          inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
        )
      ) {
        missingController = true;
      }
      if (selectedTags.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)) {
        box = true;
      }
      if (
        selectedTags.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
      ) {
        missingManual = true;
      }
      if (selectedTags.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)) {
        withManual = true;
      }

      const productLoosePrice = productDataObject?.loosePrice;

      const missingWiresDeduction =
        inventoryConstants.MISSING_WIRES_PERCENTAGE * productLoosePrice;
      const missingControllerDeduction =
        inventoryConstants.MISSING_CONTROLLER_PERCENTAGE * productLoosePrice;
      const totalDecreasePercentage = damaged;

      //-------Product Market Price after damaged percentage Decution
      let productPriceAfterDecrement =
        productMarketPrice - productMarketPrice * totalDecreasePercentage;

      //-------Product Suggested Sell Price after damaged percentage Decution
      let productsuggestedSellPriceDecrement =
        productSuggestedSellPrice -
        productSuggestedSellPrice * totalDecreasePercentage;

      //-------If with-manaul Tag is Selected Then Add Manual
      //-------Price in Product Market Price
      if (withManual) {
        productPriceAfterDecrement =
          productPriceAfterDecrement + productManualOnlyPrice;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement + productManualOnlyPrice;
      }

      //-------If missing-Manaul Tag is Selected Then Deduct Manual
      //-------Price from Product Market Price
      if (missingManual) {
        productPriceAfterDecrement =
          productPriceAfterDecrement - productManualOnlyPrice;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement - productManualOnlyPrice;
      }

      //-------If missing-wires Tag is Selected Then Deduct missing wires Percentage
      //-------Price from Product Market Price
      if (missingWires) {
        productPriceAfterDecrement =
          productPriceAfterDecrement - missingWiresDeduction;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement - missingWiresDeduction;
      }

      //-------If missing-controller Tag is Selected Then Deduct missing controller Percentage
      //-------Price from Product Market Price
      if (missingController) {
        productPriceAfterDecrement =
          productPriceAfterDecrement - missingControllerDeduction;
        productsuggestedSellPriceDecrement =
          productsuggestedSellPriceDecrement - missingControllerDeduction;
      }

      //-------If Product is System and with-box tag is selected then
      //-------add price of box
      if (productDataObject.genre === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM) {
        if (box) {
          productPriceAfterDecrement =
            productPriceAfterDecrement + productBoxOnlyPrice;
          productsuggestedSellPriceDecrement =
            productsuggestedSellPriceDecrement + productBoxOnlyPrice;
        }
      }

      const updatedItem = {
        ...productDataObject,
        marketPrice: productPriceAfterDecrement,
        suggestedSellPrice: productsuggestedSellPriceDecrement,
      };

      var tradeInMarginToApply = getMarginDropdownValue(
        productPriceAfterDecrement,
        tradeinMargin,
        updatedItem.productType,
        {
          ...updatedItem,
          tags: updatedItem.tags,
          condition: updatedItem.productCondition,
          cardRarity: updatedItem?.rarity?.label || EMPTY_STRING,
          subcategory: getProductSubCategory(updatedItem.genre),
        }
      );
      const { cashOffer, tradeOffer } = calculateOfferPrices(
        updatedItem.marketPrice,
        tradeInMarginToApply.marginObject,
        tradeInMarginToApply.marginObject.cashMarginPercentage,
        tradeInMarginToApply.marginObject.tradeinMarginPercentage
      );

      if (isTradeBatch) {
        updatedItem.cashOffer = convertToFixedPrecision(cashOffer); // Update cashOffer in the updatedItem
        updatedItem.tradeOffer = convertToFixedPrecision(tradeOffer);
      } else {
        updatedItem.costOfGoods = convertToFixedPrecision(cashOffer); // Update cashOffer in the updatedItem
      }

      const existingProduct = findExistingStockProduct({
        ...updatedItem,
        tags: selectedTagsList,
      });
      updatedItem.tags = selectedTagsList;
      updatedItem.inStockPrice = existingProduct?.length
        ? existingProduct[0]?.price?.unit_sell_price
        : getPresetStockPrice(
            updatedItem.productType,
            updatedItem.marketPrice,
            markupPresets,
            updatedItem
          );
      setProductDataObject(updatedItem);
    } else {
      setProductDataObject({
        ...productDataObject,
        marketPrice: productMarketPrice,
        suggestedSellPrice: productSuggestedSellPrice,
      });
    }
  }
};

export const exportTagFunc = (tags) => {
  if (!tags) {
    return "";
  }
  return tags.join("; ").replaceAll("#", globalConstants.EMPTY_STRING);
};

export const importTagFunc = (tags) => {
  if (!tags) {
    return [];
  }
  return tags.replace(/(?<=^|; )\b(?=\S)/g, "#").split("; ");
};

export const importUpcFunc = (value) => {
  return String(value);
};

export const exportUpcFunc = (value) => {
  return String(value);
};

export const exportStringArrayFunc = (array) => {
  return array.join("; ");
};

export const importStringArrayFunc = (array) => {
  return array.split("; ");
};

export const handleEmailOrSMSMessage = (
  messageType,
  isEmail,
  values,
  transactionData,
  customToast,
  toastMessages,
  toastType,
  sendEmailOrSMS
) => {
  if (messageType === EMAIL_MESSAGE_TYPES.TRANSACTION_RECEIPT) {
    if (isEmail) {
      if (!emailRegex.test(values.email)) {
        customToast(toastMessages.VALID_EMAIL, toastType.ERROR);
      } else {
        sendEmailOrSMS({
          isEmail: true,
          isSms: false,
          email: values.email,
          metaData: { store: values.store, transactionId: transactionData?.id },
          type: messageType,
        });
      }
    } else {
      if (!phoneRegex.test(values.mobile)) {
        customToast(toastMessages.VALID_MOBILE, toastType.ERROR);
      } else {
        sendEmailOrSMS({
          isEmail: false,
          isSms: true,
          phoneNumber: (values.mobile || "")
            .replace(/[()\- ]/g, "")
            .replace(/^/, "+1"),
          metaData: { store: values.store, transactionId: transactionData?.id },
          type: messageType,
        });
      }
    }
  } else if (messageType === EMAIL_MESSAGE_TYPES.USER_REPORTED_ISSUE) {
    sendEmailOrSMS(
      {
        isEmail: true,
        isSms: false,
        email: process.env.REACT_APP_SUPPORT_EMAIL,
        metaData: {
          store: values.store,
          userName: values.fullname,
          userEmail: values.email,
          errorDescription: values.problem,
          errorScreenUrl: values.errorScreenUrl,
        },
        type: messageType,
      },
      EMAIL_MESSAGE_TYPES.USER_REPORTED_ISSUE
    );
  }
};

export const calculateOfferPrices = (
  marketPrice,
  tradeInMarginObject,
  cashMarginPercentage,
  tradeinMarginPercentage
) => {
  console.log(("trade margin object", tradeInMarginObject));

  const marketPriceNum = parseToNumber(marketPrice);
  const cashMarginPercentageNum = parseToNumber(cashMarginPercentage);
  const tradeinMarginPercentageNum = parseToNumber(tradeinMarginPercentage);

  const ratio = tradeinMarginPercentageNum / cashMarginPercentageNum;
  // const marketCashOffer = parseToDecimalNumber(
  //   (marketPriceNum * cashMarginPercentageNum) / 100
  // );
  const marketTradeOffer = parseToDecimalNumber(
    (marketPriceNum * tradeinMarginPercentageNum) / 100
  );
  const cashOffer = parseToDecimalNumber(
    tradeInMarginObject?.productMarginSetBy ===
      tradeInDiscountConstant.FIXED_VALUE
      ? parseToDecimalNumber(cashMarginPercentage)
      : marketTradeOffer / ratio
  );
  const tradeOffer = parseToDecimalNumber(
    tradeInMarginObject?.productMarginSetBy ===
      tradeInDiscountConstant.FIXED_VALUE
      ? parseToDecimalNumber(tradeinMarginPercentage)
      : cashOffer * ratio
  );
  return { cashOffer, tradeOffer };
};

export const downloadImage = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error downloading image:", error);
    return null;
  }
};

// Returns the shortest 6-digit SKU
export const getShortestSku = (skuArr) => {
  // validate skuArr
  if (
    skuArr === EMPTY_STRING ||
    (Array.isArray(skuArr) && skuArr.length === 0)
  ) {
    return skuArr;
  } else if (!Array.isArray(skuArr)) {
    return skuArr;
  }

  // filter 6 digit skus
  const sixDigitSkuArr = skuArr.filter((sku) => {
    return sku.length === 6;
  });
  // find shortest 6 digit sku
  let shortestSku = 0;
  if (sixDigitSkuArr.length) {
    shortestSku = sixDigitSkuArr[0];
    sixDigitSkuArr.forEach((sku) => {
      if (Number(sku) < Number(shortestSku)) {
        shortestSku = sku;
      }
    });
  } else if (skuArr.length) {
    shortestSku = skuArr[0];
    skuArr.forEach((sku) => {
      if (Number(sku) < Number(shortestSku)) {
        shortestSku = sku;
      }
    });
  } else {
    throw new Error("Invalid Sku");
  }

  return shortestSku;
};

export const isArraySame = (array1, array2) => {
  if (array1.sort().join(",") === array2.sort().join(",")) {
    return true;
  } else {
    return false;
  }
};

// check either the sku array has the any sku which has batch-trade-in in it
export const isBatchTradeSku = (skuArr) => {
  if (!skuArr) {
    return false;
  }
  return skuArr.some((sku) => sku.includes(transactionConstants.BATCH_TRADE));
};

// check either the sku array has the any sku which has quick-trade-in in it
export const isQuickTradeSku = (skuArr) => {
  if (!skuArr) {
    return false;
  }
  return skuArr.some((sku) => sku.includes(transactionConstants.QUICK_TRADE));
};

// check either the sku array has the any sku which has custom-item in it
export const isCustomSku = (skuArr) => {
  if (!skuArr) {
    return false;
  }
  return skuArr.some((sku) => sku.includes(inventoryConstants.CUSTOM_ITEM));
};

export const getCountOfTotalItems = (array, key) => {
  return array.reduce((acc, item) => acc + Number(item[key]), 0);
};

export const getCountOfTradeTotalItems = (array, key) => {
  return array.reduce((acc, item) => acc + Number(item.price[key]), 0);
};

export function resizeAndDarkenImageBlob(blob, newWidth, threshold = 128) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onload = function () {
      var img = new Image();

      img.onload = function () {
        // Create canvas for the original image
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        // Set canvas size to match the image size
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get the ImageData from the canvas
        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var pixels = imageData.data;

        // Convert each pixel to grayscale
        for (var i = 0; i < pixels.length; i += 4) {
          var red = pixels[i];
          var green = pixels[i + 1];
          var blue = pixels[i + 2];
          var grayscale = RGBToGrayScale(red, green, blue);

          // Set the RGB values to the grayscale value
          pixels[i] = grayscale; // Red
          pixels[i + 1] = grayscale; // Green
          pixels[i + 2] = grayscale; // Blue
          // Alpha (pixels[i + 3]) remains unchanged
        }

        // Put the modified ImageData back onto the canvas
        ctx.putImageData(imageData, 0, 0);

        // Resize the image
        var tempCanvas = document.createElement("canvas");
        var tempCtx = tempCanvas.getContext("2d");

        tempCanvas.width = newWidth;
        tempCanvas.height = (newWidth / canvas.width) * canvas.height;

        tempCtx.drawImage(canvas, 0, 0, newWidth, tempCanvas.height);

        // Convert canvas to blob
        tempCanvas.toBlob(function (resizedBlob) {
          if (resizedBlob) {
            resolve(resizedBlob);
          } else {
            reject(new Error("Failed to convert canvas to blob"));
          }
        }, blob.type); // Maintain original image type
      };

      img.onerror = function () {
        reject(new Error("Failed to load image"));
      };

      img.src = reader.result;
    };

    reader.onerror = function () {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(blob);
  });
}

// Function to convert RGB values to grayscale using Rec. 709 coefficients
function RGBToGrayScale(red, green, blue) {
  return (red * 6966 + green * 23436 + blue * 2366) >> 15;
}

//-------resize logo image for print on label or receipt design
export function resizeImageBlob(blob, newWidth) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.readAsDataURL(blob);

    reader.onload = function () {
      var img = new Image();

      img.src = reader.result;

      img.onload = function () {
        var aspectRatio = img.width / img.height;
        var newHeight = newWidth / aspectRatio;

        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image without altering its appearance
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert canvas back to blob
        canvas.toBlob(function (resizedBlob) {
          resolve(resizedBlob);
        }, blob.type); // Maintain original image type
      };
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
}

//-------set product in redux after getting
export const setDefaultProductDataObject = async (
  markupPresets,
  setRarities,
  currentStore,
  inventoryActions,
  productDataObject,
  addBatchInventory,
  setSelectedRarity,
  tradeinMargin,
  isAddBatchInventory,
  setProductDataObject,
  setAddBatchInventory,
  priceChartingProductInfo,
  handlePercentageFlag,
  scrollToTop,
  emptyPriceChartingProductObject
) => {
  let temporaryProductType = globalConstants.EMPTY_STRING;
  let temporaryProductDataObject = { ...productDataObject };
  let temporaryProductCategory = globalConstants.EMPTY_STRING;
  let temporaryProductCondition = globalConstants.EMPTY_STRING;
  let temporaryProductConditionList = globalConstants.EMPTY_STRING;
  if (
    priceChartingProductInfo.productMetaData.sourceApi ===
    inventoryConstants.PRICECHARTING_API
  ) {
    if (
      priceChartingProductInfo.productMetaData.genre ===
      TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
    ) {
      temporaryProductType = PRODUCT_TYPES_ENUMS.TRADING_CARD;
      temporaryProductCategory = TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD;
      temporaryProductConditionList = inventoryConstants.TRADING_CARD_CONDITION;
      temporaryProductCondition =
        inventoryConstants.TRADING_CARD_CONDITION[0].value;
    } else {
      if (
        priceChartingProductInfo.productMetaData.genre ===
        TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
      ) {
        temporaryProductType = PRODUCT_TYPES_ENUMS.VIDEO_GAME;
        temporaryProductCondition = handleDefaultBatchCondition(
          priceChartingProductInfo.productObject[
            inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
          ],
          inventoryConstants.VIDEO_GAME_SYSTEM_CONDITION,
          priceChartingProductInfo.productObject["genre"]
        );
        temporaryProductConditionList = handleBatchCondition(
          priceChartingProductInfo.productObject[
            inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
          ],
          inventoryConstants.VIDEO_GAME_SYSTEM_CONDITION
        );
      } else {
        temporaryProductType = PRODUCT_TYPES_ENUMS.VIDEO_GAME;
        temporaryProductConditionList = handleBatchCondition(
          priceChartingProductInfo.productObject[
            inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
          ],
          inventoryConstants.VIDEO_GAME_CONDITION
        );
        temporaryProductCondition = handleDefaultBatchCondition(
          priceChartingProductInfo.productObject[
            inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
          ],
          inventoryConstants.VIDEO_GAME_CONDITION,
          priceChartingProductInfo.productObject["genre"]
        );
      }
    }

    temporaryProductDataObject = {
      ...productDataObject,
      id: addBatchInventory.length,
      productName:
        priceChartingProductInfo.productObject[
          inventoryConstants.PRICECHARTING_PRODUCT_NAME
        ],
      consoleName: temporaryProductCategory
        ? temporaryProductCategory
        : priceChartingProductInfo.productObject[
            inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
          ],
      marketPrice: convertToUsd(getSystemMarketPrice(priceChartingProductInfo)),
      suggestedSellPrice: convertToUsd(
        getSystemSuggestedMarketPrice(priceChartingProductInfo)
      ),
      suggestedSellPriceComplete: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.RETAIL_COMPLETE_IN_BOX_PRICE
        ]
      ),
      suggestedSellPriceLoose: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.RETAIL_LOOSE_PRICE
        ]
      ),
      suggestedSellPriceNew: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.RETAIL_NEW_PRICE
        ]
      ),
      gameStopBuyPrice: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.GAMESTOP_PRICE
        ]
      ),
      upc:
        priceChartingProductInfo.productObject.upc ||
        globalConstants.EMPTY_STRING,
      genre: priceChartingProductInfo.productMetaData.genre,
      productId: priceChartingProductInfo.productObject.id,
      productType: temporaryProductType,
      productCondition: temporaryProductCondition,
      productConditionList: temporaryProductConditionList,
      completePrice: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.COMPLETE_IN_BOX_PRICE
        ]
      ),
      loosePrice: convertToUsd(
        priceChartingProductInfo.productObject[inventoryConstants.LOOSE_PRICE]
      ),
      newPrice: convertToUsd(
        priceChartingProductInfo.productObject[inventoryConstants.NEW_PRICE]
      ),
      boxOnlyPrice: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.BOX_ONLY_PRICE
        ]
      ),
      manualPrice: convertToUsd(
        priceChartingProductInfo.productObject[
          inventoryConstants.MANUAL_ONLY_PRICE
        ]
      ),
      apiSource: priceChartingProductInfo.productMetaData.sourceApi,
      raritiesList: [],
      rarity: {},
      imgUrl: globalConstants.EMPTY_STRING,
      epid:
        priceChartingProductInfo.productObject.epid ||
        globalConstants.EMPTY_STRING,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
    };
    setProductDataObject({ ...temporaryProductDataObject });
  } else if (
    priceChartingProductInfo.productMetaData.sourceApi ===
      inventoryConstants.POKEMON_API ||
    priceChartingProductInfo.productMetaData.sourceApi ===
      inventoryConstants.SCRYFALL_API
  ) {
    temporaryProductType = PRODUCT_TYPES_ENUMS.TRADING_CARD;
    temporaryProductCondition =
      inventoryConstants.TRADING_CARD_CONDITION[0].value;
    temporaryProductConditionList = inventoryConstants.TRADING_CARD_CONDITION;
    if (
      priceChartingProductInfo.productMetaData.sourceApi ===
      inventoryConstants.POKEMON_API
    ) {
      const raritiesList = priceChartingProductInfo?.productObject?.tcgplayer
        ?.prices
        ? Object.keys(
            priceChartingProductInfo.productObject.tcgplayer.prices
          ).map((key, index) => {
            const value = `${JSON.stringify(
              priceChartingProductInfo.productObject.tcgplayer.prices[key]
                .market
            )}`;
            return { label: key, value: { key: index, value: value } };
          })
        : [];

      setRarities(raritiesList);
      setSelectedRarity(raritiesList[0]);

      const pokemonProductName = `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set.name} - ${priceChartingProductInfo.productObject.number}/${priceChartingProductInfo.productObject.set.printedTotal})`;
      temporaryProductDataObject = {
        ...productDataObject,
        id: addBatchInventory.length,
        productName: pokemonProductName,
        consoleName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
        upc:
          priceChartingProductInfo.productObject.upc ||
          globalConstants.EMPTY_STRING,
        genre: priceChartingProductInfo.productMetaData.genre,
        productId: priceChartingProductInfo.productObject.id,
        productType: temporaryProductType,
        productCondition: temporaryProductCondition,
        productConditionList: temporaryProductConditionList,
        marketPrice: raritiesList[0]?.value.value || 0,
        apiSource: priceChartingProductInfo.productMetaData["sourceApi"],
        raritiesList: raritiesList,
        rarity: raritiesList.length > 0 ? raritiesList[0] : null,
        tcgPlayerUrl:
          priceChartingProductInfo?.productObject?.tcgplayer?.url ||
          globalConstants.EMPTY_STRING,
        epid: globalConstants.EMPTY_STRING,
        imgUrl:
          priceChartingProductInfo?.productObject?.images.small ||
          globalConstants.EMPTY_STRING,
      };
      setProductDataObject({ ...temporaryProductDataObject });
    } else if (
      priceChartingProductInfo.productMetaData.sourceApi ===
      inventoryConstants.SCRYFALL_API
    ) {
      const priceList = convertPricesToNumbers(
        priceChartingProductInfo.productObject.prices
      );
      const raritiesList = priceList
        ? Object.keys(priceList).map((key, index) => {
            const value = `${JSON.stringify(priceList[key])}`;
            return {
              label: key,
              value: { key: index, value: value === "null" ? 0 : value },
            };
          })
        : [];

      setRarities(raritiesList);
      setSelectedRarity(raritiesList[0]);
      temporaryProductDataObject = {
        ...productDataObject,
        id: addBatchInventory.length,
        productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
        consoleName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
        upc:
          priceChartingProductInfo.productObject.upc ||
          globalConstants.EMPTY_STRING,
        genre: priceChartingProductInfo.productMetaData.genre,
        productId: priceChartingProductInfo.productObject.id,
        productType: temporaryProductType,
        productCondition: temporaryProductCondition,
        productConditionList: temporaryProductConditionList,
        marketPrice: raritiesList[0]?.value.value || 0,
        apiSource: priceChartingProductInfo.productMetaData["sourceApi"],
        raritiesList: raritiesList,
        rarity: raritiesList.length > 0 ? raritiesList[0] : null,
        tcgPlayerUrl:
          priceChartingProductInfo.productObject.purchase_uris?.tcgplayer ||
          globalConstants.EMPTY_STRING,
        epid: globalConstants.EMPTY_STRING,
        imgUrl:
          priceChartingProductInfo.productObject.image_uris?.small ||
          globalConstants.EMPTY_STRING,
      };
      setProductDataObject({ ...temporaryProductDataObject });
    }
  }

  var tradeInMarginToApply = getMarginDropdownValue(
    temporaryProductDataObject.marketPrice,
    tradeinMargin,
    temporaryProductDataObject.productType,
    {
      ...temporaryProductDataObject,
      tags: temporaryProductDataObject.tags,
      condition: temporaryProductDataObject.productCondition,
      cardRarity: temporaryProductDataObject?.rarity?.label || EMPTY_STRING,
      subcategory: getProductSubCategory(temporaryProductDataObject.genre),
    }
  );
  const { cashOffer, tradeOffer } = calculateOfferPrices(
    temporaryProductDataObject.marketPrice,
    tradeInMarginToApply.marginObject,
    tradeInMarginToApply?.marginObject?.cashMarginPercentage,
    tradeInMarginToApply?.marginObject?.tradeinMarginPercentage
  );

  if (isAddBatchInventory) {
    temporaryProductDataObject = {
      ...temporaryProductDataObject,
      costOfGoods: parseToDecimalNumber(cashOffer),
    };
  } else {
    temporaryProductDataObject = {
      ...temporaryProductDataObject,
      tradeInMarginTypeObject: tradeInMarginToApply,
      cashOffer: cashOffer,
      tradeOffer: tradeOffer,
    };
  }

  addProductInBatch(
    currentStore,
    inventoryActions,
    addBatchInventory,
    temporaryProductDataObject,
    setAddBatchInventory,
    markupPresets,
    handlePercentageFlag,
    scrollToTop
  );
  emptyPriceChartingProductObject();
};

//-------get product stock price
export const getTradingCardStockPrice = (item, selectedOption) => {
  const existingCardProduct = findExistingStockProduct(item);

  if (
    existingCardProduct?.length &&
    existingCardProduct[0].price.unit_sell_price > selectedOption?.value?.value
  ) {
    return parseToDecimalNumber(existingCardProduct[0].price.unit_sell_price);
  } else {
    return parseToDecimalNumber(selectedOption?.value?.value);
  }
};

//-------handle rarity on change
export const handleRaritiesChange = (
  Id,
  selectedOption,
  addBatchInventory,
  setAddBatchInventory,
  tradeinMargin,
  isTradeBatch = false,
  markupPresets
) => {
  if (selectedOption) {
    // Find the item to update
    const itemToUpdate = addBatchInventory.find((item) => item.id === Id);

    if (itemToUpdate) {
      // Parse marketPrice from the selected option
      const marketPrice = parseToNumber(selectedOption?.value?.value);

      // Update the item directly
      itemToUpdate.rarity = selectedOption;
      itemToUpdate.tags = [];
      itemToUpdate.marketPrice = marketPrice;

      const tradeInMarginToApply = getMarginDropdownValue(
        marketPrice,
        tradeinMargin,
        itemToUpdate.productType,
        {
          ...itemToUpdate,
          tags: itemToUpdate.tags,
          condition: itemToUpdate.productCondition,
          cardRarity: itemToUpdate?.rarity?.label || EMPTY_STRING,
          subcategory: getProductSubCategory(itemToUpdate.genre),
        }
      );
      const { cashOffer, tradeOffer } = calculateOfferPrices(
        marketPrice,
        tradeInMarginToApply.marginObject,
        tradeInMarginToApply.marginObject.cashMarginPercentage,
        tradeInMarginToApply.marginObject.tradeinMarginPercentage
      );

      if (isTradeBatch) {
        itemToUpdate.cashOffer = convertToFixedPrecision(cashOffer);
        itemToUpdate.tradeOffer = convertToFixedPrecision(tradeOffer);
      } else {
        itemToUpdate.costOfGoods = convertToFixedPrecision(cashOffer);
      }

      itemToUpdate.inStockPrice = getInStockPrice(itemToUpdate, markupPresets);

      // Since we're directly modifying the object, create a new array to trigger a re-render
      setAddBatchInventory([...addBatchInventory]);
    }
  }
};

//-------handle onchnage of product condition
export const onChangeProductCondition = (
  markupPresets,
  Id,
  newCondition,
  isTradeBatch,
  addBatchInventory,
  setAddBatchInventory,
  tradeinMargin
) => {
  // Find the item to be updated
  const itemToUpdate = addBatchInventory.find((item) => item.id === Id);

  if (itemToUpdate) {
    // Update productCondition and calculate marketPrice
    itemToUpdate.productCondition = newCondition;
    itemToUpdate.suggestedSellPrice = 0;
    itemToUpdate.tags = [];

    if (itemToUpdate.productType !== PRODUCT_TYPES_ENUMS.TRADING_CARD) {
      const conditionData = getMarketPriceBaseOnCondition(itemToUpdate);
      const marketPrice = conditionData[newCondition]?.marketPrice;
      itemToUpdate.marketPrice = marketPrice;
      itemToUpdate.suggestedSellPrice =
        conditionData[newCondition]?.suggestedSellPrice;

      // Calculate offers based on marketPrice
      const tradeInMarginToApply = getMarginDropdownValue(
        marketPrice,
        tradeinMargin,
        itemToUpdate.productType,
        {
          ...itemToUpdate,
          tags: itemToUpdate.tags,
          condition: itemToUpdate.productCondition,
          cardRarity: itemToUpdate?.rarity?.label || EMPTY_STRING,
          subcategory: getProductSubCategory(itemToUpdate.genre),
        }
      );
      const { cashOffer, tradeOffer } = calculateOfferPrices(
        marketPrice,
        tradeInMarginToApply.marginObject,
        tradeInMarginToApply.marginObject.cashMarginPercentage,
        tradeInMarginToApply.marginObject.tradeinMarginPercentage
      );

      if (isTradeBatch) {
        itemToUpdate.cashOffer = parseToDecimalNumber(cashOffer);
        itemToUpdate.tradeOffer = parseToDecimalNumber(tradeOffer);
      } else {
        itemToUpdate.costOfGoods = parseToDecimalNumber(cashOffer);
      }
      itemToUpdate.inStockPrice = getInStockPrice(itemToUpdate, markupPresets);
    }

    setAddBatchInventory([...addBatchInventory]);
  }
};

//-------handle onchnage of product condition
export const handleBulkConditionChange = (
  markupPresets,
  newCondition,
  bulkUpdateItemIds,
  isTradeBatch,
  addBatchInventory,
  setAddBatchInventory,
  tradeinMargin,
  handleBatchInventoryPercentage
) => {
  const updatedInventory = addBatchInventory.map((item) => {
    let marketPrice = item.marketPrice; // Initialize marketPrice outside the if block

    if (
      bulkUpdateItemIds.includes(item.id) &&
      item.productCondition !== newCondition
    ) {
      // Update productCondition and calculate marketPrice
      const productConditions = item.productConditionList.map((i) => i.value);

      const updatedItem = {
        ...item,
        productCondition: productConditions.includes(newCondition)
          ? newCondition
          : item.productCondition,
        tags: [],
      };

      marketPrice =
        newCondition === PRODUCT_CONDITIONS_ENUMS.COMPLETE
          ? item?.completePrice ?? 0
          : newCondition === PRODUCT_CONDITIONS_ENUMS.LOOSE
          ? item?.loosePrice ?? 0
          : newCondition === PRODUCT_CONDITIONS_ENUMS.NEW
          ? item?.newPrice ?? 0
          : newCondition === PRODUCT_CONDITIONS_ENUMS.BOX_ONLY
          ? item?.boxOnlyPrice ?? 0
          : newCondition === PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY
          ? item?.manualPrice ?? 0
          : item?.marketPrice ?? 0;

      updatedItem.marketPrice = marketPrice;

      var tradeInMarginToApply = getMarginDropdownValue(
        marketPrice,
        tradeinMargin,
        updatedItem.productType,
        {
          ...updatedItem,
          tags: updatedItem.tags,
          condition: updatedItem.productCondition,
          cardRarity: updatedItem?.rarity?.label || EMPTY_STRING,
          subcategory: getProductSubCategory(updatedItem.genre),
        }
      );

      // Calculate offers based on marketPrice
      const { cashOffer, tradeOffer } = calculateOfferPrices(
        marketPrice,
        tradeInMarginToApply.marginObject,
        tradeInMarginToApply?.marginObject.cashMarginPercentage,
        tradeInMarginToApply?.marginObject.tradeinMarginPercentage
      );

      if (isTradeBatch) {
        updatedItem.cashOffer = convertToFixedPrecision(cashOffer); // Update cashOffer in the updatedItem
        updatedItem.tradeOffer = convertToFixedPrecision(tradeOffer);
      } else {
        updatedItem.costOfGoods = convertToFixedPrecision(cashOffer); // Update cashOffer in the updatedItem
      }
      updatedItem.inStockPrice = getInStockPrice(updatedItem, markupPresets);
      return updatedItem;
    }

    return item;
  });

  setAddBatchInventory(handleBatchInventoryPercentage(updatedInventory));
};

//-------handle onchnage of product condition
export const handleResetPrices = (
  markupPresets,
  isTradeBatch,
  addBatchInventory,
  setAddBatchInventory,
  tradeinMargin,
  handleBatchInventoryPercentage
) => {
  const updatedInventory = addBatchInventory.map((item) => {
    let marketPrice = item.marketPrice; // Initialize marketPrice outside the if block
    let newCondition = item.productCondition;
    const updatedItem = item;
    marketPrice =
      newCondition === PRODUCT_CONDITIONS_ENUMS.COMPLETE
        ? item?.completePrice ?? 0
        : newCondition === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? item?.loosePrice ?? 0
        : newCondition === PRODUCT_CONDITIONS_ENUMS.NEW
        ? item?.newPrice ?? 0
        : newCondition === PRODUCT_CONDITIONS_ENUMS.BOX_ONLY
        ? item?.boxOnlyPrice ?? 0
        : newCondition === PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY
        ? item?.manualPrice ?? 0
        : item?.marketPrice ?? 0;

    updatedItem.marketPrice = marketPrice;

    var tradeInMarginToApply = getMarginDropdownValue(
      marketPrice,
      tradeinMargin,
      updatedItem.productType,
      {
        ...updatedItem,
        tags: updatedItem.tags,
        condition: updatedItem.productCondition,
        cardRarity: updatedItem?.rarity?.label || EMPTY_STRING,
        subcategory: getProductSubCategory(updatedItem.genre),
      }
    );

    // Calculate offers based on marketPrice
    const { cashOffer, tradeOffer } = calculateOfferPrices(
      marketPrice,
      tradeInMarginToApply.marginObject,
      tradeInMarginToApply?.marginObject.cashMarginPercentage,
      tradeInMarginToApply?.marginObject.tradeinMarginPercentage
    );

    if (isTradeBatch) {
      updatedItem.cashOffer = parseToDecimalNumber(cashOffer); // Update cashOffer in the updatedItem
      updatedItem.tradeOffer = parseToDecimalNumber(tradeOffer);
    } else {
      updatedItem.costOfGoods = parseToDecimalNumber(cashOffer); // Update cashOffer in the updatedItem
    }
    updatedItem.inStockPrice = getInStockPrice(updatedItem, markupPresets);
    return updatedItem;
  });

  setAddBatchInventory(handleBatchInventoryPercentage(updatedInventory));
};

//-------handle trade value change
export const handleTradeOfferValues = (
  InputValue,
  setFieldValue,
  initialRatio
) => {
  const newTradeValue = parseFloat(InputValue);
  setFieldValue("tradeOffer", newTradeValue);
  const newCashOffer = newTradeValue / initialRatio;
  setFieldValue("cashOffer", parseToDecimalNumber(newCashOffer));
};

//-------handle cash value change
export const handleCashOfferValues = (
  InputValue,
  setFieldValue,
  initialRatio
) => {
  const newCashOffer = parseFloat(InputValue); // Assuming InputValue is the new cash offer value
  setFieldValue("cashOffer", newCashOffer);

  const newTradeValue = newCashOffer * initialRatio;
  setFieldValue("tradeOffer", parseToDecimalNumber(newTradeValue));
};

export const handleTagList = (productTags, genre, condition, filterTag) => {
  const defaultTags =
    genre === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
      ? productTags?.systemsDefaultTags
      : productTags?.gamesDefaultTags;

  const conditionTags = {
    [PRODUCT_CONDITIONS_ENUMS.COMPLETE]: defaultTags?.complete,
    [PRODUCT_CONDITIONS_ENUMS.LOOSE]: defaultTags?.loose,
    [PRODUCT_CONDITIONS_ENUMS.BOX_ONLY]: defaultTags?.boxOnly,
    [PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY]: defaultTags?.manualOnly,
  };

  return (conditionTags[condition] || []).filter((tag) => tag !== filterTag);
};

const updateConditionsAndTags = (
  conditions,
  genre,
  productTags,
  setProductCondition,
  setConditionList,
  setProductTagsList,
  removeTag
) => {
  setConditionList(conditions);
  const selectedCondition =
    getProductSubCategory(genre) === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
      ? conditions[1]
      : conditions[0];
  setProductCondition(selectedCondition);
  setProductTagsList(
    handleTagList(productTags, genre, selectedCondition.value, removeTag)
  );
};

//-------handle condition and tag list
export const handleConditionsAndTags = (
  consoleName,
  genre,
  productTags,
  setConditionList,
  setProductCondition,
  setProductTagsList,
  condition
) => {
  const isNintendo =
    inventoryConstants.NINTENDO_CATEGORIES.includes(consoleName);
  const isSkyLander =
    inventoryConstants.SKYLANDER_CATEGORIES.includes(consoleName);
  if (isNintendo) {
    updateConditionsAndTags(
      inventoryConstants.NINTENDO_CONDITIONS,
      genre,
      productTags,
      setProductCondition,
      setConditionList,
      setProductTagsList,
      inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
    );
  } else if (isSkyLander) {
    updateConditionsAndTags(
      inventoryConstants.SKYLANDERS_CONDITION,
      genre,
      productTags,
      setProductCondition,
      setConditionList,
      setProductTagsList,
      inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
    );
  } else {
    updateConditionsAndTags(
      condition,
      genre,
      productTags,
      setProductCondition,
      setConditionList,
      setProductTagsList,
      ""
    );
  }
};

//-------handle specific categories and its condotions
export const handleShowNintendoCondition = (category, condition) => {
  return inventoryConstants.NINTENDO_CATEGORIES.includes(category) &&
    condition === PRODUCT_CONDITIONS_ENUMS.COMPLETE
    ? PRODUCT_CONDITIONS_ENUMS.WITH_POSTER
    : condition;
};

//-------handle batch specific categories condition
export const handleBatchCondition = (category, conditions) => {
  return inventoryConstants.NINTENDO_CATEGORIES.includes(category)
    ? inventoryConstants.NINTENDO_CONDITIONS
    : inventoryConstants.SKYLANDER_CATEGORIES.includes(category)
    ? inventoryConstants.SKYLANDERS_CONDITION
    : conditions;
};

export const handleDefaultBatchCondition = (category, conditions, genre) => {
  return inventoryConstants.NINTENDO_CATEGORIES.includes(category)
    ? getProductSubCategory(genre) === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
      ? inventoryConstants.NINTENDO_CONDITIONS[1].value
      : inventoryConstants.NINTENDO_CONDITIONS[0].value
    : inventoryConstants.SKYLANDER_CATEGORIES.includes(category)
    ? getProductSubCategory(genre) === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
      ? inventoryConstants.SKYLANDERS_CONDITION[1].value
      : inventoryConstants.SKYLANDERS_CONDITION[0].value
    : getProductSubCategory(genre) === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
    ? conditions[1].value
    : conditions[0].value;
};

//-------Loading Indicator
export const LoadingIndicator = () => {
  return (
    <div
      className={`ms-auto me-2 spinner-border spinner-border-sm text-purple`}
      role="status"
    >
      <span className="sr-only"></span>
    </div>
  );
};

export const addSearchKeyToArrayObject = (array, keys) => {
  return array.map((obj) => {
    const searchColumnData = keys.map((key) => obj[key]).join("");
    return { ...obj, searchColumnData };
  });
};

export const generateUuid = () => {
  return uuidv4();
};

export const calculateStockPriceByMarkup = (stockPrice, roundUpMethod) => {
  let newStockPrice = stockPrice;
  if (
    roundUpMethod === tradeInDiscountConstant.ROUND_VALUE_TO_95 &&
    newStockPrice !== 0
  ) {
    newStockPrice = Math.ceil(newStockPrice) - 0.05;
  } else if (
    roundUpMethod === tradeInDiscountConstant.ROUND_VALUE_TO_99 &&
    newStockPrice !== 0
  ) {
    newStockPrice = Math.ceil(newStockPrice) - 0.01;
  } else if (roundUpMethod === tradeInDiscountConstant.ROUND_VALUE_TO_00) {
    newStockPrice = Math.ceil(newStockPrice);
  }
  return parseToDecimalNumber(newStockPrice);
};

const findDefaultTradeInIfAny = (list) => {
  return list.find(
    (margin) =>
      margin.minPriceRange === tradeInDiscountConstant.DEFAULT_MIN_RANGE &&
      margin.maxPriceRange === tradeInDiscountConstant.DEFAULT_MAX_RANGE
  );
};

const findPriceRangeTradeInIfAny = (list, productMarketPrice) => {
  return list.find(
    (item) =>
      productMarketPrice >= item.minPriceRange &&
      productMarketPrice <= item.maxPriceRange
  );
};

const findFilterTradeInList = (
  list,
  key,
  value,
  groupType,
  isSubcategory = false,
  productCategory = globalConstants.EMPTY_STRING
) => {
  return isSubcategory
    ? list?.filter((margin) =>
        margin[groupType].some(
          (group) =>
            group[key] === value &&
            group["parentCategoryName"] === productCategory
        )
      )
    : list?.filter((margin) =>
        margin[groupType].some((group) => group[key] === value)
      );
};

//   marketPrice,
//   marginTypes,
//   productType,
//   productDataObject
// ) => {
//   const productMarketPrice = Number(marketPrice);

//   // Check for sub category margin with specific category
//   const subCategoryMarginWithSpecificCategoryList = findFilterTradeInList(
//     marginTypes.subcategoryMargins,
//     "categoryName",
//     getProductSubCategory(productDataObject.genre),
//     "marginGroup",
//     true,
//     productDataObject.consoleName
//   );

//   if (subCategoryMarginWithSpecificCategoryList?.length) {
//     let subCategoryWithSpecificCategoryMargin = "";
//     subCategoryWithSpecificCategoryMargin = findPriceRangeTradeInIfAny(
//       subCategoryMarginWithSpecificCategoryList,
//       productMarketPrice
//     );

//     if (subCategoryWithSpecificCategoryMargin) {
//       return {
//         marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//         marginObject: subCategoryWithSpecificCategoryMargin,
//       };
//     } else {
//       subCategoryWithSpecificCategoryMargin = findDefaultTradeInIfAny(
//         subCategoryWithSpecificCategoryMargin
//       );
//       if (subCategoryWithSpecificCategoryMargin) {
//         return {
//           marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//           marginObject: subCategoryWithSpecificCategoryMargin,
//         };
//       }
//     }
//   }

//   // Check for sub category margin with all category
//   const subCategoryMarginWithAllCategoryList = findFilterTradeInList(
//     marginTypes.subcategoryMargins,
//     "categoryName",
//     getProductSubCategory(productDataObject.genre),
//     "marginGroup",
//     true,
//     PRODUCT_TYPES_ENUMS.ALL
//   );
//   if (subCategoryMarginWithAllCategoryList?.length) {
//     let subCategoryMarginWithAllCategory = "";
//     subCategoryMarginWithAllCategory = findPriceRangeTradeInIfAny(
//       subCategoryMarginWithAllCategoryList,
//       productMarketPrice
//     );

//     if (subCategoryMarginWithAllCategory) {
//       return {
//         marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//         marginObject: subCategoryMarginWithAllCategory,
//       };
//     } else {
//       subCategoryMarginWithAllCategory = findDefaultTradeInIfAny(
//         subCategoryMarginWithAllCategoryList
//       );
//       if (subCategoryMarginWithAllCategory) {
//         return {
//           marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//           marginObject: subCategoryMarginWithAllCategory,
//         };
//       }
//     }
//   }

//   // Check for category margin
//   const categoryMarginList = findFilterTradeInList(
//     marginTypes.categoryMargins,
//     "categoryName",
//     productDataObject.consoleName,
//     "marginGroup"
//   );

//   if (categoryMarginList?.length) {
//     let categoryMargin = "";
//     categoryMargin = findPriceRangeTradeInIfAny(
//       categoryMarginList,
//       productMarketPrice
//     );

//     if (categoryMargin) {
//       return {
//         marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//         marginObject: categoryMargin,
//       };
//     } else {
//       categoryMargin = findDefaultTradeInIfAny(categoryMarginList);
//       if (categoryMargin) {
//         return {
//           marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//           marginObject: categoryMargin,
//         };
//       }
//     }
//   }

//   // Check for product type margin
//   const productTypeMarginList = findFilterTradeInList(
//     marginTypes.productTypeMargins,
//     "productType",
//     productType,
//     "marginGroup"
//   );

//   if (productTypeMarginList?.length) {
//     let productTypeMargin = "";
//     productTypeMargin = findPriceRangeTradeInIfAny(
//       productTypeMarginList,
//       productMarketPrice
//     );

//     if (productTypeMargin) {
//       return {
//         marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//         marginObject: productTypeMargin,
//       };
//     } else {
//       productTypeMargin = findDefaultTradeInIfAny(productTypeMarginList);
//       if (productTypeMargin) {
//         return {
//           marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
//           marginObject: productTypeMargin,
//         };
//       }
//     }
//   }

//   // If none of the above, default to global margin
//   return {
//     marginType: inventoryConstants.MARGIN_TYPE_GLOBAL,
//     marginObject: marginTypes?.globalMargin,
//   };
// };

// Helper function to find margin and return if found
const findMarginAndReturn = (
  productMarketPrice,
  marginList,
  filterProperty,
  filterValue,
  isSubCategory = false,
  defaultFilterValue = null
) => {
  const filteredMargins = findFilterTradeInList(
    marginList,
    filterProperty,
    filterValue,
    "marginGroup",
    isSubCategory,
    defaultFilterValue
  );
  if (filteredMargins?.length) {
    const foundMargin =
      findPriceRangeTradeInIfAny(filteredMargins, productMarketPrice) ||
      findDefaultTradeInIfAny(filteredMargins);
    if (foundMargin) {
      return {
        marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
        marginObject: foundMargin,
      };
    }
  }
  return null;
};

const isProductMarginExist = (
  productMargins,
  productDataObject,
  productType,
  productMarketPrice
) => {
  const filteredMargins = productMargins?.filter(
    (margin) =>
      margin.productCondition === productDataObject.condition &&
      isArraySame(margin.tags, productDataObject.tags) &&
      margin.marginGroup.some(
        (group) =>
          String(group.product_name).toLowerCase() ===
            String(productDataObject.productName).toLowerCase() &&
          String(group.category_name).toLowerCase() ===
            String(productDataObject.consoleName).toLowerCase() &&
          String(group.subcategory).toLowerCase() ===
            String(productDataObject.subcategory).toLowerCase() &&
          group.cardRarity === productDataObject.cardRarity &&
          group.productType === productType
      )
  );

  if (filteredMargins?.length) {
    const foundMargin =
      findPriceRangeTradeInIfAny(filteredMargins, productMarketPrice) ||
      findDefaultTradeInIfAny(filteredMargins);
    if (foundMargin) {
      return {
        marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
        marginObject: foundMargin,
      };
    }
  }
  return null;
};

export const getMarginDropdownValue = (
  marketPrice,
  marginTypes,
  productType,
  productDataObject,
  isGetSubCategoryFromGenre = true
) => {
  const productMarketPrice = Number(marketPrice);
  const { genre, consoleName } = productDataObject;
  let marginResult = false;

  // Check product margin if exist
  const productMargins = marginTypes.productMargins;

  marginResult = isProductMarginExist(
    productMargins,
    productDataObject,
    productType,
    marketPrice
  );
  if (marginResult) {
    return marginResult;
  }

  // Check subcategory margin with specific category
  marginResult = findMarginAndReturn(
    productMarketPrice,
    marginTypes.subcategoryMargins,
    "categoryName",
    isGetSubCategoryFromGenre ? getProductSubCategory(genre) : genre,
    true,
    consoleName
  );
  if (marginResult) {
    return marginResult;
  }

  // Check subcategory margin with all category
  marginResult = findMarginAndReturn(
    productMarketPrice,
    marginTypes.subcategoryMargins,
    "categoryName",
    isGetSubCategoryFromGenre ? getProductSubCategory(genre) : genre,
    true,
    PRODUCT_TYPES_ENUMS.ALL
  );
  if (marginResult) {
    return marginResult;
  }

  // Check category margin
  marginResult = findMarginAndReturn(
    productMarketPrice,
    marginTypes.categoryMargins,
    "categoryName",
    consoleName
  );
  if (marginResult) {
    return marginResult;
  }

  // Check product type margin
  marginResult = findMarginAndReturn(
    productMarketPrice,
    marginTypes.productTypeMargins,
    "productType",
    productType
  );
  if (marginResult) {
    return marginResult;
  }

  // Default to global margin
  return {
    marginType: inventoryConstants.MARGIN_TYPE_GLOBAL,
    marginObject: marginTypes?.globalMargin,
  };
};

const findMarkupAndReturn = (
  markupPresets,
  stockPrice,
  productMarketPrice,
  markupList,
  filterProperty,
  filterValue,
  isSubCategory = false,
  defaultFilterValue = null
) => {
  const filteredMarkups = findFilterTradeInList(
    markupList,
    filterProperty,
    filterValue,
    "markupGroup",
    isSubCategory,
    defaultFilterValue
  );
  if (filteredMarkups?.length) {
    const foundMarkup =
      findPriceRangeTradeInIfAny(filteredMarkups, productMarketPrice) ||
      findDefaultTradeInIfAny(filteredMarkups);
    if (foundMarkup) {
      stockPrice *= 1 + foundMarkup.markupPercentage / 100;
      return calculateStockPriceByMarkup(
        stockPrice,
        markupPresets.globalMarkup.roundValuesTo
      );
    }
  }
  return null;
};

//-------get stock price based on market price and stock price
export const getPresetStockPrice = (
  productType,
  marketPrice,
  markupPresets,
  productDataObject,
  isInventoryProduct = false,
  subcategory = EMPTY_STRING,
  categoryName = EMPTY_STRING
) => {
  let productMarketPrice = Number(marketPrice);
  let stockPrice = productMarketPrice;
  const { genre, consoleName } = productDataObject;

  // Check subcategory markup with specific category
  let markupResult = findMarkupAndReturn(
    markupPresets,
    stockPrice,
    productMarketPrice,
    markupPresets.subcategoryMarkups,
    "categoryName",
    isInventoryProduct ? subcategory : getProductSubCategory(genre),
    true,
    isInventoryProduct ? categoryName : consoleName
  );
  if (markupResult) {
    return markupResult;
  }

  // Check subcategory markup with all category
  markupResult = findMarkupAndReturn(
    markupPresets,
    stockPrice,
    productMarketPrice,
    markupPresets.subcategoryMarkups,
    "categoryName",
    isInventoryProduct ? getProductSubCategory(genre) : subcategory,
    true,
    PRODUCT_TYPES_ENUMS.ALL
  );
  if (markupResult) {
    return markupResult;
  }

  // Check category markup
  markupResult = findMarkupAndReturn(
    markupPresets,
    stockPrice,
    productMarketPrice,
    markupPresets.categoryMarkups,
    "categoryName",
    isInventoryProduct ? categoryName : consoleName
  );
  if (markupResult) {
    return markupResult;
  }

  // Check product type markup
  markupResult = findMarkupAndReturn(
    markupPresets,
    stockPrice,
    productMarketPrice,
    markupPresets.productTypeMarkups,
    "productType",
    productType
  );
  if (markupResult) {
    return markupResult;
  }

  stockPrice *= 1 + markupPresets.globalMarkup.markupPercentage / 100;

  return calculateStockPriceByMarkup(
    stockPrice,
    markupPresets.globalMarkup.roundValuesTo
  );
};

// ------find discount and return it
const findDisocuntAndReturn = (
  productMarketPrice,
  markupList,
  filterProperty,
  filterValue,
  isSubCategory = false,
  defaultFilterValue = null
) => {
  const filteredMarkups = findFilterTradeInList(
    markupList,
    filterProperty,
    filterValue,
    "discountGroup",
    isSubCategory,
    defaultFilterValue
  );
  if (filteredMarkups?.length) {
    const foundDiscount =
      findPriceRangeTradeInIfAny(filteredMarkups, productMarketPrice) ||
      findDefaultTradeInIfAny(filteredMarkups);
    if (
      foundDiscount &&
      checkDiscountValidity(foundDiscount.startDate, foundDiscount.endDate)
    ) {
      return foundDiscount;
    }
  }
  return null;
};

//-------get stock price based on market price and stock price
export const getProductDiscount = (product, discounts) => {
  let productStockPrice = Number(product.price);
  const { category, productType, genre, subcategory } = product;
  // Check subcategory discount with specific category
  let discountResult = findDisocuntAndReturn(
    productStockPrice,
    discounts.subCategoryDiscounts,
    "categoryName",
    subcategory,
    true,
    category
  );
  if (discountResult) {
    return discountResult;
  }

  // Check subcategory discount with all category
  discountResult = findDisocuntAndReturn(
    productStockPrice,
    discounts.subCategoryDiscounts,
    "categoryName",
    subcategory,
    true,
    PRODUCT_TYPES_ENUMS.ALL
  );
  if (discountResult) {
    return discountResult;
  }

  // Check category discount
  discountResult = findDisocuntAndReturn(
    productStockPrice,
    discounts.categoryDiscounts,
    "categoryName",
    category
  );
  if (discountResult) {
    return discountResult;
  }

  // Check product type discount
  discountResult = findDisocuntAndReturn(
    productStockPrice,
    discounts.productTypeDiscounts,
    "productType",
    productType
  );
  if (discountResult) {
    return discountResult;
  }
};

export const handleMarginChange = (
  setMargin,
  marketPrice,
  productType,
  setFieldValue,
  selectedOption,
  marginTypesvalue,
  productDataObject,
  setMarginTypeObject
) => {
  let marginTypeObject = {};
  let margin = {};

  let marginObject;

  if (selectedOption === inventoryConstants.MARGIN_TYPE_GLOBAL) {
    marginObject = marginTypesvalue.globalMargin;
  } else {
    marginObject = getMarginDropdownValue(
      marketPrice,
      marginTypesvalue,
      productType,
      {
        ...productDataObject,
        tags: productDataObject.tags,
        condition: productDataObject.productCondition,
        cardRarity: productDataObject?.rarity?.label || EMPTY_STRING,
        subcategory: getProductSubCategory(productDataObject.genre),
      }
    ).marginObject;
  }

  if (marginObject) {
    const tradeinMarginPercentage = marginObject.tradeinMarginPercentage / 100;
    const cashMarginPercentage = marginObject.cashMarginPercentage / 100;
    marginTypeObject = {
      marginType: inventoryConstants.MARGIN_TYPE_STANDARD,
      marginObject: marginObject,
    };
    margin = {
      cashTradeMarginPercentage: cashMarginPercentage / tradeinMarginPercentage,
    };
    const { cashOffer, tradeOffer } = calculateOfferPrices(
      marketPrice,
      marginObject,
      marginObject.cashMarginPercentage,
      marginObject.tradeinMarginPercentage
    );

    setFieldValue("tradeOffer", convertToFixedPrecision(tradeOffer));
    setFieldValue("cashOffer", convertToFixedPrecision(cashOffer));
  }

  const updatedMarginObject = {
    marginType:
      selectedOption === inventoryConstants.MARGIN_TYPE_GLOBAL
        ? inventoryConstants.MARGIN_TYPE_GLOBAL
        : inventoryConstants.MARGIN_TYPE_STANDARD,
    marginObject: marginObject,
  };

  setMarginTypeObject(updatedMarginObject);
  setMargin(margin);
};

export const handleConditionChange = (
  selectedOption,
  setProductCondition,
  priceChartingProductInfo,
  productTags,
  setProductTagsList,
  setProductDataObject,
  productDataObject,
  setSelectedTags
) => {
  if (!selectedOption?.value) return;

  setProductCondition(selectedOption);

  const { value } = selectedOption;
  const conditionData = {
    [PRODUCT_CONDITIONS_ENUMS.COMPLETE]: {
      marketPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.COMPLETE_IN_BOX_PRICE
        ] || 0,
      suggestedSellPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.RETAIL_COMPLETE_IN_BOX_PRICE
        ] || 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.NEW]: {
      marketPrice:
        priceChartingProductInfo?.productObject[inventoryConstants.NEW_PRICE],
      suggestedSellPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.RETAIL_NEW_PRICE
        ] || 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.LOOSE]: {
      marketPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.LOOSE_PRICE
        ] || 0,
      suggestedSellPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.RETAIL_LOOSE_PRICE
        ] || 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.BOX_ONLY]: {
      marketPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.BOX_ONLY_PRICE
        ] || 0,
      suggestedSellPrice: 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY]: {
      marketPrice:
        priceChartingProductInfo?.productObject[
          inventoryConstants.MANUAL_ONLY_PRICE
        ] || 0,
      suggestedSellPrice: 0,
    },
  };

  const conditionObject = conditionData[value] || {};

  setProductDataObject((prevState) => ({
    ...prevState,
    ...conditionObject,
  }));

  setProductTagsList(
    handleTagList(
      productTags,
      priceChartingProductInfo?.productObject.genre,
      value,
      inventoryConstants.NINTENDO_CATEGORIES.includes(
        priceChartingProductInfo?.productObject[
          inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
        ]
      ) ||
        inventoryConstants.SKYLANDER_CATEGORIES.includes(
          priceChartingProductInfo?.productObject[
            inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
          ]
        )
        ? value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
          ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
          : inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
        : ""
    )
  );

  setSelectedTags([]);
};

export const getMarketPriceBaseOnCondition = (productDataObject) => {
  return {
    [PRODUCT_CONDITIONS_ENUMS.COMPLETE]: {
      marketPrice: productDataObject?.completePrice || 0,
      suggestedSellPrice: productDataObject?.suggestedSellPriceComplete || 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.NEW]: {
      marketPrice: productDataObject?.newPrice || 0,
      suggestedSellPrice: productDataObject?.suggestedSellPriceNew || 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.LOOSE]: {
      marketPrice: productDataObject?.loosePrice || 0,
      suggestedSellPrice: productDataObject?.suggestedSellPriceLoose || 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.BOX_ONLY]: {
      marketPrice: productDataObject?.boxOnlyPrice || 0,
      suggestedSellPrice: 0,
    },
    [PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY]: {
      marketPrice: productDataObject?.manualPrice || 0,
      suggestedSellPrice: 0,
    },
  };
};

export const handleEditBatchConditionChange = (
  markupPresets,
  selectedOption,
  setProductCondition,
  productTags,
  setProductTagsList,
  setProductDataObject,
  productDataObject,
  setSelectedTags,
  tradeinMargin,
  setAddSelectedTags,
  isTradeBatch = false
) => {
  //-------selected product condition not fonud
  if (!selectedOption?.value) return;

  //-------set product condition
  setProductCondition(selectedOption);

  if (
    productDataObject?.consoleName ===
      TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
    productDataObject?.consoleName === TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD
  ) {
    const existingProduct = findExistingStockProduct({
      ...productDataObject,
      productCondition: selectedOption?.value,
    });

    const inStockPrice = existingProduct?.length
      ? existingProduct[0]?.price?.unit_sell_price
      : getPresetStockPrice(
          productDataObject.productType,
          productDataObject.marketPrice,
          markupPresets,
          productDataObject
        );

    setProductDataObject({
      ...productDataObject,
      inStockPrice: inStockPrice,
      productCondition: selectedOption?.value,
      tags: [],
    });

    return;
  } else {
    const { value } = selectedOption;

    const updatedItem = productDataObject;
    updatedItem.productCondition = selectedOption?.value;
    const conditionData = getMarketPriceBaseOnCondition(productDataObject);

    updatedItem.marketPrice = conditionData[value]?.marketPrice || 0;
    updatedItem.suggestedSellPrice =
      conditionData[value]?.suggestedSellPrice || 0;

    var tradeInMarginToApply = getMarginDropdownValue(
      updatedItem.marketPrice,
      tradeinMargin,
      updatedItem.productType,
      {
        ...updatedItem,
        tags: updatedItem.tags,
        condition: updatedItem.productCondition,
        cardRarity: updatedItem?.rarity?.label || EMPTY_STRING,
        subcategory: getProductSubCategory(updatedItem.genre),
      }
    );
    const { cashOffer, tradeOffer } = calculateOfferPrices(
      updatedItem.marketPrice,
      tradeInMarginToApply.marginObject,
      tradeInMarginToApply.marginObject.cashMarginPercentage,
      tradeInMarginToApply.marginObject.tradeinMarginPercentage
    );

    if (isTradeBatch) {
      updatedItem.cashOffer = convertToFixedPrecision(cashOffer); // Update cashOffer in the updatedItem
      updatedItem.tradeOffer = convertToFixedPrecision(tradeOffer);
    } else {
      updatedItem.costOfGoods = convertToFixedPrecision(cashOffer); // Update cashOffer in the updatedItem
    }

    const existingProduct = findExistingStockProduct({
      ...updatedItem,
      productCondition: selectedOption?.value,
    });

    updatedItem.inStockPrice = existingProduct?.length
      ? existingProduct[0]?.price?.unit_sell_price
      : getPresetStockPrice(
          updatedItem.productType,
          updatedItem.marketPrice,
          markupPresets,
          updatedItem
        );
    updatedItem.tags = [];
    setProductDataObject(updatedItem);

    setProductTagsList(
      handleTagList(
        productTags,
        productDataObject?.genre,
        value,
        inventoryConstants.NINTENDO_CATEGORIES.includes(
          productDataObject?.consoleName
        ) ||
          inventoryConstants.SKYLANDER_CATEGORIES.includes(
            productDataObject?.consoleName
          )
          ? value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
            ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
            : inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
          : ""
      )
    );
    setSelectedTags([]);
    setAddSelectedTags([]);
  }
};

export const handleRaritiesChangeInSingleAddEdit = (
  selectedOption,
  setSelectedRarity,
  setProductDataObject,
  productDataObject,
  tradeinMargin,
  markupPresets,
  isTradeBatch = false
) => {
  if (selectedOption) {
    setSelectedRarity(selectedOption);
    let marketPrice = parseToNumber(selectedOption?.value?.value);
    var tradeInMarginToApply = getMarginDropdownValue(
      marketPrice,
      tradeinMargin,
      productDataObject.productType,
      {
        ...productDataObject,
        tags: productDataObject.selectedTags,
        condition: productDataObject.productCondition,
        cardRarity: selectedOption?.label || EMPTY_STRING,
        subcategory: getProductSubCategory(productDataObject.genre),
      }
    );
    const { cashOffer, tradeOffer } = calculateOfferPrices(
      marketPrice,
      tradeInMarginToApply.marginObject,
      tradeInMarginToApply.marginObject.cashMarginPercentage,
      tradeInMarginToApply.marginObject.tradeinMarginPercentage
    );

    const existingProduct = findExistingStockProduct({
      ...productDataObject,
      rarity: selectedOption,
    });

    const inStockPrice = existingProduct?.length
      ? existingProduct[0]?.price?.unit_sell_price
      : getPresetStockPrice(
          productDataObject.productType,
          selectedOption?.value?.value,
          markupPresets,
          productDataObject
        );

    if (isTradeBatch) {
      setProductDataObject({
        ...productDataObject,
        marketPrice: selectedOption?.value?.value,
        cashOffer: convertToFixedPrecision(cashOffer),
        tradeOffer: convertToFixedPrecision(tradeOffer),
        inStockPrice: inStockPrice,
        rarity: selectedOption,
      });
    } else {
      setProductDataObject({
        ...productDataObject,
        marketPrice: selectedOption?.value?.value,
        costOfGoods: convertToFixedPrecision(cashOffer),
        inStockPrice: inStockPrice,
        rarity: selectedOption,
      });
    }
  }
};

export const getProductDetailCardTags = (tags, sku) => {
  if (tags && tags?.length === 0) {
    return "";
  } else if (tags?.length === 1) {
    return tags[0];
  } else {
    return (
      <TableCustomHeader
        id={`sku-number-in-array-${getShortestSku(sku)}`}
        label={<span>{`${tags[0]} + ${tags?.length - 1} more`}</span>}
        tooltopText={
          <div className="d-flex flex-column ">
            {tags?.map((s) => (
              <p className=" sku-wrapper text-nowrap text-start fw-normal m-0">
                {s}
              </p>
            ))}
          </div>
        }
        className="sku-tooltip ms-1"
      />
    );
  }
};

export const getProductSubCategory = (productGenre) => {
  for (let item of GENRE_SUB_CATEGORY.PRICECHARTING_GENRE) {
    if (item.genre.includes(productGenre)) {
      return item.subCategory;
    }
  }
  return globalConstants.EMPTY_STRING;
};

export const handleShowCategoryName = (isSubcategory, category) => {
  let name = globalConstants.EMPTY_STRING;
  if (isSubcategory) {
    name = category.parentCategoryName + " - " + category.name;
  } else {
    name = category.productTypeName + " - " + category.name;
  }
  return name;
};

// set the selected options in edit case of tradeins margin, preset prices and discount
export const handleMapSelectedOptionsInEdit = (groupArray, groupType) => {
  return groupArray.map((option) => {
    return groupType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
      ? {
          id: option.categoryId,
          name: option.categoryName,
          parentCategoryId: option.parentCategoryId,
          parentCategoryName: option.parentCategoryName,
        }
      : groupType === SET_BY_MARGIN_ENUMS.PRODUCT.value
      ? {
          product_id: option.product_id,
          product_name: option.product_name,
          productType: option.productType,
          category_name: option.category_name,
          cardRarity: option.cardRarity,
          subcategory: option.subcategory,
        }
      : {
          id:
            groupType === SET_BY_MARGIN_ENUMS.TYPE.value
              ? undefined
              : option.categoryId,
          name:
            groupType === SET_BY_MARGIN_ENUMS.TYPE.value
              ? option?.productType
              : option.categoryName,
        };
  });
};

// filters add/edit modal search field options for tradeins margin, preset prices and discount
export const filterApplyToSearchOptions = (
  setBy,
  productTypes,
  categories,
  subcategories,
  filterProductType,
  filterProductCategory,
  selectedCategories
) => {
  let filterOptions = [];
  if (setBy === SET_BY_MARGIN_ENUMS.TYPE.value) {
    filterOptions = productTypes.map((type) => ({
      id: type.id,
      name: type.productType,
    }));
  } else if (setBy === SET_BY_MARGIN_ENUMS.CATEGORY.value) {
    filterOptions = categories.map((category) => ({
      id: category.id,
      productTypeId: category.productTypeId,
      name: category.name,
    }));

    if (filterProductType.label !== PRODUCT_TYPES_ENUMS.ALL) {
      filterOptions = filterOptions.filter(
        (item) => item.productTypeId === filterProductType.value
      );
    }
  } else if (setBy === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value) {
    filterOptions = subcategories?.map((subcategory) => ({
      id: subcategory.id,
      productTypeId: subcategory.parentProductTypeId,
      parentCategoryId: subcategory.parentCategoryId,
      name: subcategory.name,
    }));
    if (filterProductType.label !== PRODUCT_TYPES_ENUMS.ALL) {
      filterOptions = filterOptions.filter(
        (item) => item.productTypeId === filterProductType.value
      );
    }
    if (filterProductCategory?.value !== PRODUCT_TYPES_ENUMS.ALL) {
      filterOptions = filterOptions.filter(
        (item) =>
          item.parentCategoryId === filterProductCategory.value ||
          item.parentCategoryId === SYSTEM_SUBCATEGORY
      );
    }
  }

  const selectedValueNameList = selectedCategories?.map((item) => item.name);

  filterOptions = filterOptions.filter(
    (item) => !selectedValueNameList?.includes(item.name)
  );

  return filterOptions;
};

export const handleRemoveSelectedCategory = (
  categoryToRemove,
  selectedCategories,
  searchCategories
) => {
  let updatedSelectedCategories = selectedCategories.filter(
    (category) => category.name !== categoryToRemove
  );
  let updatedSearchCategories = [];

  const fullCategoryObject = selectedCategories.find(
    (category) => category.name === categoryToRemove
  );

  if (fullCategoryObject) {
    updatedSearchCategories = [...searchCategories, fullCategoryObject].sort(
      (a, b) => a.name.localeCompare(b.name)
    );
  } else {
    updatedSearchCategories = searchCategories;
  }

  return {
    updatedSelectedCategories,
    updatedSearchCategories,
  };
};

export const getTypeOrCategoryObject = (list, id) => {
  return list.find((item) => item.id === id);
};

export const getTypeOrCategoryIdByName = (
  list,
  name,
  productTypeName = false,
  categoryName = false,
  subcategoryName = false
) => {
  // in case of productType (check productType), in case of category (check productType and category) and in case of subcategory check (productType, category, subcategory)
  const obj = list.find((item) => {
    // productType case
    if (!productTypeName && !categoryName && !subcategoryName) {
      return item.productType === name;
    }
    // category case
    else if (productTypeName && !categoryName && !subcategoryName) {
      return item.name === name && item.productTypeName === productTypeName;
    }
    // subcategory case
    else if (productTypeName && categoryName && !subcategoryName) {
      return (
        item.name === name &&
        item.parentProductTypeName === productTypeName &&
        (item.parentCategoryName === SYSTEM_SUBCATEGORY ||
          item.parentCategoryName === categoryName)
      );
    } else {
      return false;
    }
  });
  return obj?.id ?? EMPTY_STRING;
};

export const getCustomTypesOptionList = (list) => {
  return (
    list?.map((item) => ({
      value: item.id,
      label: item.productType,
    })) || []
  );
};

export const getCustomCategoriesOptionList = (list, id, isAll) => {
  const filterList =
    isAll === PRODUCT_TYPES_ENUMS.ALL || !id
      ? list
      : list.filter((item) => item.productTypeId === id);
  return (
    filterList?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || []
  );
};

export const getCustomSubCategoriesOptionList = (
  subCategoriesArray,
  selectedCategoryId,
  selectedProductType = null,
  selectedProductTypeId = null,
  isExcludeCustom = false,
  categoriesArray = [],
  productTypesArray = []
) => {
  let subCategoriesList = [...subCategoriesArray];
  if (isExcludeCustom) {
    subCategoriesList = subCategoriesList.filter(
      (subCategory) => !subCategory.isCustom
    );
  }

  // find Video Game productTypeId and selected category productTypeId
  let selectedCategoryTypeId = null;
  let selectedCategory = null;
  let videoGameTypeId = null;
  let systemSubCategory = null;
  if (categoriesArray.length) {
    systemSubCategory = subCategoriesList.find(
      (subCategory) => !subCategory.isCustom
    );
    if (systemSubCategory) {
      videoGameTypeId = systemSubCategory.parentProductTypeId;
    }
    // find selected category type id
    selectedCategory = categoriesArray.find(
      (category) => selectedCategoryId === category.id
    );
    if (selectedCategory) {
      selectedCategoryTypeId = selectedCategory.productTypeId;
    }
  }

  // find productTypeName by productTypeId if it's null
  if (
    productTypesArray.length &&
    !selectedProductType &&
    selectedProductTypeId
  ) {
    selectedProductType = productTypesArray.find(
      (productType) => productType.id === selectedProductTypeId
    )?.productType;
  }

  const filterList =
    !selectedProductType ||
    (selectedProductType === PRODUCT_TYPES_ENUMS.ALL && !selectedCategoryId)
      ? subCategoriesList
      : selectedProductType === PRODUCT_TYPES_ENUMS.ALL && selectedCategoryId
      ? subCategoriesList.filter((item) => {
          if (selectedCategoryTypeId === videoGameTypeId) {
            return (
              item.parentCategoryId === SYSTEM_SUBCATEGORY ||
              item.parentCategoryId === selectedCategoryId
            );
          } else {
            return item.parentCategoryId === selectedCategoryId;
          }
        })
      : selectedProductType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
      ? subCategoriesList.filter((item) => {
          return (
            item.parentCategoryId === SYSTEM_SUBCATEGORY ||
            item.parentCategoryId === selectedCategoryId ||
            (!selectedCategoryId &&
              item.parentProductTypeId === selectedProductTypeId)
          );
        })
      : selectedProductType && !selectedCategoryId
      ? subCategoriesList.filter(
          (item) => item.parentProductTypeId === selectedProductTypeId
        )
      : subCategoriesList.filter(
          (item) => item.parentCategoryId === selectedCategoryId
        );

  return (
    filterList?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || []
  );
};

//------- Add Default TYPES and Categories for first time
export const addDefaultCategories = async (currentStore) => {
  // for (let i = 0; i < defaultTypes.length; i++) {
  //   const defaultType = defaultTypes[i];
  //   await ItemOrganizationService.addCustomProductType(currentStore.id, {
  //     productType: defaultType.productType,
  //     storeId: null,
  //     isCustom: false,
  //     isActive: true,
  //   }).then(
  //     (response) => {},
  //     (error) => {}
  //   );
  // }

  // const customTypes = await ItemOrganizationService.getCustomProductTypes(
  //   currentStore.id
  // ).then(
  //   (response) => {
  //     return response.productTypes;
  //   },
  //   (error) => {}
  // );

  // const videoGameType = customTypes.find(
  //   (type) => type.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
  // );
  // const tradingCardType = customTypes.find(
  //   (type) => type.productType === PRODUCT_TYPES_ENUMS.TRADING_CARD
  // );

  // for (let i = 0; i < defaultCategories.length; i++) {
  //   const defaultCategory = defaultCategories[i];
  //   await ItemOrganizationService.addCustomCategory(currentStore.id, {
  //     name: defaultCategory.name,
  //     storeId: null,
  //     productTypeId: videoGameType.id,
  //     productTypeName: videoGameType.productType,
  //     isCustom: false,
  //     isActive: true,
  //   }).then(
  //     (response) => {},
  //     (error) => {}
  //   );
  // }

  // for (let i = 0; i < defaultTradingCardCategories.length; i++) {
  //   const defaultCategory = defaultTradingCardCategories[i];
  //   await ItemOrganizationService.addCustomCategory(currentStore.id, {
  //     name: defaultCategory.name,
  //     storeId: null,
  //     productTypeId: tradingCardType.id,
  //     productTypeName: tradingCardType.productType,
  //     isCustom: false,
  //     isActive: true,
  //   }).then(
  //     (response) => {},
  //     (error) => {}
  //   );
  // }

  // // adding default subcategories
  // for (let i = 0; i < defaultSubCategories.length; i++) {
  //   const defaultSubCategory = defaultSubCategories[i];
  //   await ItemOrganizationService.addCustomSubCategory(currentStore.id, {
  //     name: defaultSubCategory.name,
  //     storeId: null,
  //     parentCategoryId: SYSTEM_SUBCATEGORY,
  //     parentCategoryName: SYSTEM_SUBCATEGORY,
  //     parentProductTypeId: videoGameType.id,
  //     parentProductTypeName: videoGameType.productType,
  //     isCustom: false,
  //     isActive: true,
  //   }).then(
  //     (response) => {},
  //     (error) => {}
  //   );
  // }

  // adding default tags
  const defaultTags = await inventoryService
    .addDefaultTags(currentStore.id, DEFAULT_TAGS)
    .then(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Error: saving the default tags", error);
      }
    );

  customToast("categories added", toastType.SUCCESS);
};

export const getDefaultFilterType = (list, label) => {
  const foundItem = list?.find((type) => type.productType === label);
  return foundItem
    ? {
        value: foundItem.id,
        label: foundItem.productType,
      }
    : {};
};

export const getDefaultFilterCategory = (list, label) => {
  const foundItem = list?.find((type) => type.name === label);
  return foundItem
    ? {
        value: foundItem.id,
        label: foundItem.name,
      }
    : "";
};

export const getThresholdFilterList = (thresholdData) => {
  let combineFilterList = [
    ...(thresholdData?.byCategory?.map((item) => ({
      categoryId: item.categoryId,
      categoryName: item.categoryName,
      valueType: item.valueType,
      value: item.value,
      isCategory: CUSTOM_FILTER_ENUMS.BY_CATEGORY.value,
    })) || []),
    ...(thresholdData?.byPriceRange?.map((item) => ({
      min: item.min,
      max: item.max,
      value: item.value,
      isCategory: CUSTOM_FILTER_ENUMS.BY_PRICE_RANGE.value,
      valueType: item.valueType,
    })) || []),
  ];

  return combineFilterList;
};

export const handleFilterPricechartingSearchResult = async (
  searchValue,
  inventoryService,
  searchProductType,
  productCategory,
  productSubCategory
) => {
  let isFind = false;
  let tempSubCategory = globalConstants.EMPTY_STRING;
  return await inventoryService.getPricechartingApiProducts(searchValue).then(
    (response) => {
      return response?.products?.filter((prod) => {
        isFind = false;
        if (
          prod.genre !== TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD &&
          prod.genre !== TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD &&
          searchProductType?.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME
        ) {
          isFind = true;
          if (productCategory && isFind) {
            if (
              prod[inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME] ===
              productCategory?.label
            ) {
              isFind = true;
            } else {
              isFind = false;
            }
          }

          if (productSubCategory && isFind) {
            tempSubCategory = getProductSubCategory(prod?.genre);
            if (tempSubCategory === productSubCategory?.label) {
              isFind = true;
            } else {
              isFind = false;
            }
          }
        } else if (
          prod.genre !== TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD &&
          prod.genre !== TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD
        ) {
          isFind = true;
        }
        return isFind;
      });
    },
    (error) => {
      customToast(error, toastType.ERROR);
      return [];
    }
  );
};

export const handleGetFilterLoadOptions = (
  options,
  searchProductType,
  productCategory,
  productSubCategory
) => {
  let filteredOptions = options;
  let isFind = false;

  if (searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL) {
    filteredOptions = options.filter((pType) => {
      if (productCategory && productSubCategory) {
        if (
          pType.category === productCategory.label &&
          pType.genre === productSubCategory?.label
        ) {
          isFind = true;
        } else {
          isFind = false;
        }
      } else if (productCategory) {
        if (
          (isTradingCard(pType) && pType.genre === productCategory.label) ||
          (!isTradingCard(pType) && pType.category === productCategory.label)
        ) {
          isFind = true;
        } else {
          isFind = false;
        }
      } else if (productSubCategory) {
        if (pType.genre === productSubCategory?.label) {
          isFind = true;
        } else {
          isFind = false;
        }
      } else {
        isFind = true;
      }
      return isFind;
    });
  }

  if (searchProductType?.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME) {
    filteredOptions = options.filter((pType) => isVideoGame(pType));
  }

  if (searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD) {
    filteredOptions = options.filter((pType) => {
      // no need to handle subcategory in trading card case as options list of subcategory for trading card is null
      if (productCategory) {
        return pType.genre === productCategory.label;
      } else {
        return isTradingCard(pType);
      }
    });
  }

  return filteredOptions;
};

export const handleAddBatchInventoryPark = (
  addBatchInventory,
  currentStore,
  addBatchInventoryDraftDispatch
) => {
  addBatchInventoryDraftDispatch({
    store: { id: currentStore.id, name: currentStore.storeName },
    inventory: addBatchInventory,
    quantity: addBatchInventory.reduce(
      (a, b) => a + Number(b?.inStockQuantity),
      0
    ),
    totalAmountToPay: addBatchInventory.reduce(
      (a, b) => a + Number(b?.inStockQuantity) * Number(b?.inStockPrice),
      0
    ),
  });
};

export const isButtonDisable = (spinnerArray, Key) => {
  return spinnerArray?.includes(Key);
};

export const encodeQueryParam = (value, specialCharacter) => {
  return value.replace(specialCharacter, "%26");
};

export const stringToBase64 = (string) => {
  const enc = new Base64();
  return enc.urlEncode(string);
};

export const updateCartLocalStorage = (key, data, storeId) => {
  // Retrieve and parse the current cart data from localStorage
  const storedData = parseJsonObject(getItemFromLocalStorage(key)) || [];

  // Check if the storeId already exists in the stored data
  const updatedData = storedData?.map((item) =>
    item.storeId === storeId ? { ...item, data: data } : item
  );

  // If storeId wasn't found, add a new entry
  if (!storedData?.some((item) => item.storeId === storeId)) {
    updatedData.push({ storeId: storeId, data: data });
  }

  // Save the updated cart data back to localStorage
  setItemToLocalStorage(key, stringifyObject(updatedData));
};

export const getStoreSaleCartDetailsFromStorage = (
  key,
  storeId,
  transactionDefaultData
) => {
  let transactionDataObject = transactionDefaultData;

  const storedSellTradeDataInLocalStorage = parseJsonObject(
    getItemFromLocalStorage(key)
  );

  if (storedSellTradeDataInLocalStorage) {
    const findCurrentStoreData = storedSellTradeDataInLocalStorage.find(
      (storedData) => storedData.storeId === storeId
    );
    transactionDataObject =
      findCurrentStoreData?.data || transactionDefaultData;
  }
  return transactionDataObject;
};

export const isSpinnerEnabled = (spinnerArray, key) => {
  // Check if key is an array
  if (Array.isArray(key)) {
    return key.some((element) => spinnerArray.includes(element));
  }

  // If key is not an array, fallback to original behavior
  return spinnerArray.includes(key);
};

export const addTimeDelay = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));
export * from "./inventoryUtility";

// handleCategorySelect for tradeins margin, preset prices and discount
export const handleCategorySelect = (
  selectedCategory,
  setByFilter,
  searchCategories,
  setSearchCategories,
  selectedCategories,
  setSelectedCategories,
  filterProductCategory
) => {
  if (!selectedCategories.find((c) => c.name === selectedCategory?.name)) {
    if (setByFilter === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value) {
      setSelectedCategories([
        ...selectedCategories,
        {
          ...selectedCategory,
          parentCategoryId: filterProductCategory.value,
          parentCategoryName: filterProductCategory.label,
        },
      ]);
    } else {
      setSelectedCategories([...selectedCategories, selectedCategory]);
    }
    setSearchCategories(
      searchCategories?.filter(
        (category) => category?.name !== selectedCategory?.name
      )
    );
  }
};

export const prepareFilterForPaginationCall = (
  productType,
  category,
  subcategory
) => {
  let filters = "";
  if (productType && productType?.label !== PRODUCT_TYPES_ENUMS.ALL) {
    filters = filters + `AND c.productType = "${productType.label}" `;
  }

  if (category?.label) {
    filters =
      filters +
      `AND c.category_name = "${
        category?.label || globalConstants.EMPTY_STRING
      }" `;
  }

  if (subcategory?.label) {
    filters =
      filters +
      `AND c.subcategory = "${
        subcategory?.label || globalConstants.EMPTY_STRING
      }" `;
  }

  return filters;
};

export const prepareFilterForInventoryHistoryPaginationCall = (
  productType,
  category,
  transactionType,
  startDate,
  endDate,
  subcategory
) => {
  let filters = "";
  if (productType && productType?.label !== PRODUCT_TYPES_ENUMS.ALL) {
    filters = filters + `AND c.product.productType = '${productType.label}' `;
  }

  if (category?.label) {
    filters =
      filters +
      `AND c.product.category = '${
        category?.label || globalConstants.EMPTY_STRING
      }' `;
  }

  if (subcategory?.label) {
    filters =
      filters +
      `AND c.product.subcategory = "${
        subcategory?.label || globalConstants.EMPTY_STRING
      }" `;
  }

  if (
    transactionType?.label &&
    transactionType?.label !== PRODUCT_TYPES_ENUMS.ALL
  ) {
    filters = filters + `AND c.TransactionType = '${transactionType?.label}' `;
  }

  if (startDate) {
    filters = filters + `AND c.createdOn > '${startDate}' `;
  }

  if (endDate) {
    filters = filters + `AND c.createdOn < '${endDate}' `;
  }
  return filters;
};

export const isAnyStockPriceUpdate = (data) => {
  return data?.some((item) => item.isProductExisted && item.isPriceChanged);
};

export const isAnyEditedProduct = (data) => {
  return data?.some(
    (item) =>
      item.labelType === NOTIFICATION_TYPES.PRODUCT_LABEL_EDIT ||
      item.labelType === NOTIFICATION_TYPES.PRODUCT_LABEL_EDIT_CONSOLIDATED
  );
};

export const isShowCheckbox = (row) => {
  return row.price.isProductExisted && row.price.isPriceChanged;
};

export const getFilteredColumns = (data, columnHeaders) => {
  let columns = columnHeaders;
  // columns = isAnyEditedProduct(data)
  //   ? columns
  //   : columns.filter((column) => column.id !== "stockQuantity");

  columns = isAnyStockPriceUpdate(data)
    ? columns
    : columns.filter((column) => column.id !== "update-all-labels");

  return columns;
};

const updateCustomerData = (
  dispatch,
  storeId,
  customers,
  cartDataObject,
  setData
) => {
  if (!cartDataObject) return;

  const currentStoreCartData = cartDataObject.find(
    (storedData) => storedData.storeId === storeId
  );

  if (currentStoreCartData && currentStoreCartData?.data?.customer?.id) {
    const existingCustomer = customers.find(
      (customer) => customer.id === currentStoreCartData?.data?.customer.id
    );

    dispatch(
      setData(
        {
          ...currentStoreCartData.data,
          customer: existingCustomer
            ? {
                ...currentStoreCartData.data.customer,
                firstName: existingCustomer.firstName,
                lastName: existingCustomer.lastName,
                mobile: existingCustomer.mobile,
                email: existingCustomer.email,
                currentBalance: existingCustomer.currentBalance,
              }
            : "",
        },
        storeId
      )
    );
  }
};

export const updateCustomerCreditOnCarts = (
  dispatch,
  storeId,
  customers,
  setNewTradeData,
  setTransactionData
) => {
  const tradeCartData = parseJsonObject(
    getItemFromLocalStorage(transactionConstants.NEW_TRADE_PAGE_DATA)
  );
  const transactionCartData = parseJsonObject(
    getItemFromLocalStorage(transactionConstants.NEW_SALE_PAGE_DATA)
  );

  updateCustomerData(
    dispatch,
    storeId,
    customers,
    tradeCartData,
    setNewTradeData
  );
  updateCustomerData(
    dispatch,
    storeId,
    customers,
    transactionCartData,
    setTransactionData
  );
};

export const getSystemMarketPrice = (priceChartingProductInfo) => {
  if (
    priceChartingProductInfo.productMetaData.genre ===
    TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
  ) {
    return priceChartingProductInfo.productObject[
      inventoryConstants.LOOSE_PRICE
    ];
  } else {
    const isSkyLander = inventoryConstants.SKYLANDER_CATEGORIES.includes(
      priceChartingProductInfo.productObject[
        inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
      ]
    );

    if (isSkyLander) {
      return priceChartingProductInfo.productObject[
        inventoryConstants.NEW_PRICE
      ];
    } else {
      return priceChartingProductInfo.productObject[
        inventoryConstants.COMPLETE_IN_BOX_PRICE
      ];
    }
  }
};

export const getSystemSuggestedMarketPrice = (priceChartingProductInfo) => {
  if (
    priceChartingProductInfo.productMetaData.genre ===
    TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
  ) {
    return priceChartingProductInfo.productObject[
      inventoryConstants.RETAIL_LOOSE_PRICE
    ];
  } else {
    const isSkyLander = inventoryConstants.SKYLANDER_CATEGORIES.includes(
      priceChartingProductInfo.productObject[
        inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
      ]
    );
    if (isSkyLander) {
      return priceChartingProductInfo.productObject[
        inventoryConstants.RETAIL_NEW_PRICE
      ];
    } else {
      return priceChartingProductInfo.productObject[
        inventoryConstants.RETAIL_COMPLETE_IN_BOX_PRICE
      ];
    }
  }
};
//-------onSelectedTagChange
export const onSelectTagsChange = (
  selectedTags,
  setSelectedTags,
  productDataObject,
  setProductDataObject,
  priceChartingProductInfo,
  productCondition,
  productType
) => {
  const selectedTagList = selectedTags.map((tag) => tag.label);
  changePriceBaseOnTags(
    selectedTagList,
    productDataObject,
    setProductDataObject,
    priceChartingProductInfo,
    inventoryConstants,
    PRODUCT_CONDITIONS_ENUMS,
    TRADING_CARD_CATEGORIES_ENUMS,
    productCondition,
    productType
  );
  setSelectedTags(selectedTagList);
};

export const handleProductConditionUpdate = async (
  currentStore,
  priceChartingProductInfo,
  getInventoryByPricechartingId,
  setProductType,
  setProductUrl,
  productDataObject,
  setProductDataObject,
  productTags,
  setProductTagsList,
  setRarities,
  setSelectedRarity,
  setConditionList,
  setProductCondition,
  productImage = false
) => {
  if (priceChartingProductInfo?.productObject) {
    if (
      priceChartingProductInfo.productMetaData.sourceApi ===
      inventoryConstants.PRICECHARTING_API
    ) {
      if (
        priceChartingProductInfo.productMetaData.genre ===
        TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
      ) {
        setSelectedRarity("");
        setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
        setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
        setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
        setProductDataObject({
          ...productDataObject,
          productName:
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_NAME
            ],
          consoleName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
          marketPrice:
            priceChartingProductInfo.productObject[
              inventoryConstants.LOOSE_PRICE
            ] || 0,
          suggestedSellPrice:
            priceChartingProductInfo.productObject[
              inventoryConstants.RETAIL_LOOSE_PRICE
            ] || 0,
          gameStopBuyPrice:
            priceChartingProductInfo.productObject[
              inventoryConstants.GAMESTOP_PRICE
            ],
          upc:
            priceChartingProductInfo.productObject.upc ||
            globalConstants.EMPTY_STRING,
          genre: priceChartingProductInfo.productMetaData.genre,
          productId: priceChartingProductInfo.productObject.id,
          costOfGoods: 0,
          inStockPrice: 0,
          inStorePrice: 0,
          inStockQuantity: 0,
          skuNumber: [],
          averageBuyPrice: 0,
          averageSellPrice: 0,
          maxBuyPrice: 0,
          cardNumber: 0,
          tcgPlayerUrl: globalConstants.EMPTY_STRING,
          imgUrl: productImage || globalConstants.EMPTY_STRING,
          epid:
            priceChartingProductInfo.productObject?.epid ||
            globalConstants.EMPTY_STRING,
        });
        getInventoryByPricechartingId(currentStore?.id, {
          id: priceChartingProductInfo.productObject.id,
          categoryName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
          productName:
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_NAME
            ],
        });
      } else {
        if (
          priceChartingProductInfo.productMetaData.genre ===
          TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
        ) {
          setSelectedRarity("");
          setProductType(PRODUCT_TYPES_ENUMS.VIDEO_GAME);
          handleConditionsAndTags(
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
            ],
            priceChartingProductInfo.productObject["genre"],
            productTags,
            setConditionList,
            setProductCondition,
            setProductTagsList,
            inventoryConstants.VIDEO_GAME_SYSTEM_CONDITION
          );
        } else {
          setSelectedRarity("");
          setProductType(PRODUCT_TYPES_ENUMS.VIDEO_GAME);

          handleConditionsAndTags(
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
            ],
            priceChartingProductInfo.productObject["genre"],
            productTags,
            setConditionList,
            setProductCondition,
            setProductTagsList,
            inventoryConstants.VIDEO_GAME_CONDITION
          );
        }

        setProductDataObject({
          ...productDataObject,
          productName:
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_NAME
            ],
          consoleName:
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
            ],
          marketPrice: getSystemMarketPrice(priceChartingProductInfo),
          suggestedSellPrice: getSystemSuggestedMarketPrice(
            priceChartingProductInfo
          ),
          gameStopBuyPrice:
            priceChartingProductInfo.productObject[
              inventoryConstants.GAMESTOP_PRICE
            ],
          upc:
            priceChartingProductInfo.productObject.upc ||
            globalConstants.EMPTY_STRING,
          genre: priceChartingProductInfo.productMetaData.genre,
          productId: priceChartingProductInfo.productObject.id,
          costOfGoods: 0,
          inStockPrice: 0,
          inStorePrice: 0,
          inStockQuantity: 0,
          skuNumber: [],
          averageBuyPrice: 0,
          averageSellPrice: 0,
          maxBuyPrice: 0,
          cardNumber: 0,
          tcgPlayerUrl: globalConstants.EMPTY_STRING,
          imgUrl: productImage || globalConstants.EMPTY_STRING,
          epid:
            priceChartingProductInfo.productObject?.epid ||
            globalConstants.EMPTY_STRING,
        });
        getInventoryByPricechartingId(currentStore?.id, {
          id: priceChartingProductInfo.productObject.id,
          categoryName:
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
            ],
          productName:
            priceChartingProductInfo.productObject[
              inventoryConstants.PRICECHARTING_PRODUCT_NAME
            ],
        });
      }
    } else if (
      priceChartingProductInfo.productMetaData.sourceApi ===
        inventoryConstants.POKEMON_API ||
      priceChartingProductInfo.productMetaData.sourceApi ===
        inventoryConstants.SCRYFALL_API
    ) {
      setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
      setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
      setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
      setProductTagsList([]);
      if (
        priceChartingProductInfo.productMetaData.sourceApi ===
        inventoryConstants.POKEMON_API
      ) {
        const raritiesList = priceChartingProductInfo?.productObject?.tcgplayer
          ?.prices
          ? Object.keys(
              priceChartingProductInfo?.productObject?.tcgplayer?.prices
            ).map((key, index) => {
              const value = `${JSON.stringify(
                priceChartingProductInfo.productObject?.tcgplayer?.prices[key]
                  .market
              )}`;
              return { label: key, value: { key: index, value: value } };
            })
          : [];

        setRarities(raritiesList);
        setSelectedRarity(raritiesList[0]);

        const pokemonProductName = `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set.name} - ${priceChartingProductInfo.productObject.number}/${priceChartingProductInfo.productObject.set.printedTotal})`;
        getInventoryByPricechartingId(currentStore?.id, {
          id: priceChartingProductInfo.productObject.id,
          categoryName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
          productName: pokemonProductName,
        });
        setProductDataObject({
          ...productDataObject,
          productName: pokemonProductName,
          consoleName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
          upc:
            priceChartingProductInfo.productObject.upc ||
            globalConstants.EMPTY_STRING,
          genre: priceChartingProductInfo.productMetaData.genre,
          productId: priceChartingProductInfo.productObject.id,
          marketPrice: raritiesList[0]?.value.value || 0,
          suggestedSellPrice: 0,
          cardNumber: priceChartingProductInfo.productObject.number,
          imgUrl:
            productImage ||
            priceChartingProductInfo?.productObject?.images.small ||
            globalConstants.EMPTY_STRING,
          tcgPlayerUrl: priceChartingProductInfo?.productObject?.tcgplayer?.url,
          costOfGoods: 0,
          inStockPrice: 0,
          inStorePrice: 0,
          inStockQuantity: 0,
          skuNumber: [],
          gameStopBuyPrice: 0,
          averageBuyPrice: 0,
          averageSellPrice: 0,
          maxBuyPrice: 0,
          epid: globalConstants.EMPTY_STRING,
        });
      } else if (
        priceChartingProductInfo.productMetaData.sourceApi ===
        inventoryConstants.SCRYFALL_API
      ) {
        const priceList = convertPricesToNumbers(
          priceChartingProductInfo.productObject.prices
        );
        const raritiesList = Object.keys(priceList).map((key, index) => {
          const value = `${JSON.stringify(priceList[key])}`;
          return {
            label: key,
            value: { key: index, value: value === "null" ? 0 : value },
          };
        });

        setRarities(raritiesList);
        setSelectedRarity(raritiesList[0]);
        getInventoryByPricechartingId(currentStore?.id, {
          id: priceChartingProductInfo.productObject.id,
          categoryName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
          productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
        });
        setProductDataObject({
          ...productDataObject,
          productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
          consoleName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
          upc:
            priceChartingProductInfo.productObject.upc ||
            globalConstants.EMPTY_STRING,
          genre: priceChartingProductInfo.productMetaData.genre,
          productId: priceChartingProductInfo.productObject.id,
          marketPrice: raritiesList[0]?.value.value || 0,
          suggestedSellPrice: 0,
          cardNumber: priceChartingProductInfo.productObject.collector_number,
          tcgPlayerUrl:
            priceChartingProductInfo.productObject.purchase_uris?.tcgplayer ||
            globalConstants.EMPTY_STRING,
          imgUrl: "",
          costOfGoods: 0,
          inStockPrice: 0,
          inStorePrice: 0,
          inStockQuantity: 0,
          skuNumber: [],
          gameStopBuyPrice: 0,
          averageBuyPrice: 0,
          averageSellPrice: 0,
          maxBuyPrice: 0,
          imgUrl:
            productImage ||
            priceChartingProductInfo.productObject.image_uris?.small ||
            globalConstants.EMPTY_STRING,
          epid: globalConstants.EMPTY_STRING,
        });
      }
    }
  }
  setProductUrl("");
};

export const getReceiptPrintDate = (dateString) => {
  // return new Date(date).toLocaleString("en-US", {
  //   year: "2-digit",
  //   month: "2-digit",
  //   day: "2-digit",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   second: "2-digit",
  //   hour12: true,
  // });

  const date = formatDate(dateString);
  const time = formatTime(dateString);

  return `${date} - ${time}`;
};

export const createMappedArray = (
  csvData,
  predefinedColumns,
  columnMapping
) => {
  return csvData.map((row) => {
    const mappedRow = {};
    predefinedColumns.forEach((predefinedColumn) => {
      const csvColumn = columnMapping[predefinedColumn];
      if (csvColumn && row.hasOwnProperty(csvColumn)) {
        mappedRow[predefinedColumn] = row[csvColumn];
      }
    });
    return mappedRow;
  });
};

export const handleColumnMapping = (
  predefinedColumn,
  selectedCsvColumn,
  setColumnMapping
) => {
  setColumnMapping((prevMapping) => ({
    ...prevMapping,
    [predefinedColumn]: selectedCsvColumn,
  }));
};

export const handleFileLoaded = (
  data,
  fileInfo,
  predefinedColumns,
  setCsvData,
  setColumnMapping
) => {
  // handle empty file
  if (!data.length) {
    customToast(toastMessages.NO_RECORD_FOUND, toastType.ERROR);
    return;
  }
  if (fileInfo.type === "text/csv") {
    const defaultMapping = {};
    const csvColumns = Object.keys(data[0]);

    csvColumns.forEach((csvColumn) => {
      if (predefinedColumns.includes(csvColumn)) {
        defaultMapping[csvColumn] = csvColumn;
      }
    });
    setCsvData(data);
    setColumnMapping(defaultMapping);
  } else {
    customToast(toastMessages.ONLY_CSV_ALLOWED, toastType.ERROR);
  }
};

export const isProductMetaDataSame = (
  product1,
  product2,
  isSerialNumber = true
) => {
  const isSame =
    String(product1.product_name).toLowerCase() ===
      String(product2.product_name).toLowerCase() &&
    String(product1.category_name).toLowerCase() ===
      String(product2.category_name).toLowerCase() &&
    String(product1.subcategory).toLowerCase() ===
      String(product2.subcategory).toLowerCase() &&
    product1.price.type === product2.price.type &&
    isTagsEqual(product1.tags, product2.tags) &&
    product1.cardRarity === product2.cardRarity &&
    product1.productType === product2.productType;
  if (isSerialNumber) {
    return isSame && product1.serialNumber === product2.serialNumber;
  } else {
    return isSame;
  }
};

export const beautifyStringForImport = (stringValue) => {
  return '"' + stringValue + '"';
};

export const isPriceChanged = (arr1, arr2) => {
  return arr1.some((item1) => {
    const item2 = arr2.find((item) => item.id === item1.id);
    return item2 && item1.price.unit_sell_price !== item2.price.unit_sell_price;
  });
};

export const getProductLabelNotifications = (notificationsArr) => {
  return notificationsArr?.length
    ? notificationsArr?.filter(
        (notfication) =>
          notfication.type === NOTIFICATION_TYPES.PRODUCT_LABEL ||
          notfication.type === NOTIFICATION_TYPES.PRODUCT_LABEL_EDIT ||
          notfication.type ===
            NOTIFICATION_TYPES.PRODUCT_LABEL_EDIT_CONSOLIDATED
      )
    : [];
};

export const handleIsLabelNotificationOnEditInventory = (
  editInventoryResponse,
  isLabelNotification
) => {
  return (
    editInventoryResponse.data?.data?.isLabelNotification && isLabelNotification
  );
};

export const isTradeTransaction = (transaction) => {
  return transaction?.id.startsWith(globalConstants.TYPE_ID_TRD);
};

export const isSaleTransaction = (transaction) => {
  return transaction?.id.startsWith(globalConstants.TYPE_ID_TRA);
};

export const isReturnTransaction = (transaction) => {
  return transaction?.id.startsWith(globalConstants.TYPE_RETURN);
};

export const calculateTransactionTaxPercentage = (transaction) => {
  return +Number(
    (parseToNumber(transaction.Tax) / parseToNumber(transaction.SubTotal)) * 100
  ).toFixed(2);
};

//-------- handle uplaod image
export const handleUploadProductImage = (
  e,
  activateSpinner,
  deactivateSpinner,
  productDataObject,
  setProductDataObject
) => {
  const file = e.target.files[0];
  if (file) {
    if (
      file.type === IMAGE_FILE_TYPES.PNG ||
      file.type === IMAGE_FILE_TYPES.JPEG ||
      file.type === IMAGE_FILE_TYPES.JPG
    ) {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = async () => {
            activateSpinner(inventoryConstants.UPLOAD_IMAGE);
            const productImage = await uploadCustomProductImage(file);
            setProductDataObject({
              ...productDataObject,
              imgUrl: productImage,
            });
            deactivateSpinner(inventoryConstants.UPLOAD_IMAGE);
          };
        };
        reader.readAsDataURL(file);
      }
    } else {
      customToast(
        "Only .jpg, .jpeg and .png files are allowed",
        toastType.ERROR
      );
      e.target.value = null;
    }
  }
};
export const isDateInTimeRange = (timeFrame) => {
  const { start, end, duration } = timeFrame;

  const startDate = new Date(start);
  const endDate = new Date(end);
  const currentDate = new Date();

  const currentMonth = currentDate.getMonth();
  const endMonth = endDate.getMonth();

  if (duration === datepickerConstants.DAILY) {
    return currentDate <= endDate && currentDate >= startDate;
  } else if (duration === datepickerConstants.MONTHLY) {
    return currentMonth === endMonth;
  } else {
    return false;
  }
};

export const calculateBarWidth = (max, current) => {
  if (max === 0) {
    return 0;
  }

  const percentage = (current / max) * 100;

  return Math.max(0, Math.min(percentage, 100));
};

export const NoDataWrapper = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column no-graph-data-wrapper category-data-found">
      <p>No data to show.</p>
      <p>Try selecting a different date range</p>
    </div>
  );
};

//------- Round off values to show 1000 into 1k
export const formatValueToThousand = (value, currency = false) => {
  if (value > 999.99) {
    return `${currency || ""}${Math.round(value / 100) / 10}K`; // Rounds to one decimal place and adds 'K'
  } else {
    return `${currency || ""}${value}`; // For values less than 1000, show with two decimal places
  }
};

export const calculateRatio = (item) => {
  return (
    item?.tradeInMarginTypeObject?.marginObject?.tradeinMarginPercentage /
    item?.tradeInMarginTypeObject?.marginObject?.cashMarginPercentage
  );
};

export const roundNumber = (number) => {
  return Number(Math.round(Number(number) * 1e2) / 1e2);
};

export const handleCalculateTotalCogsAndStockPrice = (
  filteredInventory,
  isTrade = false
) => {
  let initialTotalCogs = 0;
  let initialTotalStockPrice = 0;
  filteredInventory.forEach((item) => {
    // calculate total cogs and total stock price
    initialTotalCogs = isTrade
      ? roundNumber(
          Number(initialTotalCogs) +
            Number(item?.price?.unit_purchase_price) *
              Number(item?.price?.quantity)
        )
      : roundNumber(
          Number(initialTotalCogs) + Number(item.cogs) * Number(item.quantity)
        );
    initialTotalStockPrice = isTrade
      ? roundNumber(
          Number(initialTotalStockPrice) +
            Number(item?.price?.unit_sell_price) * Number(item?.price?.quantity)
        )
      : roundNumber(
          Number(initialTotalStockPrice) +
            Number(item.actualPrice) * Number(item.quantity)
        );
  });
  return { initialTotalCogs, initialTotalStockPrice };
};

//------- Validate Every System has Serial Number if Required
export const isAllSystemHasSerialNumber = (currentStore, addBatchInventory) => {
  if (
    currentStore?.[
      toggleSwitchButtonConstants.IS_SERIAL_NUMBER_REQUIRED_INVENTORY
    ]
  ) {
    return addBatchInventory.some((inv) => {
      return (
        getProductSubCategory(inv.genre) ===
          TRADING_CARD_CATEGORIES_ENUMS.SYSTEM && !inv.serialNumber
      );
    });
  }
};

//------ check product subcategory is system
export const isSystemProduct = (subCategory) => {
  return subCategory === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM;
};

//------ check product serial number is unique
export const isSerialNumberUnique = (serialNumber, products) => {
  //------ Check if the serial number already exists in the products array
  return !products.some(
    (product) =>
      product?.serialNumber &&
      serialNumber &&
      product.serialNumber === serialNumber
  );
};

//------ get sum of all instock products
export const getTotalNumberOfInStockProducts = (findProduct) => {
  return findProduct?.reduce((sum, product) => {
    return sum + (product?.price?.quantity || 0); // Add quantity if it exists, otherwise add 0
  }, 0);
};

//------- get products if found in stock
export const isProductsExistInStock = (
  condition,
  selectedTags,
  selectedRarity,
  inventoryProducts,
  priceChartingProductInfo
) => {
  return inventoryProducts.filter((product) => {
    if (
      product.price.type === condition &&
      isTagsEqual(product.tags || [], selectedTags || [])
    ) {
      if (
        priceChartingProductInfo?.productMetaData?.sourceApi ===
          inventoryConstants.POKEMON_API ||
        priceChartingProductInfo?.productMetaData?.sourceApi ===
          inventoryConstants.SCRYFALL_API
      ) {
        if (
          product.cardRarity === selectedRarity?.label ||
          (!product.cardRarity && !selectedRarity?.label)
        ) {
          return product;
        }
      } else {
        return product;
      }
    }
  });
};

//------get product detail if found in in stock
export const findInventoryProductDetail = async (
  condition,
  productType,
  markupPresets,
  tradeinMargin,
  selectedTags,
  selectedRarity,
  productDataObject,
  inventoryProducts,
  setProductDataObject,
  priceChartingProductInfo
) => {
  const marketPrice =
    productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
    productDataObject.consoleName === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
      ? convertToUsd(productDataObject.marketPrice)
      : productDataObject.marketPrice;

  var tradeInMarginToApply = getMarginDropdownValue(
    marketPrice,
    tradeinMargin,
    productType,
    {
      ...productDataObject,
      tags: selectedTags,
      condition: condition,
      cardRarity: selectedRarity?.label || EMPTY_STRING,
      subcategory: getProductSubCategory(productDataObject.genre),
    }
  );
  const { cashOffer } = calculateOfferPrices(
    marketPrice,
    tradeInMarginToApply.marginObject,
    tradeInMarginToApply.marginObject.cashMarginPercentage,
    tradeInMarginToApply.marginObject.tradeinMarginPercentage
  );

  if (inventoryProducts?.length > 0) {
    const findProduct = isProductsExistInStock(
      condition,
      selectedTags,
      selectedRarity,
      inventoryProducts,
      priceChartingProductInfo
    );
    setProductDataObject((prevProductDataObject) => ({
      ...prevProductDataObject,
      inStockQuantity: getTotalNumberOfInStockProducts(findProduct) || 0,
      skuNumber: findProduct?.length > 1 ? [] : findProduct?.[0]?.sku || [],
      inStorePrice: findProduct?.[0]?.price?.unit_sell_price || 0,
      inStockPrice:
        findProduct?.[0]?.price?.unit_sell_price ||
        getPresetStockPrice(
          productType,
          marketPrice,
          markupPresets,
          prevProductDataObject
        ),

      costOfGoods: parseToDecimalNumber(cashOffer),
      averageBuyPrice: findProduct?.[0]?.price?.averageBuyPrice || 0,
      averageSellPrice: findProduct?.[0]?.price?.averageSellPrice || 0,
      maxBuyPrice: findProduct?.[0]?.price?.maxBuyPrice || 0,
    }));

    return;
  }
  setProductDataObject((prevState) => ({
    ...prevState,
    inStorePrice: 0,
    inStockPrice: getPresetStockPrice(
      productType,
      marketPrice,
      markupPresets,
      prevState
    ),
    costOfGoods: parseToDecimalNumber(cashOffer),
  }));
};

export const parseLiscenceScannedData = (data, IsExpired) => {
  // Define the substrings to look for
  const substrings = customerConstants.DRIVING_LICENSE_SUBSTRING;
  const validateSubstrings = customerConstants.VALIDATE_SUBSTRING;

  // Check if the required substrings are present in the data
  const requiredSubstringsFound = validateSubstrings.every((substring) =>
    data.includes(substring)
  );
  if (!requiredSubstringsFound) {
    customToast(toastMessages.ID_NOT_RECOGNIZED, toastType.ERROR);
    return {
      isDataParsed: false,
      data: toastMessages.ID_NOT_RECOGNIZED,
    };
  }

  // // Extract the expiration date from the data using a regular expression
  // const expirationDateRegex = /Expiration\sDate[:\s]*(\d{4}-\d{2}-\d{2})/;
  // const match = data.match(expirationDateRegex);

  // if (match && match[1]) {
  //   const expirationDate = match[1]; // The expiration date is in the format YYYY-MM-DD
  //   const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format

  //   if (expirationDate < currentDate) {
  //     customToast(toastMessages.ID_IS_EXPIRED, toastType.ERROR); // Show "ID is expired" message
  //     return {
  //       isDataParsed: false,
  //       data: toastMessages.ID_IS_EXPIRED,
  //     };
  //   }
  // }

  // Iterate over each substring and add a newline before it in the input data
  substrings.forEach((substring) => {
    // Replace the substring with itself prefixed by a newline
    data = data.replace(new RegExp(`(${substring})`, "g"), `\n$1`);
  });

  if (IsExpired(data)) {
    customToast(toastMessages.ID_IS_EXPIRED, toastType.ERROR); // Show "ID is expired" message
    return {
      isDataParsed: false,
      data: toastMessages.ID_IS_EXPIRED,
    };
  }

  return {
    isDataParsed: true,
    data: data,
  };
};

//------- handle customer license scan error
export const handleCustomerLicenseScanError = (
  user,
  errors,
  logErrors,
  currentStore
) => {
  logErrors({
    type: LOGS_TYPES.CUSTOMER_LICENSE_SCAN_LOGS,
    errors: `${errors}`,
    metaData: `Store Name: ${currentStore?.storeName}, User Email: ${user.email}, Error Screen URL: ${window.location.href}`,
  });
};

export const capitalizeEveryWord = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const isProductSystemWithSerialNumber = (product) => {
  return (
    product?.serialNumber?.length > 0 &&
    product.subcategory === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
  );
};

export const getAllDefaultTags = (productTagsList) => {
  return [
    ...(productTagsList?.systemsDefaultTags?.complete || []),
    ...(productTagsList?.systemsDefaultTags?.loose || []),
    ...(productTagsList?.systemsDefaultTags?.boxOnly || []),
    ...(productTagsList?.gamesDefaultTags?.complete || []),
    ...(productTagsList?.gamesDefaultTags?.loose || []),
    ...(productTagsList?.gamesDefaultTags?.boxOnly || []),
    ...(productTagsList?.gamesDefaultTags?.manualOnly || []),
  ].reduce((acc, value) => {
    if (!acc.includes(value)) {
      acc.push(value);
    }
    return acc;
  }, []);
};

export const isCustomTagsIncluded = (productTagsList, selectedTags) => {
  const defaultTags = getAllDefaultTags(productTagsList);

  const hasCustomTag = selectedTags.some((tag) => !defaultTags.includes(tag));

  return hasCustomTag;
};

//------- Check Market Price Hign than instock price or not
export const isMarketPriceHigh = (
  marketPrice,
  stockPrice,
  productTags,
  selectedTags,
  isDataFetching = false
) => {
  return (
    !isCustomTagsIncluded(productTags, selectedTags) &&
    Number(marketPrice) > Number(stockPrice) &&
    !isDataFetching
  );
};

//------- get Total of Cost of goods in batch
export const getTotalCogsOfBatchProducts = (addBatchInventory) => {
  return addBatchInventory.reduce((acc, currentInv) => {
    return convertToFixedPrecision(
      Number(currentInv.costOfGoods * currentInv.inStockQuantity) + acc
    );
  }, 0);
};

//------- get Total of Trade in Trade batch
export const getTotalTradeOfferOfBatchProducts = (addTradeBatchInventory) => {
  const totalCashOffer = addTradeBatchInventory.reduce((acc, currentInv) => {
    return Number(currentInv.cashOffer * currentInv.inStockQuantity) + acc;
  }, 0);

  // Calculate the total trade offer
  const totalTradeOffer = addTradeBatchInventory.reduce((acc, currentInv) => {
    return Number(currentInv.tradeOffer * currentInv.inStockQuantity) + acc;
  }, 0);

  return {
    cashOfferValue: parseToDecimalNumber(totalCashOffer),
    tradeOfferValue: parseToDecimalNumber(totalTradeOffer),
  };
};

export const getRowId = (params) => params.data.id;

export const getRowStyle = (params) => {
  if (params.node && params.node.editing) {
    return { backgroundColor: "#000000" };
  }
  return null;
};

export const getPaginationFilterParamsFromGridState = (gridState) => {
  return `search=${gridState.searchQuery}&pageSize=${
    gridState.pageSize
  }&pageNumber=${gridState.pageIndex}&filters=${stringToBase64(
    gridState.filters
  )}&sortFilter=${stringToBase64(gridState.sortFilter)}`;
};

export const getCustomerFullName = (customer) => {
  return (
    (customer?.firstName ?? "Customer") + " " + (customer?.lastName ?? "Name")
  );
};
