import React, { createContext, useState, useContext, useEffect } from "react";
import { CONSOLE_LOGS_TYPES } from "../../constants";

// Create a Context for the logs
const LogContext = createContext();

// LogProvider component that provides the log context to the app
export const LogProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);

  // Capture console logs
  useEffect(() => {
    const originalLog = console.log;
    const originalError = console.error;
    const originalWarn = console.warn;

    // Override console.log
    console.log = (...args) => {
      setLogs((prevLogs) => [
        ...prevLogs,
        { type: CONSOLE_LOGS_TYPES.LOG, message: args },
      ]);
      originalLog.apply(console, args);
    };

    // Override console.error
    console.error = (...args) => {
      setLogs((prevLogs) => [
        ...prevLogs,
        { type: CONSOLE_LOGS_TYPES.ERROR, message: args },
      ]);
      originalError.apply(console, args);
    };

    // Override console.warn
    console.warn = (...args) => {
      setLogs((prevLogs) => [
        ...prevLogs,
        { type: CONSOLE_LOGS_TYPES.WARNING, message: args },
      ]);
      originalWarn.apply(console, args);
    };

    // Cleanup on unmount
    return () => {
      console.log = originalLog;
      console.error = originalError;
      console.warn = originalWarn;
    };
  }, []);

  return (
    <LogContext.Provider value={{ logs, setLogs }}>
      {children}
    </LogContext.Provider>
  );
};

// Custom hook to use the LogContext
export const useLogs = () => useContext(LogContext);
