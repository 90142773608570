import React, { useEffect } from "react";
import { connect } from "react-redux";

import CustomBodyModal from "../../../shared/components/modal/CustomBodyModal";
import CardPayment from "../NewTransaction/NewTransationPayment/CardPayment";
import { EMPTY_STRING, transactionConstants } from "../../../constants";
import {
  deviceActions,
  systemActions,
  transactionActions,
} from "../../../redux/actions";
import { success } from "../../../redux/actions/actionsUtilities";

const CreditCardPaymentModal = (props) => {
  const {
    amountToPay,
    storeDevices,
    currentStore,
    getStoreDevices,
    searchTransaction,
    returnTransaction,
    setReturnTransaction,
    cancelCardTransaction,
    handleReturnModalSubmit,
    isCreditCardPaymentModal,
    setIsCreditCardPaymentModal,
  } = props;

  useEffect(() => {
    getStoreDevices(currentStore?.id); // eslint-disable-next-line
  }, []);

  return (
    <CustomBodyModal
      size={"md"}
      show={isCreditCardPaymentModal}
      onHide={setIsCreditCardPaymentModal}
      className={"nested-modal-z-index"}
      backdropClassName={"nested-modal-z-index"}
    >
      <CardPayment
        currentStore={currentStore}
        storeDevices={storeDevices}
        transactionData={{ totalAmountToPay: amountToPay }}
        setPaymentMethod={() => {}}
        paymentDevice={returnTransaction.poiId}
        amountToPay={amountToPay}
        handleThirdPartyPayment={(value) => {
          const updatedReturnTransaction = {
            poiId: value,
            transactionId: searchTransaction.id,
            activeCardPaymentType: currentStore.activeCardPaymentType,
          };
          setReturnTransaction(updatedReturnTransaction);
          handleReturnModalSubmit(updatedReturnTransaction);
        }}
        handleCreditCardPayment={(value) => {
          const updatedReturnTransaction = {
            poiId: value,
            transactionId: searchTransaction.id,
            activeCardPaymentType: currentStore.activeCardPaymentType,
          };
          setReturnTransaction(updatedReturnTransaction);
          handleReturnModalSubmit(updatedReturnTransaction);
        }}
        handleTransactionCancel={() => {
          cancelCardTransaction(
            {
              poiId: returnTransaction.poiId,
              transactionId: returnTransaction.transactionId,
            },
            null
          );
        }}
        handleCancelButtonClick={() => {
          setIsCreditCardPaymentModal(false);
        }}
      />
    </CustomBodyModal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  storeDevices: state.device.storeDevices,
  searchTransaction: state.transaction.searchTransaction[0],
  returnTransaction: state.transaction.returnTransaction,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getStoreDevices: (storeId) =>
    dispatch(deviceActions.getStoreDevices(storeId)),
  cancelCardTransaction: (data, transactionObject) =>
    dispatch(transactionActions.cancelCardTransaction(data, transactionObject)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  setReturnTransaction: (data) =>
    dispatch(success(data, transactionConstants.SET_RETURN_TRANSACTION)),
});

//-------Export new return Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardPaymentModal);
