import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const NestedSidebarBody = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <ul className="p-0 d-flex flex-column justify-content-center align-items-center">
        {props.tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={`d-flex justify-content-center align-items-start flex-column my-1 ${
                window.location.pathname.includes(tab.route) &&
                "active-nested-sidebar-btn"
              }`}
              onClick={() => {
                navigate(tab.route);
              }}
            >
              {tab.name}
            </li>
          );
        })}
      </ul>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NestedSidebarBody);
