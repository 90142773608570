import { toastType } from "../../constants";
import { logErrorsService } from "../../services";
import { customToast } from "../../shared/utility";

const logErrors = (data) => {
  return (dispatch) => {
    logErrorsService.logErrors(data).then(
      (response) => {},
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };
};

export const logErrorsActions = {
  logErrors,
};
