import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";

import Modal from "react-bootstrap/Modal";
import BarcodeReader from "react-barcode-reader";
import CreditCardPaymentModal from "./CreditCardPaymentModal";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import CustomerImage from "../../../assets/images/customerImage.svg";
import SearchField from "../../../shared/components/searchField/SearchField";
import ToggleButton from "../../../shared/components/toggleButtons/ToggleButton";
import InputTextField from "../../../shared/components/inputTextField/InputTextField";
import AddNewCustomerModal from "../NewTransaction/AddNewCustomer/AddNewCustomerModal";
import ReturnChartItem from "../../../shared/components/returnChartItem/ReturnChartItem";
import CustomerDropdown from "../../../shared/components/selectDropdown/CustomerDropdown";
import { scanGiftCardBarcodeValidationSchema } from "../../../shared/validationSchema/validationSchema";
import {
  systemActions,
  customerActions,
  transactionActions,
} from "../../../redux/actions";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  categoryDropdownConstants,
  toastMessages,
  toastType,
  EMPTY_STRING,
} from "../../../constants";
import {
  CrossIcon,
  SearchIcon,
  DeleteIcon,
  PaymentIcon,
  BlueBoxIcon,
  AsendingSortingIcon,
  DefaultSortingIcon,
  DesendingSortingIcon,
  EditIcon,
} from "../../../assets/icons/iconsProvider";
import {
  stringToSlug,
  getShortestSku,
  stringifyObject,
  parseToThousandSeperatorDecimalNumber,
  parseJsonObject,
  parseToNumber,
  parseToDecimalNumber,
  customToast,
  isSpinnerEnabled,
  roundNumber,
  handleCalculateTotalCogsAndStockPrice,
} from "../../../shared/utility";
import DataTable from "react-data-table-component";
import { transactionService } from "../../../services";
import { useLocation } from "react-router";
import SelectedCustomerCard from "../../../shared/components/shared/SelectedCustomerCard";
import { success } from "../../../redux/actions/actionsUtilities";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

const initialModalValues = {
  GiftCardBarcode: globalConstants.EMPTY_STRING,
};
//-------New Return component start here
const NewReturn = (props) => {
  // initializations
  const {
    customer,
    addReturn,
    transaction,
    spinnerArray,
    currentStore,
    activateSpinner,
    getCustomerById,
    deactivateSpinner,
    emptyCustomerById,
    searchTransaction,
    returnTransaction,
    isAddCustomerModal,
    getSearchTransaction,
    setReturnTransaction,
    emptySearchTransaction,
    toggleAddCustomerModal,
  } = props;
  let tempSubtotal = 0;
  const { state } = useLocation();
  const { customerById, allCustomers } = customer;
  const [searchText, setSearchText] = useState("");
  const [noteText, setNoteText] = useState("");
  const [noteTextToggle, setNoteTextToggle] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [activeButton, setActiveButton] = useState(globalConstants.CASH);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const [columns, setColumns] = useState([]);
  const [show, setShow] = useState(false);
  const toggleNoteTextBox = () => {
    setNoteTextToggle((noteTextToggle) => !noteTextToggle);
    setNoteText("");
  };
  const [activePaymentMethod, setActivePaymentMethod] = useState(
    globalConstants.CASH
  );
  const [isCreditCardPaymentModal, setIsCreditCardPaymentModal] =
    useState(false);
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [customerInfo, setCustomerInfo] = useState("");
  const [inventoryInfo, setInventoryInfo] = useState("");
  const [editInventoryInfo, setEditInventory] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const toggleIframe = () => setShowIframe((prevShowIframe) => !prevShowIframe);
  const [productUrl, setProductUrl] = useState("");
  const handleViewPriceCharting = (productName, categoryName) => {
    setProductUrl(
      `https://www.pricecharting.com/game/${stringToSlug(
        categoryName
      )}/${stringToSlug(productName)}`
    );
  };

  //-------handle add customer click
  const handleAddCustomerClick = () => {
    toggleAddCustomerModal(true);
  };
  const handleActiveButtonChange = (activeButton) => {
    setActivePaymentMethod(activeButton);
  };
  const handleReturnModalSubmit = async (data) => {
    activateSpinner(transactionConstants.GET_RETURN_ID);
    const returnIdResponse = await transactionService.getReturnId();
    const { TransactionType, creditUsed, ...transactionDetails } =
      searchTransaction?.[0] || {};
    const updatedInventoryInfo = inventoryInfo
      .map((item) => {
        if (item.editQTY !== undefined) {
          let updatedItem = {
            ...item,
            quantity: item.editQTY,
            serialNumber: item?.serialNumber ?? EMPTY_STRING,
          };
          delete updatedItem.editQTY;
          delete updatedItem.maxValue;
          return updatedItem;
        }
        return item;
      })
      .filter((item) => item.quantity > 0);
    const { initialTotalCogs, initialTotalStockPrice } =
      handleCalculateTotalCogsAndStockPrice(updatedInventoryInfo, false);
    let updatedInventoryReturn = {
      ...transactionDetails,
      PaymentType: activePaymentMethod,
      SubTotal: subtotal().toFixed(2),
      Tax: calculateTax(subtotal())?.toFixed(2),
      totalAmountToPay: getTotalAmountToPay(),
      TransactionID: searchText,
      inventory: updatedInventoryInfo,
      customer: customerInfo,
      initialTotalCogs,
      initialTotalStockPrice,
      note: noteText,
      id: returnIdResponse,
      giftCardBarcode: EMPTY_STRING,
      creditUsed: 0,
    };

    if (activePaymentMethod === globalConstants.GIFT_CARD) {
      updatedInventoryReturn = {
        ...updatedInventoryReturn,
        giftCardBarcode: data.GiftCardBarcode,
        giftCard: {
          giftCardName: `Gift Card (...${data.GiftCardBarcode.slice(-3)})`,
          giftCardChargeAmount: 0,
          giftCardBarcode: data.GiftCardBarcode,
          remainingBalance: 0,
        },
      };
    } else if (activePaymentMethod === globalConstants.CREDIT) {
      updatedInventoryReturn = {
        ...updatedInventoryReturn,
        creditUsed: getTotalAmountToPay(),
      };
    } else if (activePaymentMethod === globalConstants.CREDIT_CARD) {
      updatedInventoryReturn = {
        ...updatedInventoryReturn,
        poiId: data.poiId,
        activeCardPaymentType: data.activeCardPaymentType,
      };
    } else {
      updatedInventoryReturn = {
        ...updatedInventoryReturn,
        taxPercentage: transactionDetails.taxPercentage,
      };
    }

    deactivateSpinner(transactionConstants.GET_RETURN_ID);
    addReturn(
      updatedInventoryReturn,
      currentStore?.companyId,
      currentStore?.id
    );
  };
  const toggleCustomTradeModal = () => {
    if (!customerInfo?.id && activePaymentMethod === globalConstants.CREDIT) {
      customToast(toastMessages.PLEASE_SELECT_CUSTOMER, toastType.ERROR);
    } else {
      setShow(!show);
    }
  };
  const handleError = (error) => {};
  //-------Handle search button click
  const handleSearchClick = () => {
    setReturnTransaction({});
    setActiveButton(globalConstants.CASH);
    getSearchTransaction(currentStore?.id, searchText);
  };
  const resetReturnPage = () => {
    setActiveButton(globalConstants.CASH);
    emptyCustomerById();
    emptySearchTransaction();
    setInventoryInfo([]);
    setEditInventory([]);
    setSearchText("");
  };
  const isDisableReturnTransaction = () => {
    return !inventoryInfo || !inventoryInfo.some((item) => item.editQTY > 0);
  };
  const isDisableCreditCardButton = () => {
    return (
      isDisableReturnTransaction() ||
      !(
        searchTransaction[0]?.paymentHistory?.totalPaidAmount?.creditCard > 0 &&
        searchTransaction[0]?.paymentHistory?.totalReturnedAmount?.creditCard +
          getTotalAmountToPay() <=
          searchTransaction[0]?.paymentHistory?.totalPaidAmount?.creditCard
      )
    );
  };
  const handleInputFieldTable = (id, maxValuestring, valuestring) => {
    const value = Number(valuestring);
    const maxValue = Number(maxValuestring);
    let formattedValue = value === "" ? 0 : parseInt(value, 10);
    if (value <= maxValue) {
      setInventoryInfo((prevData) => {
        return prevData?.map((item) =>
          item.sku === id
            ? {
                ...item,
                editQTY: formattedValue,
              }
            : item
        );
      });
      setActiveButton(globalConstants.CASH);
    }
  };
  const handleInputChange = (sku, newValue) => {
    const updatedProductsinventory = inventoryInfo?.map((product) =>
      product.sku == sku ? { ...product, editQTY: newValue } : product
    );
    const updatedProductsEditInventory = editInventoryInfo?.map((product) =>
      product.sku == sku ? { ...product, editQTY: newValue } : product
    );
    setInventoryInfo(updatedProductsinventory);
    setEditInventory(updatedProductsEditInventory);
  };
  const deleteProduct = (productId) => {
    setInventoryInfo((currentInventoryInfo) =>
      currentInventoryInfo.map((product) => {
        if (product.sku === productId) {
          return { ...product, editQTY: 0 };
        }
        return product;
      })
    );
  };
  const columnHeaders = [
    {
      id: "name",
      name: "Name",
      selector: (row) => row.itemName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.itemName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "price",
      name: "Price",
      selector: (row) => row.priceAfterGlobalDiscount,
      cell: (row) => (
        <span className="users-cell-max-size">
          {`$${parseToThousandSeperatorDecimalNumber(
            row.priceAfterGlobalDiscount
          )}`}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "available",
      name: "Available",
      selector: (row) => row.quantity,
      cell: (row) => (
        <span className="users-cell-max-size">{row.quantity}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "editQty",
      name: "Edit QTY",
      selector: (row) => row.editQTY,
      cell: (row) => {
        return (
          <>
            {/* <InputNumberField /> */}
            <div className="input-textfield-table-wrapper">
              <input
                className={`input-textfield-table `}
                autoComplete="off"
                // placeholder={placeHolder}
                type="number"
                min={0}
                value={row.editQTY}
                onChange={(e) =>
                  handleInputFieldTable(row.sku, row.maxValue, e.target.value)
                }
              />
            </div>
          </>
        );
      },
    },
  ];
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };
  const subtotal = () => {
    if (Array.isArray(inventoryInfo) && inventoryInfo.length > 0) {
      return inventoryInfo.reduce((acc, item) => {
        return (
          acc + (item?.priceAfterGlobalDiscount ?? 0) * (item.editQTY || 0)
        );
      }, 0);
    }
    return "0.00";
  };
  const calculateItems = () => {
    if (Array.isArray(inventoryInfo) && inventoryInfo.length > 0) {
      return inventoryInfo.reduce((acc, item) => {
        return acc + item.editQTY;
      }, 0);
    }
    return "0.00";
  };
  const calculateTax = (subtotal) => {
    const appliedTax = searchTransaction[0]?.Tax;
    if (appliedTax === 0) {
      return 0;
    } else {
      return (subtotal * (searchTransaction?.[0]?.taxPercentage ?? 0)) / 100;
    }
  };
  const getTotalAmountToPay = () => {
    tempSubtotal = subtotal();
    return roundNumber(tempSubtotal + calculateTax(tempSubtotal));
  };
  //-------Return New Return Component
  const handleScan = async (data) => {
    if (!addCustomerModal) {
      setSearchText(data);
      getSearchTransaction(currentStore?.id, data);
      emptyCustomerById();
      emptySearchTransaction();
      setInventoryInfo([]);
      setEditInventory([]);
    }
  };
  const formattedColumns = columns.map((column) => {
    if (column.sortable) {
      return {
        ...column,
        name: (
          <div className=" d-flex w-100">
            {column.name}
            <div className="ml-2 d-flex justify-content-center align-items-center">
              {column.sortDirection === null && (
                <DefaultSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.ASC && (
                <AsendingSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.DESC && (
                <DesendingSortingIcon className="ms-1 cursor-pointer" />
              )}
            </div>
          </div>
        ),
      };
    } else {
      return column;
    }
  });
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };
  //------ handle customer edit
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === customerInfo?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    handleActiveButtonChange(buttonName); // Call the callback function with the new active button
  };
  const clearSearchText = () => {
    setSearchText("");
  };

  useEffect(() => {
    if (currentStore) {
      setColumns(columnHeaders);
      resetReturnPage();
    }
  }, [currentStore]);

  useEffect(() => {
    setCustomerInfo({ ...customerById });
  }, [customerById]);

  useEffect(() => {
    const inventoryWithMaxValueEditQTY = searchTransaction?.[0]?.inventory.map(
      (item) => ({
        ...item,
        editQTY: 0,
        maxValue: item.quantity,
      })
    );
    if (searchTransaction.length > 0) {
      setInventoryInfo(inventoryWithMaxValueEditQTY);
    }
    if (searchTransaction?.[0]?.customer.id) {
      getCustomerById(
        currentStore.companyId,
        currentStore?.id,
        searchTransaction?.[0]?.customer.id
      );
    }
  }, [searchTransaction]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
    // eslint-disable-next-line
  }, [isAddCustomerModal]);

  useEffect(() => {
    if (state?.id) {
      setSearchText(state?.id);
      getSearchTransaction(currentStore?.id, state?.id);
    }
    emptyCustomerById();
    emptySearchTransaction();

    return () => {
      emptyCustomerById();
      setReturnTransaction({});
      emptySearchTransaction();
      setInventoryInfo([]);
      setEditInventory([]);
    };
  }, []);

  return (
    <>
      <div>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <Topbar title="New  Return"></Topbar>
      <Row className="m-0 h-100 w-100 ">
        <Col md={8} className=" ps-0">
          <Row className="m-0 h-100  user-screen-wrapper w-100 ">
            <Row className="m-0  p-0 w-100 user-screen-wrapper">
              <Col md={10} className="">
                <SearchField
                  onChange={(e) => setSearchText(e.target.value)}
                  IconImage={SearchIcon}
                  placeHolder="Search"
                  value={searchText}
                  CrossIcon={searchText ? CrossIcon : ""}
                  clearSearchText={clearSearchText}
                  BlueBoxIcon={BlueBoxIcon}
                />
              </Col>
              <Col
                md={2}
                className="d-flex justify-content-end align-items-center gap-3"
              >
                <Button
                  label={buttonNameConstants.SEARCH}
                  // IconImage={AddIcon}
                  isDisabled={!searchText}
                  buttonType={buttonTypeConstants.LIGHT_PURPLE}
                  handleClick={handleSearchClick}
                />
              </Col>
            </Row>
            {searchTransaction[0]?.id &&
              (customerInfo?.id ? (
                <Row className="m-0 user-screen-wrapper w-100 ">
                  <Col md={12} className="my-2 rounded-2 py-2 bg-white mt-2">
                    <SelectedCustomerCard
                      customer={customerInfo}
                      isStoreCredit={true}
                      isDeleteEditOptions={true}
                      handleCustomerDeleteClick={() => {
                        setCustomerInfo("");
                      }}
                      handleCustomerEditClick={handleCustomerEditClick}
                    />
                  </Col>
                </Row>
              ) : (
                <Col md={12} className="mt-2">
                  <CustomerDropdown
                    value={
                      customerInfo?.id
                        ? {
                            value: stringifyObject(customerInfo?.customer),
                            label: (
                              <div className="d-flex align-items-center gap-2 ">
                                <span>
                                  <img
                                    src={CustomerImage}
                                    className="new-sale-customer-image"
                                    alt="customer"
                                  />
                                </span>
                                {`${customerInfo?.firstName} ${customerInfo?.lastName}`}
                              </div>
                            ),
                          }
                        : ""
                    }
                    dropdownIndicator={false}
                    noOptionsMessage="No Customer Found"
                    placeholder="Customer Name, Phone, Email"
                    handleAddCustomerClick={handleAddCustomerClick}
                    SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                    handleChange={(selectedOption) => {
                      setCustomerInfo(
                        selectedOption?.value
                          ? parseJsonObject(selectedOption?.value)
                          : globalConstants.EMPTY_STRING
                      );
                    }}
                    selectedCustomerFlag={selectedCustomerFlag}
                  />
                </Col>
              ))}
            <Row className="m-0 p-0  w-100 h-100 user-screen-wrapper  pt-3">
              <DataTable
                className="categories-data-table tradeIn-table-height"
                columns={formattedColumns}
                data={inventoryInfo}
                fixedHeader={true}
                onSort={onTableSort}
                defaultSortFieldId={defaultSortFieldId}
              />
            </Row>
          </Row>
        </Col>
        <Col md={4}>
          <Row className="m-0">
            <p className="tradeIns-heading new-return-text p-0">
              Return <span>{searchText}</span>
            </p>
            <div className="user-screen-wrapper ps-0 container-scrollbar  return-card-wrapper">
              {inventoryInfo &&
                inventoryInfo
                  .filter((product) => product.editQTY > 0)
                  .map((product) => (
                    <ReturnChartItem
                      key={product.sku}
                      product={product}
                      handleInputChange={handleInputChange}
                      deleteProduct={deleteProduct}
                      handleViewPriceCharting={handleViewPriceCharting}
                      toggleIframe={toggleIframe}
                    />
                  ))}
            </div>
            <Col className="p-0 mt-2 m-0" md={12}>
              <ToggleButton
                activeButton={activeButton}
                Disabled={!customerInfo?.id}
                isCreditCardButtonDisable={isDisableCreditCardButton()}
                handleButtonClick={handleButtonClick}
                buttonsList={[
                  globalConstants.CASH,
                  globalConstants.CREDIT,
                  globalConstants.CREDIT_CARD,
                ]}
              />
            </Col>
            <Row className="p-0  m-0 mt-4">
              <Col className="p-0    " md={6}>
                <p className="return-payment-add">ADD</p>
                {/* <p className="return-payment-tax-note">
                  {" "}
                  Tax ({searchTransaction?.[0]?.taxPercentage ?? 0}%) <EditIcon className={"gray-icon-color"} />
                </p> */}
              </Col>
              <Col className="p-0" md={6}>
                <p
                  onClick={toggleNoteTextBox}
                  className="text-end return-payment-note cursor-pointer"
                >
                  Note
                </p>
                {/* <p className="text-end return-payment-tax-note">
                  {" "}
                  ${calculateTax(subtotal())?.toFixed(2)}
                </p> */}
              </Col>
              <Col className="p-0 " md={12}>
                {noteTextToggle && (
                  <textarea
                    className="note-text-area"
                    onChange={(e) => {
                      setNoteText(e.target.value);
                    }}
                    value={noteText}
                    name=""
                    id=""
                  ></textarea>
                )}
              </Col>
              <Col className="p-0" md={12}>
                <Button
                  type="submit"
                  className="payment-button-children"
                  handleClick={() => {
                    toggleCustomTradeModal();
                  }}
                  IconImage={PaymentIcon}
                  label={
                    <div className="payment-icon-button">
                      <div className="d-flex gap-2">
                        <p className="payment-icon-button-text">
                          Pay <span>{calculateItems()} items</span>
                        </p>
                      </div>
                      <p className="payment-return-amount-text">
                        ${getTotalAmountToPay()}
                      </p>
                    </div>
                  }
                  isDisabled={isDisableReturnTransaction()}
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <AddNewCustomerModal
          selectedCustomer={selectedCustomer}
          addCustomerModal={addCustomerModal}
          customerToEdit={customerToEdit}
        />
        <Modal
          size="xl"
          animation={true}
          centered
          backdrop="static"
          show={showIframe}
          onHide={toggleIframe}
          // className="iframe-edit-inventory"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <iframe
              title="Product Iframe"
              src={productUrl}
              className="iframe-website-edit-inventory  w-100"
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={toggleCustomTradeModal}
          size="md"
          animation={true}
          centered
          backdrop="static"
          className="add-inventory-modal"
        >
          <Formik
            initialValues={initialModalValues}
            validate={(values) => {
              if (activePaymentMethod === globalConstants.GIFT_CARD) {
                return scanGiftCardBarcodeValidationSchema(values);
              }
              return {}; // No validation for other payment methods
            }}
            onSubmit={handleReturnModalSubmit}
          >
            <Form className="px-0">
              <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                  <span className="refund-type">Refund</span>
                  <span className="refund-type-to">to</span>
                  <span className="refund-type">
                    {activePaymentMethod === globalConstants.CASH
                      ? buttonNameConstants.CASH
                      : activePaymentMethod === globalConstants.GIFT_CARD
                      ? buttonNameConstants.GIFT_CARD_PAYMENT
                      : activePaymentMethod === globalConstants.CREDIT_CARD
                      ? buttonNameConstants.CREDIT_CARD
                      : buttonNameConstants.STORE_CREDIT}
                  </span>
                  <span className="refund-type-items">
                    {calculateItems()} items
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pt-0">
                <Row className="m-0 p-0">
                  {inventoryInfo &&
                    inventoryInfo
                      .filter((product) => product.editQTY > 0)
                      ?.map((product) => {
                        return (
                          <Col
                            md={12}
                            className="m-0 p-0 mb-4 d-flex justify-content-between"
                          >
                            <div>
                              <span className="return-items-modal">
                                {product.editQTY}
                              </span>
                              <span className="return-product-name-modal">
                                {" "}
                                {product?.item?.length > 12
                                  ? `${product.itemName.substring(0, 12)}...`
                                  : product.itemName}
                              </span>
                              <span className="return-items-modal">
                                # {getShortestSku(product.sku)}
                              </span>
                            </div>
                            <div className="return-amount-modal">
                              $
                              {(
                                product.editQTY *
                                product.priceAfterGlobalDiscount
                              ).toFixed(2)}
                            </div>
                          </Col>
                        );
                      })}
                  <Col
                    md={12}
                    className="m-0  mb-4 p-0 d-flex justify-content-between"
                  >
                    <div className="return-product-name-modal m-0">
                      Subtotal
                    </div>

                    <div className="return-amount-modal">
                      $ {Number(subtotal()).toFixed(2)}
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className="m-0  mb-4 p-0 d-flex justify-content-between"
                  >
                    <div className="return-product-name-modal m-0">
                      Tax ({searchTransaction?.[0]?.taxPercentage}
                      %)
                    </div>
                    <div className="return-amount-modal">
                      ${calculateTax(subtotal())?.toFixed(2)}
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className="m-0  mb-4 p-0 d-flex justify-content-between"
                  >
                    <div className="return-product-totals-modal m-0">
                      Total ( {calculateItems()} items)
                    </div>
                    <div className="return-product-totals-modal m-0">
                      $ {getTotalAmountToPay()}
                    </div>
                  </Col>

                  {activePaymentMethod === globalConstants.GIFT_CARD && (
                    <Col md={12} className="mb-3 p-0 ">
                      <InputTextField
                        name="GiftCardBarcode"
                        placeHolder="Enter/Scan Gift Card Barcode"
                        className="background-gray"
                      />
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Col md={12} className=" m-0 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={toggleCustomTradeModal}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                  ></Button>
                  {activePaymentMethod === globalConstants.CREDIT_CARD ? (
                    <>
                      <CreditCardPaymentModal
                        isCreditCardPaymentModal={isCreditCardPaymentModal}
                        setIsCreditCardPaymentModal={
                          setIsCreditCardPaymentModal
                        }
                        amountToPay={getTotalAmountToPay()}
                        handleReturnModalSubmit={handleReturnModalSubmit}
                      />
                      <Button
                        type="button"
                        className="w-100"
                        label={buttonNameConstants.PAY}
                        isDisabled={isSpinnerEnabled(
                          spinnerArray,
                          transactionConstants.ADD_RETURN
                        )}
                        handleClick={() => {
                          setIsCreditCardPaymentModal(true);
                        }}
                      ></Button>
                    </>
                  ) : (
                    <Button
                      type="submit"
                      className="w-100"
                      label={buttonNameConstants.PAY}
                      isDisabled={isSpinnerEnabled(
                        spinnerArray,
                        transactionConstants.ADD_RETURN
                      )}
                    ></Button>
                  )}
                </Col>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      </Row>
    </>
  );
}; //-------new Return component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customer: state.customer,
  transaction: state.transaction,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  returnTransaction: state.transaction.returnTransaction,
  searchTransaction: state.transaction.searchTransaction,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getSearchTransaction: (storeId, id) =>
    dispatch(transactionActions.getSearchTransaction(storeId, id)),
  getCustomerById: (companyId, storeId, customerId) =>
    dispatch(customerActions.getCustomerById(companyId, storeId, customerId)),
  emptyCustomerById: () => dispatch(customerActions.emptyCustomerById()),
  emptySearchTransaction: () =>
    dispatch(transactionActions.emptySearchTransaction()),
  addReturn: (returnData, companyId, storeId) =>
    dispatch(transactionActions.addReturn(returnData, companyId, storeId)),
  getReturnId: () => dispatch(transactionActions.getReturnId()),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  setReturnTransaction: (data) =>
    dispatch(success(data, transactionConstants.SET_RETURN_TRANSACTION)),
});

//-------Export new return Component
export default connect(mapStateToProps, mapDispatchToProps)(NewReturn);
