import React, { useEffect } from "react";
import { connect } from "react-redux";

import CustomerImage from "../../../assets/images/customerImage.svg";
import {
  BlueBoxIcon,
  DeleteIcon,
  EditIcon,
} from "../../../assets/icons/iconsProvider";
import { EMPTY_STRING } from "../../../constants";
import {
  getCustomerFullName,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import { customerService } from "../../../services";
import { customerActions } from "../../../redux/actions";

const SelectedCustomerCard = (props) => {
  const {
    customer,
    currentStore,
    getCustomerById,
    isStoreCredit = true,
    handleCustomerEditClick,
    isDeleteEditOptions = true,
    handleCustomerDeleteClick,
    className = {
      img: EMPTY_STRING,
      nameDiv: EMPTY_STRING,
      nameSpan: EMPTY_STRING,
      parnetDiv: EMPTY_STRING,
      storeCreditDiv: EMPTY_STRING,
      editDeleteDiv: EMPTY_STRING,
      deleteButton: EMPTY_STRING,
      editButton: EMPTY_STRING,
    },
  } = props;

  useEffect(() => {
    getCustomerById(
      currentStore?.companyId,
      currentStore?.id,
      customer?.id,
      handleCustomerEditClick
    );
  }, []);

  return (
    <div
      className={`w-100 d-flex justify-content-start align-items-center ${
        className.parnetDiv || "selected-customer"
      }`}
    >
      <img className={`${className.img}`} src={CustomerImage} alt="customer" />
      <div
        className={`d-flex flex-column justify-content-center ${
          className.nameDiv || "ms-2"
        } `}
      >
        <span className={`customer-name-heading ${className.nameSpan}`}>
          {getCustomerFullName(customer)}
        </span>
        <span>{customer?.mobile || customer?.email || "(000) 000-0000"}</span>
      </div>
      {isStoreCredit && (
        <div
          className={`d-flex flex-column justify-content-center  align-items-center customer-credit ms-auto ${className.storeCreditDiv}`}
        >
          <span>Store Credit</span>
          <span>
            $
            {parseToThousandSeperatorDecimalNumber(
              customer?.currentBalance || 0
            )}
          </span>
        </div>
      )}
      {isDeleteEditOptions && (
        <div className={`${className.editDeleteDiv}`}>
          <button
            type="button"
            className={`cart-card-delete-button ${className.deleteButton}`}
            onClick={handleCustomerDeleteClick}
          >
            <DeleteIcon />
          </button>
          <button
            type="button"
            className={`cart-card-delete-button ${className.editButton}`}
            onClick={handleCustomerEditClick}
          >
            <EditIcon />
          </button>
        </div>
      )}
    </div>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getCustomerById: (companyId, storeId, customerId) =>
    dispatch(customerActions.getCustomerById(companyId, storeId, customerId)),
});

//-------Export History Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedCustomerCard);
