import React, { Suspense } from "react";
import RouterWrapper from "./router/RouterWrapper";
import { BrowserRouter as Router } from "react-router-dom";

import { LogProvider } from "../../shared/hooks/logsContext";
import PageRedirection from "../../shared/hooks/PageRedirection";
import ErrorBoundaryContainer from "./ErrorBoundary/ErrorBoundaryContainer";
const App = () => {
  return (
    <Suspense fallback={null}>
      <Router>
        <LogProvider>
          <ErrorBoundaryContainer>
            <PageRedirection />
            <RouterWrapper />
          </ErrorBoundaryContainer>
        </LogProvider>
      </Router>
    </Suspense>
  );
};

export default App;
