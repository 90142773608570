import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

import ScanningImage from "../../../assets/images/ScanIdImage.svg";
import { buttonNameConstants, buttonTypeConstants } from "../../../constants";
import Button from "../../../shared/components/Buttons/Button";

const ScanIdModal = ({ isScanning, toggleScanningModal }) => {
  const handleHideModal = () => {
    toggleScanningModal();
  };
  return (
    <Modal
      show={isScanning}
      size={"md"}
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className={"nested-modal-z-index"}
      backdropClassName={"nested-modal-z-index"}
    >
      <Modal.Body className="add-inventory-modal-body ">
        <Row className="w-100">
          <Col md={12} className="d-flex justify-content-center">
            <img height="60" src={ScanningImage} />
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-center flex-column align-items-center opacity-50 gap-2 mt-3"
          >
            <h1>Scan ID Barcode</h1>
            <p>Scan function is only compatible with a US Driver’s License</p>
          </Col>
          <Col md={12} className="d-flex justify-content-center mt-3">
            <Button
              label={buttonNameConstants.CANCEL}
              buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
              handleClick={handleHideModal}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ScanIdModal;
