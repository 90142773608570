import React, { useState, useEffect } from "react";
import {
  StepDownNumberIcon,
  StepUpNumberIcon,
} from "../../../assets/icons/iconsProvider";

const VerticalNumberField = (props) => {
  const { sku, disabled = false, quantity, maxValue, onChange } = props;

  // State to store the value of the input field
  const [value, setValue] = useState(1);
  // Function to handle incrementing the value
  const handleIncrement = (event) => {
    event.stopPropagation();
    const fieldValue = Number(value);
    if (fieldValue < maxValue) {
      setValue(fieldValue + 1);
      onChange(sku, fieldValue + 1);
    }
  };

  // Function to handle decrementing the value
  const handleDecrement = (event) => {
    event.stopPropagation();
    const fieldValue = Number(value);
    if (fieldValue > 1) {
      setValue(fieldValue - 1);
      onChange(sku, fieldValue - 1);
    }
  };

  const handleInputValueChange = (e) => {
    const newValue = e.target.value.replace(/^0+/, "") || "0";
    const sanitizedValue = parseInt(newValue) || 0;
    if (sanitizedValue <= maxValue && sanitizedValue > 0) {
      setValue(sanitizedValue);
      onChange(sku, sanitizedValue);
    }
  };

  const inputWidth = value?.toString().length;

  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  return (
    <div className="vertical-number-field-container">
      <button type="button" disabled={disabled} onClick={handleIncrement}>
        <StepUpNumberIcon className="p-0" />
      </button>
      <input
        className={`vertical-number-field ${
          inputWidth === 2
            ? "vertical-number-field-2-digit"
            : inputWidth === 3
            ? "vertical-number-field-3-digit"
            : inputWidth === 4
            ? "vertical-number-field-4-digit"
            : ""
        } `}
        autoComplete="off"
        type="number"
        value={value}
        onChange={(e) => handleInputValueChange(e)}
        min={1}
        onClick={(event) => {
          event.stopPropagation();
        }}
        disabled={disabled}
      />
      <button type="button" disabled={disabled} onClick={handleDecrement}>
        <StepDownNumberIcon className="p-0" />
      </button>
    </div>
  );
};

export default VerticalNumberField;
