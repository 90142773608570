import { postRequest } from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

const logErrors = (data) => {
  return postRequest(`log-errors`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

export const logErrorsService = {
  logErrors,
};
