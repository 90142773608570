import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import { transactionActions } from "../../../redux/actions";
import Topbar from "../../../../src/shared/components/topbar/Topbar";
import ImportTransactionHistory from "./ImportTransactionHistory/ImportTransactionHistory";
import SummaryCardSkeleton from "../../../shared/Skeletons/inventorySkeletons/SummaryCardSkeleton";
import TransactionOverViewCard from "../../../shared/components/transactionOverViewCard/TransactionOverViewCard";
import TransactionHistoryTableWithFilters from "../../../shared/components/shared/TransactionHistoryTableWithFilters";
import {
  globalConstants,
  buttonNameConstants,
  transactionConstants,
  EMPTY_STRING,
} from "../../../constants";
import { ExportCSVIcon } from "../../../assets/icons/iconsProvider";
import {
  formatDate,
  downloadCSV,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
} from "../../../shared/utility";
import { ACTIVITY_TYPES_ENUMS } from "../../../system/globalEnums";
import { useScreenWidth } from "../../../shared/hooks/windowResize";

//-------History component start here
const History = (props) => {
  const {
    spinnerArray,
    currentStore,
    sellTradeStats,
    sellTradeHistory,
    emptyDuplicateRecords,
    duplicateHistoryRecords,
    bulkUploadTransactionHistory,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [option, setOption] = useState({
    label: globalConstants.ALL,
    value: globalConstants.ALL,
  });
  const [screenWidth, setScreenWidth] = useScreenWidth();
  const [importTransactionHistoryModal, setImportTransactionHistoryModal] =
    useState(false);

  //-------Handle History edit click
  const toggleImportTransactionHistoryModal = () => {
    setImportTransactionHistoryModal(!importTransactionHistoryModal);
  };

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = sellTradeHistory.filter((row) => {
      return (
        String(
          row.type +
            row.id +
            row.PaymentType +
            row.TotalAmountPaid +
            row.customer.name +
            formatDate(row.createdOn)
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    return filterList;
  };

  const filterColumnforExport = (allData) => {
    return allData.map((row) => {
      const cartQuantity =
        row.inventory?.reduce(
          (acc, curr) =>
            acc + Number(curr.quantity || curr.price?.quantity || 0),
          0
        ) || 0;
      const exportObject = {
        SaleId: row.id,
        CreatedOn: row.createdOn,
        TransactionType: row.type,
        PaymentType: row.PaymentType,
        CartQuantity: cartQuantity,
        Customer: row.customer.name,
        TotalAmountPaid: row.totalAmountToPay,
      };
      return exportObject;
    });
  };
  const handleExportButtonClick = () => {
    downloadCSV(
      searchText
        ? filterColumnforExport(handleFilterData())
        : filterColumnforExport(sellTradeHistory)
    );
  };
  //-------Return History Component
  return (
    <>
      <Topbar
        handleClick={() => handleExportButtonClick()}
        exportButton={true}
        IconImage={ExportCSVIcon}
        label={buttonNameConstants.EXPORT_CSV}
        title="Transaction History"
        importCsvButton={true}
        handleImportCsvClick={() => toggleImportTransactionHistoryModal()}
      ></Topbar>
      <Row className="m-0 ">
        <Col
          md={12}
          className="transaction-overview-card-wrapper d-flex flex-wrap gap-3"
        >
          {!isSpinnerEnabled(
            spinnerArray,
            transactionConstants.GET_ALL_TRANSACTION
          ) ? (
            <>
              {option?.value === ACTIVITY_TYPES_ENUMS.ALL ||
              option?.value === ACTIVITY_TYPES_ENUMS.SALE ? (
                <>
                  <TransactionOverViewCard
                    label="Total Sold"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalSales ?? 0
                    )}`}
                  />
                  <TransactionOverViewCard
                    label="Total COGS"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalCogs ?? 0
                    )}`}
                  />
                  <TransactionOverViewCard
                    label="Total Net"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalNet ?? 0
                    )}`}
                  />{" "}
                  <TransactionOverViewCard
                    label="Total Number of Transactions"
                    data={parseToThousandSeperatorWholeNumber(
                      sellTradeStats?.totalNumberOfTransactions ?? 0
                    )}
                  />
                </>
              ) : (
                ""
              )}

              {option?.value === ACTIVITY_TYPES_ENUMS.RETURN ? (
                <TransactionOverViewCard
                  label="Total Return Transactions"
                  data={parseToThousandSeperatorWholeNumber(
                    sellTradeStats?.totalNumberOfReturns ?? 0
                  )}
                />
              ) : (
                ""
              )}
              {option?.value === ACTIVITY_TYPES_ENUMS.ALL ||
              option?.value === ACTIVITY_TYPES_ENUMS.RETURN ? (
                <TransactionOverViewCard
                  label="Total Returns"
                  data={`$${parseToThousandSeperatorDecimalNumber(
                    sellTradeStats?.totalReturns ?? 0
                  )}`}
                />
              ) : (
                ""
              )}

              {option?.value === ACTIVITY_TYPES_ENUMS.RETURN ? (
                <>
                  <TransactionOverViewCard
                    label="Total Cash Returns"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalCashReturns ?? 0
                    )}`}
                  />
                  <TransactionOverViewCard
                    label="Total Store Credit Returns"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalStoreCreditReturns ?? 0
                    )}`}
                  />{" "}
                  <TransactionOverViewCard
                    label="Total Credit Card Returns"
                    data={`$${parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalCreditCardReturns ?? 0
                    )}`}
                  />
                </>
              ) : (
                ""
              )}

              {option?.value === ACTIVITY_TYPES_ENUMS.TRADE ? (
                <TransactionOverViewCard
                  label="Total Trade In Transactions"
                  data={parseToThousandSeperatorWholeNumber(
                    sellTradeStats?.totalNumberOfTrades ?? 0
                  )}
                />
              ) : (
                ""
              )}

              {option?.value === ACTIVITY_TYPES_ENUMS.ALL ||
              option?.value === ACTIVITY_TYPES_ENUMS.TRADE ? (
                <>
                  <TransactionOverViewCard
                    label="Total Trade Purchases"
                    data={parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalTradeInsPurchases ?? 0
                    )}
                  />
                  <TransactionOverViewCard
                    label="Total Trade Ins Stock Value"
                    data={parseToThousandSeperatorDecimalNumber(
                      sellTradeStats?.totalTradeInsStockValue ?? 0
                    )}
                  />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
            </>
          )}
        </Col>
      </Row>
      <TransactionHistoryTableWithFilters
        searchText={searchText}
        option={option}
        setOption={setOption}
        setSearchText={setSearchText}
        tableHeight={
          option?.value === globalConstants.ALL &&
          screenWidth < globalConstants.LARGE_SCREEN_SIZE
            ? `calc(100vh - 385px)`
            : `calc(100vh - 320px)`
        }
      />
      <ImportTransactionHistory
        currentStore={currentStore}
        emptyDuplicateRecords={emptyDuplicateRecords}
        duplicateHistoryRecords={duplicateHistoryRecords}
        bulkUploadTransactionHistory={bulkUploadTransactionHistory}
        importTransactionHistoryModal={importTransactionHistoryModal}
        toggleImportTransactionHistoryModal={
          toggleImportTransactionHistoryModal
        }
      />
    </>
  );
}; //-------History component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  sellTradeStats: state.transaction.allTransaction.sellTradeStats,
  duplicateHistoryRecords: state.transaction.duplicateHistoryRecords,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  sellTradeHistory: state.transaction.sellTradeHistory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  bulkUploadTransactionHistory: (storeId, data) =>
    dispatch(transactionActions.bulkUploadTransactionHistory(storeId, data)),
  emptyDuplicateRecords: () =>
    dispatch(transactionActions.emptyDuplicateRecords()),
});

//-------Export History Component
export default connect(mapStateToProps, mapDispatchToProps)(History);
