import React, { useState } from "react";
import { connect } from "react-redux";

import {
  customerConstants,
  EMPTY_STRING,
  globalConstants,
} from "../../../constants";
import { success } from "../../../redux/actions/actionsUtilities";
import CustomBodyModal from "../../../shared/components/modal/CustomBodyModal";
import TransactionHistoryTableWithFilters from "../../../shared/components/shared/TransactionHistoryTableWithFilters";

const CustomerLogsModal = (props) => {
  const { isCustomerLogsModal, setIsCustomerLogsModal, customer } = props;
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [option, setOption] = useState({
    label: globalConstants.ALL,
    value: globalConstants.ALL,
  });

  return (
    <>
      <CustomBodyModal
        show={isCustomerLogsModal}
        size={"xl"}
        onHide={(e) => {
          setIsCustomerLogsModal(false);
        }}
      >
        <TransactionHistoryTableWithFilters
          option={option}
          setOption={setOption}
          searchText={searchText}
          isInfiniteScroll={true}
          setSearchText={setSearchText}
          isCustomerLogsModal={{
            customer,
            isSearchField: false,
          }}
        />
      </CustomBodyModal>
    </>
  );
};

// reducer
const mapStateToProps = (state) => ({
  isCustomerLogsModal: state.customer.isCustomerLogsModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setIsCustomerLogsModal: (data) =>
    dispatch(success(data, customerConstants.SET_IS_CUSTOMER_LOGS_MODAL)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(CustomerLogsModal);
