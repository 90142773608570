import moment from "moment";
import { connect } from "react-redux";
import React, { useMemo } from "react";
import { Modal, Row, Col } from "react-bootstrap";

import DashboardDatePicker from "../../../../../shared/components/datetimePickerDropdown/DashboardDatePicker";
import TableCustomHeader from "../../../../../shared/components/table/TableCustomHeader";
import {
  getReceiptPrintDate,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
} from "../../../../../shared/utility";
import {
  categoryDropdownConstants,
  defaultGridState,
  globalConstants,
  inventoryConstants,
  ITEM_HISTORY_DATEPICKER_OPTIONS,
} from "../../../../../constants";
import CategoriesDropdown from "../../../../../shared/components/selectDropdown/CategoriesDropdown";
import { PRODUCT_TYPES_ENUMS } from "../../../../../system/globalEnums";
import InfiniteScrollTable from "../../../../../shared/components/agGrid/InfiniteScrollTable";
import { inventoryActions } from "../../../../../redux/actions";

const ItemHistoryModal = ({
  item,
  gridState,
  timeFrame,
  spinnerArray,
  setTimeFrame,
  setGridState,
  updateGridState,
  clearItemHistory,
  inventoryHistory,
  setTransactionType,
  isItemHistoryModal,
  toggleItemHistoryModal,
}) => {
  //-------UseRef

  const columns = useMemo(
    () => [
      {
        id: "createdOn",
        header: "Date",
        accessorKey: "createdOn",
        cell: (props) => getReceiptPrintDate(props?.row?.original?.createdOn),
        enableSorting: false,
      },
      {
        id: "TransactionType",
        header: "Activity",
        accessorKey: "TransactionType",

        enableSorting: false,
      },
      {
        id: "cogs",
        header: "Unit Purchase Price",
        accessorKey: "cogs",
        cell: (props) => (
          <span className="inventory-cell-max-size text-center">
            ${" "}
            {parseToThousandSeperatorDecimalNumber(props?.row?.original?.cogs)}
          </span>
        ),
        enableSorting: false,
      },
      {
        id: "amount",
        header: "Unit Sell Price",
        accessorKey: "amount",
        cell: (props) => (
          <span className="inventory-cell-max-size text-center">
            ${" "}
            {parseToThousandSeperatorDecimalNumber(
              props?.row?.original?.amount
            )}
          </span>
        ),
        enableSorting: false,
      },
      {
        id: "quantity",
        header: "Quantity",
        accessorKey: "quantity",
        cell: (props) => (
          <span className="inventory-cell-max-size text-center">
            {parseToThousandSeperatorWholeNumber(
              props?.row?.original?.quantity
            )}
          </span>
        ),
        enableSorting: false,
      },
      {
        id: "processedBy",
        header: "Processed By",
        accessorKey: "processedBy",
        enableSorting: false,
      },
    ],
    []
  );

  const gridData = useMemo(() => {
    let filteredData = inventoryHistory?.inventoryHistory || [];
    setGridState({
      ...gridState,
      pageIndex: inventoryHistory.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        inventoryHistory.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        inventoryHistory.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        inventoryHistory.pageStartRecordNumber |
        defaultGridState?.pageStartRecordNumber,
    });
    return filteredData;
  }, [inventoryHistory]);

  //-------- handle hide modal
  const handleHideModal = () => {
    toggleItemHistoryModal();
    clearItemHistory([]);
    setGridState({ ...defaultGridState, pageSize: "20" });
  };

  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };

  const fetchMoreData = () => {
    updateGridState({
      ...gridState,
      pageSize:
        Number(
          gridState?.pageSize ?? inventoryConstants.ITEM_HISTORY_LIST_LENGTH
        ) + inventoryConstants.ITEM_HISTORY_LIST_LENGTH,
    });
  };
  return (
    <Modal
      show={isItemHistoryModal}
      size={"lg"}
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal "
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Item History - {item?.product_name || "Item Name"} (
            {item?.category_name || "Category Name"})
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body">
        <Row className="m-0 w-100">
          <Col md={12} className="d-flex align-items-center gap-2">
            <DashboardDatePicker
              datePickerOptions={ITEM_HISTORY_DATEPICKER_OPTIONS}
              label="Date"
              getTimeFrame={getTimeFrame}
            />
            <CategoriesDropdown
              label="Activity Type"
              isClearable={false}
              placeholder="Activity Type"
              noOptionsMessage="No Activity Type Found"
              options={inventoryConstants.ACTIVITY_TYPE}
              className={"filter-dropdowns-activity-type "}
              SelectIconImage={categoryDropdownConstants.BEND_ICON}
              handleChange={(e) => {
                setTransactionType(
                  e == null
                    ? {
                        label: globalConstants.ALL,
                        value: globalConstants.ALL,
                      }
                    : e
                );
              }}
              defaultValue={{
                value: PRODUCT_TYPES_ENUMS.ALL,
                label: "All",
              }}
            />
          </Col>
          <Col md={12} className="px-0  py-2">
            <InfiniteScrollTable
              columns={columns}
              data={gridData}
              update={fetchMoreData}
              hasMore={
                inventoryHistory?.pageSize < inventoryHistory.totalRecords
              }
              isLoading={isSpinnerEnabled(
                spinnerArray,
                inventoryConstants.GET_INVENTORY_HISTORY
              )}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  clearItemHistory: (data) => dispatch(inventoryActions.clearItemHistory(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemHistoryModal);
