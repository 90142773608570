import React from "react";

import { SET_BY_MARGIN_ENUMS } from "../../../system/globalEnums";
import AppliesToCategories from "../AppliesToCategories/AppliesToCategories";

const TableCustomAppliesToField = ({ row, groupArray, groupType }) => {
  const categoryText =
    groupType === SET_BY_MARGIN_ENUMS.PRODUCT.value ? "Products" : "Categories";

  return (
    <>
      {groupArray.length > 2 ||
      (groupArray.length === 2 &&
        groupArray.reduce(
          (totalChars, categoryOrType) =>
            totalChars + categoryOrType?.categoryName?.length,
          0
        ) > 30) ? (
        <>
          <AppliesToCategories
            key={row.id}
            anchorSelect={`#tool-${row.id}`}
            categories={groupArray}
            groupType={groupType}
            className={
              "applies-to-category container-scrollbar category-tooltip-wrapper"
            }
          ></AppliesToCategories>

          <button
            key={row.id}
            id={`tool-${row.id}`}
            className="action-Button-Wrapper ps-0"
          >
            {groupArray.length} {categoryText}
          </button>
        </>
      ) : (
        <div className="applies-to-category">
          {groupArray.map((categoryOrType, index) => (
            <span key={index}>
              {groupType === SET_BY_MARGIN_ENUMS.TYPE.value
                ? categoryOrType.productType
                : groupType === SET_BY_MARGIN_ENUMS.PRODUCT.value
                ? categoryOrType.product_name
                : groupType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
                ? categoryOrType.categoryName +
                  " - " +
                  categoryOrType.parentCategoryName
                : categoryOrType.categoryName}
              {index < groupArray.length - 1 ? ", " : ""}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default TableCustomAppliesToField;
