import React, { useState, useMemo, useRef, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { Row, Col, Modal, Form } from "react-bootstrap";

import Button from "../Buttons/Button";
import SearchField from "../searchField/SearchField";
import SelectedCustomerCard from "./SelectedCustomerCard";
import TableCustomHeader from "../table/TableCustomHeader";
import { transactionActions } from "../../../redux/actions";
import SelectDropDown from "../selectDropdown/SelectDropdown";
import CustomerDropdown from "../selectDropdown/CustomerDropdown";
import ServerPaginationTable from "../agGrid/ServerPaginationTable";
import InfiniteScrollTable from "../agGrid/InfiniteScrollTable";
import { printReceiptData } from "../../Printers/ReceiptPrinters/printReceipt";
import CategoriesDropdown from "../../../shared/components/selectDropdown/CategoriesDropdown";
import PrintLabelModal from "../../../views/Inventory/Items/printingLabelModal/PrintLabelModal";
import { prepareReceiptForPrinter } from "../../Printers/ReceiptPrinters/prepareReceiptForPrinter";
import { viewCartPrintDropdownStyle } from "../../../assets/scss/style/viewCartPrintDropdownStyle";
import SendReceiptModal from "../../../views/SellAndTrade/History/ImportTransactionHistory/SendReceiptModal";
import { prepareReceiptForPrinterTrade } from "../../Printers/ReceiptPrinters/prepareReceiptForPrinterTrade";
import AddNewCustomerModal from "../../../views/SellAndTrade/NewTransaction/AddNewCustomer/AddNewCustomerModal";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../system/globalEnums";
import {
  toastType,
  EMPTY_STRING,
  skeletonArray,
  toastMessages,
  globalConstants,
  defaultGridState,
  inventoryConstants,
  buttonNameConstants,
  routesPathConstants,
  buttonTypeConstants,
  transactionConstants,
  categoryDropdownConstants,
  ITEM_HISTORY_DATEPICKER_OPTIONS,
} from "../../../constants";
import {
  ShowIcon,
  CrossIcon,
  SearchIcon,
  BlueBoxIcon,
  PrintIcon,
} from "../../../assets/icons/iconsProvider";
import {
  getRowId,
  getRowStyle,
  customToast,
  getShortestSku,
  calcullateItems,
  parseJsonObject,
  isSpinnerEnabled,
  LoadingIndicator,
  isSaleTransaction,
  isTradeTransaction,
  isReturnTransaction,
  getCustomerFullName,
  getReceiptPrintDate,
  getUnitPurchasePrice,
  parseToDecimalNumber,
  getProductDetailCardTags,
  parseToThousandSeperatorDecimalNumber,
  isButtonDisable,
} from "../../utility";
import { success } from "../../../redux/actions/actionsUtilities";
import CustomCellRendererWithSkeleton from "../agGrid/CustomCellRendererWithSkeleton";
import DashboardDatePicker from "../datetimePickerDropdown/DashboardDatePicker";

const TransactionHistoryTableWithFilters = (props) => {
  // initializations
  const {
    user,
    option,
    setOption,
    searchText,
    currentStore,
    spinnerArray,
    allCustomers,
    setSearchText,
    paginationData,
    sendEmailOrSMS,
    sellTradeHistory,
    getAllTransaction,
    isAddCustomerModal,
    emptyAllTransaction,
    defaultReceiptPrinter,
    toggleAddCustomerModal,
    isInfiniteScroll = false,
    updateCustomerInTransactionHistory,
    tableHeight = `calc(100vh - 320px)`,
    isCustomerLogsModal = {
      isSearchField: true,
      customer: { id: EMPTY_STRING },
    },
  } = props;
  const gridRef = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [idType, setIdType] = useState(EMPTY_STRING);
  const [selectedRows, setSelectedRows] = useState([]);
  const [viewCartDetail, setViewCartDetail] = useState([]);
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isReceiptModal, setIsReceiptModal] = useState(false);
  const [printRecieptData, setprintRecieptData] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState(EMPTY_STRING);
  const [gridState, setGridState] = useState(
    isCustomerLogsModal.customer.id
      ? { ...defaultGridState, searchQuery: isCustomerLogsModal.customer.id }
      : defaultGridState
  );
  const [timeFrame, setTimeFrame] = useState({
    start: EMPTY_STRING,
    end: EMPTY_STRING,
    duration: "daily",
  });

  const getTotalCogs = (params) => {
    return params?.data?.inventory?.reduce((sum, item) => {
      return (
        sum +
        (isTradeTransaction(params?.data)
          ? item?.price?.unit_purchase_price || 0
          : item?.cogs || 0)
      );
    }, 0);
  };

  const HistoryColumnsPaginationTable = useMemo(
    () => [
      {
        headerName: "Sale ID",
        field: "saleId",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${params.data.id}`}
          />
        ),
      },
      {
        headerName: "Date",
        field: "createdOn",
        cellStyle: {
          overflow: "visible",
        },
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="inventory-cell-max-size ">
                <TableCustomHeader
                  id={`created-on-in-array-${params?.data?.id}`}
                  label={
                    <span className="inventory-cell-max-size">
                      {getReceiptPrintDate(params.data.createdOn)}
                    </span>
                  }
                  tooltopText={
                    <p className="text-start fw-normal m-0">
                      {getReceiptPrintDate(params.data.createdOn)}
                    </p>
                  }
                  className="sku-tooltip"
                />
              </span>
            }
            componentRenderCondition={params?.data?.createdOn}
          />
        ),
      },
      {
        headerTooltip: "Transaction Type",
        headerName: "Transactio...",
        field: "TransactionType",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`${params.data.type}`}
            componentRenderCondition={params?.data?.type}
          />
        ),
      },
      {
        headerName: "Payment",
        field: "paymentType",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="inventory-cell-max-size ">
                {params?.data?.PaymentType === transactionConstants.CREDIT_CARD
                  ? buttonNameConstants.CREDIT_CARD
                  : params?.data?.PaymentType === transactionConstants.GIFT_CARD
                  ? buttonNameConstants.GIFT_CARD_PAYMENT
                  : params?.data?.PaymentType ===
                    transactionConstants.STORE_CREDIT
                  ? buttonNameConstants.STORE_CREDIT
                  : buttonNameConstants.CASH}
              </span>
            }
            componentRenderCondition={params?.data?.PaymentType}
          />
        ),
      },
      {
        headerName: "Cart QTY",
        field: "cartQuantity",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              params?.data?.inventory?.reduce(
                (a, b) => a + Number(b.quantity),
                0
              ) ||
              params?.data?.inventory?.reduce(
                (a, b) => a + Number(b.price.quantity),
                0
              ) ||
              0
            }
            componentRenderCondition={params?.data?.inventory}
          />
        ),
      },
      {
        headerName: "Customer",
        field: "customer",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="inventory-cell-max-size">
                {getCustomerFullName(params?.data?.customer)}
              </span>
            }
            componentRenderCondition={params?.data?.customer}
          />
        ),
      },
      {
        headerName: "Total COGS",
        field: "amount",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`$${parseToThousandSeperatorDecimalNumber(
              params?.data?.id ? getTotalCogs(params) : 0
            )}`}
            componentRenderCondition={
              params?.data?.totalAmountToPay ||
              params?.data?.totalAmountToPay === 0
            }
          />
        ),
      },
      {
        headerName: "Total Amount",
        field: "amount",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={`$${parseToThousandSeperatorDecimalNumber(
              params?.data?.totalAmountToPay
            )}`}
            componentRenderCondition={
              params?.data?.totalAmountToPay ||
              params?.data?.totalAmountToPay === 0
            }
          />
        ),
      },
      {
        maxWidth: 150,
        headerName: EMPTY_STRING,
        field: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        lockPosition: "right",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  type="button"
                  onClick={() => handlePrintReceipt(params.data)}
                  className={`action-Button-Wrapper`}
                >
                  <PrintIcon className="cursor-pointer text-primary history-view-icon" />
                </button>
                <button
                  className="action-Button-Wrapper ps-0 pe-0"
                  onClick={() => {
                    handleViewCart(params.data);
                  }}
                >
                  <ShowIcon className="cursor-pointer text-primary history-view-icon" />
                </button>
              </div>
            }
            componentRenderCondition={params.data?.id}
          />
        ),
        sortable: false,
      },
    ],
    // eslint-disable-next-line
    []
  );
  const HistoryColumnsInfiniteScrollTable = useMemo(
    () => [
      {
        id: "saleId",
        header: "Sale ID",
        accessorKey: "saleId",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={`${props?.row?.original?.id}`}
            componentRenderCondition={props?.row?.original?.id}
          />
        ),
        enableSorting: false,
      },
      {
        id: "createdOn",
        header: "Date",
        accessorKey: "createdOn",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={
              <span className="inventory-cell-max-size ">
                <TableCustomHeader
                  id={`created-on-in-array-${props?.row?.original?.id}`}
                  label={
                    <span className="inventory-cell-max-size">
                      {getReceiptPrintDate(props?.row?.original?.createdOn)}
                    </span>
                  }
                  tooltopText={
                    <p className="text-start fw-normal m-0">
                      {getReceiptPrintDate(props?.row?.original?.createdOn)}
                    </p>
                  }
                  className="sku-tooltip"
                />
              </span>
            }
            componentRenderCondition={props?.row?.original?.createdOn}
          />
        ),
        enableSorting: false,
      },
      {
        id: "TransactionType",
        header: "Transaction Type",
        accessorKey: "TransactionType",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={`${props?.row?.original?.type}`}
            componentRenderCondition={props?.row?.original?.type}
          />
        ),
        enableSorting: false,
      },
      {
        id: "paymentType",
        header: "Payment",
        accessorKey: "paymentType",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={
              <span className="inventory-cell-max-size ">
                {props?.row?.original?.PaymentType ===
                transactionConstants.CREDIT_CARD
                  ? buttonNameConstants.CREDIT_CARD
                  : props?.row?.original?.PaymentType ===
                    transactionConstants.GIFT_CARD
                  ? buttonNameConstants.GIFT_CARD_PAYMENT
                  : props?.row?.original?.PaymentType ===
                    transactionConstants.STORE_CREDIT
                  ? buttonNameConstants.STORE_CREDIT
                  : buttonNameConstants.CASH}
              </span>
            }
            componentRenderCondition={props?.row?.original?.PaymentType}
          />
        ),
        enableSorting: false,
      },
      {
        id: "cartQuantity",
        header: "Cart QTY",
        accessorKey: "cartQuantity",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={
              props?.row?.original?.inventory?.reduce(
                (a, b) => a + Number(b.quantity),
                0
              ) ||
              props?.row?.original?.inventory?.reduce(
                (a, b) => a + Number(b.price.quantity),
                0
              ) ||
              0
            }
            componentRenderCondition={props?.row?.original?.inventory}
          />
        ),
        enableSorting: false,
      },
      {
        id: "customer",
        header: "Customer",
        accessorKey: "customer",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={
              <span className="inventory-cell-max-size">
                {getCustomerFullName(props?.row?.original?.customer)}
              </span>
            }
            componentRenderCondition={props?.row?.original?.customer}
          />
        ),
        enableSorting: false,
      },
      {
        id: "amount",
        header: "Amount",
        accessorKey: "amount",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={`$${parseToThousandSeperatorDecimalNumber(
              props?.row?.original?.totalAmountToPay
            )}`}
            componentRenderCondition={
              props?.row?.original?.totalAmountToPay ||
              props?.row?.original?.totalAmountToPay === 0
            }
          />
        ),
        enableSorting: false,
      },
      {
        id: "currentBalance",
        header: "Store Credit",
        accessorKey: "currentBalance",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            cellComponent={`
              $${parseToThousandSeperatorDecimalNumber(
                props?.row?.original?.customer?.currentBalance
              )}`}
            componentRenderCondition={props?.row?.original?.customer?.id}
          />
        ),
        enableSorting: false,
      },
      {
        id: "id",
        header: EMPTY_STRING,
        accessorKey: "id",
        cell: (props) => (
          <CustomCellRendererWithSkeleton
            params={props}
            componentRenderCondition={props?.row?.original?.id}
            cellComponent={
              <div>
                <button
                  className="action-Button-Wrapper ps-0 pe-0"
                  onClick={() => {
                    handleViewCart(props?.row?.original);
                  }}
                >
                  <ShowIcon className="cursor-pointer text-primary history-view-icon" />
                </button>
              </div>
            }
          />
        ),
        enableSorting: false,
      },
    ],
    []
  );

  // helper functions
  const gridData = useMemo(() => {
    let filteredData = sellTradeHistory || [];
    setGridState({
      ...gridState,
      pageIndex: paginationData?.pageNumber || defaultGridState?.pageIndex,
      totalRecords:
        paginationData?.totalRecords || defaultGridState?.totalRecords,
      pageEndRecordNumber:
        paginationData?.pageEndRecordNumber ||
        defaultGridState?.pageEndRecordNumber,
      pageStartRecordNumber:
        paginationData?.pageStartRecordNumber ||
        defaultGridState?.pageStartRecordNumber,
    });
    setIsSearchLoading(false);
    return filteredData;
  }, [sellTradeHistory]);
  const toggleCustomTradeModal = () => {
    setShow(!show);
    setIsAddCustomer(!isAddCustomer);
  };
  const clearSearchText = () => {
    setSearchText("");
    setDebouncedSearchText("");
    updateGridState({ ...gridState, searchQuery: EMPTY_STRING });
  };
  const handleDebouncedSearch = useDebouncedCallback((value) => {
    updateGridState({ ...gridState, searchQuery: value, pageIndex: 1 });
  }, 1000);
  const handleSearchInputChange = (value) => {
    setIsSearchLoading(true);
    handleDebouncedSearch(value);
    setDebouncedSearchText(value);
  };
  const updateGridState = (gridState) => {
    getAllTransaction(
      currentStore?.id,
      option?.value,
      timeFrame.start,
      timeFrame.end,
      gridState
    );
    setGridState(gridState);
  };
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };
  const handlePrintProductLabel = () => {
    toggleCustomTradeModal();
    togglePrintLabelModal();
  };
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };
  const handleAddCustomerClick = () => {
    setIsAddCustomer(!isAddCustomer);
  };
  const handleAddNewCustomerClick = () => {
    setCustomerToEdit({});
    toggleAddCustomerModal(true);
  };
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === viewCartDetail[0]?.customer?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };
  const handleUpdateCustomer = (customer) => {
    const transactionType = isTradeTransaction(viewCartDetail[0])
      ? "trade"
      : isSaleTransaction(viewCartDetail[0])
      ? "transaction"
      : "return";

    const customerData = customer?.id
      ? customer
      : { name: EMPTY_STRING, id: EMPTY_STRING };
    const { id, store } = viewCartDetail[0] || {};
    const transactionDetails = {
      id,
      storeId: store?.id,
      type: option?.value,
      start: timeFrame.start,
      end: timeFrame.end,
    };

    updateCustomerInTransactionHistory(
      { customer: customerData },
      transactionDetails,
      gridState,
      transactionType
    );
  };
  const handlePrintReceipt = (newTradeData) => {
    if (idType == globalConstants.TYPE_ID_TRD) {
      handlePrintReceiptTrade(newTradeData);
    } else {
      const printData = prepareReceiptForPrinter(
        user,
        newTradeData.id,

        newTradeData.paymentHistory,
        currentStore,
        allCustomers?.find((customer) => {
          if (customer.id === newTradeData?.customer?.id) {
            return {
              ...newTradeData?.customer,
              currentBalance: customer.currentBalance,
            };
          }
        }) || {},
        [
          ...newTradeData?.inventory?.map((inv) => {
            return {
              ...inv,
              cogs: inv?.price?.unit_purchase_price,
              item: inv?.product_name,
              priceAfterDiscount: inv?.price?.unit_purchase_price,
              itemName: inv?.product_name,
              quantity: inv?.price?.quantity,
              productType: inv?.productType,
              category: inv?.category_name,
              // max: 2,
              // actualPrice: inv?.price?.unit_sell_price,
              // isDiscountApplied: false,
              // discountPercentage: 0,
              // priceAfterDiscount: 1000,
              // actualDiscountPercentage: 0,
            };
          }),
        ],
        newTradeData.discount,
        newTradeData.SubTotal,
        newTradeData.Tax,
        newTradeData.totalAmountToPay,
        newTradeData.cashTenderedAmount,
        newTradeData.cashChangeOwed,
        newTradeData.giftCardPaymentHistory,
        false,
        false,
        newTradeData.discountMethod,
        newTradeData.discountedValue,
        getReceiptPrintDate(newTradeData.createdOn)
      );
      printReceiptData(printData, defaultReceiptPrinter);
    }
  };
  const handlePrintReceiptTrade = (newTradeData) => {
    const partialPayment =
      (newTradeData?.paymentHistory?.[transactionConstants.CASH] || 0) +
        (newTradeData?.paymentHistory?.[transactionConstants.STORE_CREDIT] ||
          0) >
        0 &&
      newTradeData?.totalAmountToPay !== newTradeData?.currentAmountToPay;
    const printData = prepareReceiptForPrinterTrade(
      user,
      newTradeData?.id,
      newTradeData?.paymentHistory,
      currentStore,
      allCustomers?.find((customer) => {
        if (customer.id === newTradeData?.customer?.id) {
          return {
            ...newTradeData?.customer,
            currentBalance: customer.currentBalance,
          };
        }
      }) || {},
      [
        ...newTradeData?.inventory.map((inv) => {
          return {
            ...inv,
            cogs: inv?.price?.unit_purchase_price,
            item: inv?.product_name,
            priceAfterDiscount: partialPayment
              ? getUnitPurchasePrice(newTradeData, inv)
              : inv?.price?.unit_purchase_price,
            itemName: inv?.product_name,
            quantity: inv?.price?.quantity,
            productType: inv?.productType,
            category: inv?.category_name,

            // max: 2,
            // actualPrice: inv?.price?.unit_sell_price,
            // isDiscountApplied: false,
            // discountPercentage: 0,
            // priceAfterDiscount: 1000,
            // actualDiscountPercentage: 0,
          };
        }),
      ],
      newTradeData?.discount,
      newTradeData?.SubTotal,
      newTradeData?.Tax,
      newTradeData?.totalAmountToPay,
      0,
      0,
      false,
      partialPayment,
      false,
      getReceiptPrintDate(newTradeData.createdOn)
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };
  const toggleIsReceiptModal = () => {
    setIsReceiptModal(!isReceiptModal);
  };
  const handleIssueRefund = () => {
    navigate(routesPathConstants.SELL_AND_TRADE_NEW_RETURN, {
      state: {
        id: viewCartDetail[0]?.id,
      },
    });
  };
  const handleViewCart = (row) => {
    const idPrefix = row.id.substring(0, 3);
    setIdType(idPrefix);
    setprintRecieptData(row);
    setViewCartDetail([row]);
    setSelectedRows([]);
    setSelectedRows(
      row?.inventory.map((item) => {
        const newObject = {
          sku: getShortestSku(item.sku),
          product_name: item.product_name,
          price: {
            quantity: item?.price?.quantity,
            type: item?.price?.type,
            unit_sell_price: item?.price?.unit_purchase_price,
          },
          category_name: item?.category_name || item?.category,
        };
        return newObject;
      })
    );
    toggleCustomTradeModal();
  };
  const fetchMoreData = () => {
    updateGridState({
      ...gridState,
      pageSize:
        Number(
          gridState?.pageSize ?? inventoryConstants.ITEM_HISTORY_LIST_LENGTH
        ) + Number(inventoryConstants.ITEM_HISTORY_LIST_LENGTH),
    });
  };

  // useEffects
  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      getAllTransaction(
        currentStore?.id,
        option?.value,
        timeFrame.start,
        timeFrame.end,
        gridState
      );
    }

    return () => {
      emptyAllTransaction();
    };
  }, [timeFrame.start, timeFrame.end, currentStore, option]);
  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      handleUpdateCustomer({
        ...allCustomers[0],
        name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
      });
    }
    setSelectedCustomerFlag(false);
    // eslint-disable-next-line
  }, [allCustomers]);
  useEffect(() => {
    if (viewCartDetail?.[0]?.id) {
      setViewCartDetail(
        sellTradeHistory?.filter((item) => item.id === viewCartDetail[0]?.id)
      );
    }
  }, [sellTradeHistory]);
  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
    // eslint-disable-next-line
  }, [isAddCustomerModal]);

  return (
    <>
      <Row className="m-0">
        <Col md={12} className="d-flex align-items-center mb-2 gap-3">
          <div className={`w-100 d-flex align-items-center gap-3 `}>
            <DashboardDatePicker
              label="Date"
              getTimeFrame={getTimeFrame}
              datePickerOptions={ITEM_HISTORY_DATEPICKER_OPTIONS}
            />
            <CategoriesDropdown
              label="Type"
              options={inventoryConstants.TRANSACTION_TYPE}
              handleChange={(e) => {
                setOption(
                  e == null
                    ? {
                        label: globalConstants.ALL,
                        value: globalConstants.ALL,
                      }
                    : e
                );
              }}
              noOptionsMessage="No Transaction Type Found"
              placeholder="Transaction Type"
              SelectIconImage={categoryDropdownConstants.TRANSACTION_ICON}
              className={"dropdown-max-width"}
              isClearable={false}
              defaultValue={{
                value: PRODUCT_TYPES_ENUMS.ALL,
                label: "All",
              }}
            />
            {isCustomerLogsModal.isSearchField && (
              <SearchField
                onChange={(e) => handleSearchInputChange(e.target.value)}
                IconImage={SearchIcon}
                BlueBoxIcon={BlueBoxIcon}
                label="Search"
                placeHolder="Type or Scan"
                value={debouncedSearchText}
                CrossIcon={debouncedSearchText ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                LoadingIndicator={
                  isSearchLoading ||
                  (isButtonDisable(
                    spinnerArray,
                    transactionConstants.GET_ALL_TRANSACTION
                  ) &&
                    searchText)
                    ? LoadingIndicator
                    : false
                }
              />
            )}
          </div>
        </Col>
      </Row>
      <Row
        className={`m-0 w-100 h-100 user-screen-wrapper pt-3 p-0 ${
          isInfiniteScroll && isCustomerLogsModal.customer.id
            ? EMPTY_STRING
            : "pb-5 mb-2"
        }`}
      >
        {isInfiniteScroll ? (
          <InfiniteScrollTable
            columns={HistoryColumnsInfiniteScrollTable}
            data={gridData}
            update={fetchMoreData}
            hasMore={gridState?.pageSize < gridState?.totalRecords}
            isLoading={isSpinnerEnabled(
              spinnerArray,
              transactionConstants.GET_ALL_TRANSACTION
            )}
          />
        ) : (
          <ServerPaginationTable
            colDefs={HistoryColumnsPaginationTable}
            gridRef={gridRef}
            gridData={
              isSpinnerEnabled(
                spinnerArray,
                transactionConstants.GET_ALL_TRANSACTION
              )
                ? skeletonArray
                : gridData
            }
            gridState={gridState}
            getRowId={getRowId}
            getRowStyle={getRowStyle}
            updateGridState={updateGridState}
            paginationPageSize={gridState.pageSize}
            pageSizeOptions={gridState.pageSizeOptions}
            isDisablePaginationActions={isSpinnerEnabled(
              spinnerArray,
              transactionConstants.GET_ALL_TRANSACTION
            )}
            tableHeight={tableHeight}
          />
        )}
      </Row>
      <Modal
        show={show}
        onHide={toggleCustomTradeModal}
        size="md"
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Form className="px-0">
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <span className="modal-sale-transaction-id">
                {isTradeTransaction(viewCartDetail?.[0])
                  ? "Trade "
                  : isReturnTransaction(viewCartDetail?.[0])
                  ? "Return "
                  : "Sale "}
                ({viewCartDetail?.[0]?.id})
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <Row className="m-0 p-0">
              <Col md={12} className="p-0 mb-2">
                <span className="trans-items-modal view-cart-date">
                  {getReceiptPrintDate(viewCartDetail?.[0]?.createdOn)}
                </span>
              </Col>
              <Col md={12} className="p-0 mb-4 mt-2">
                {Array.isArray(viewCartDetail) &&
                viewCartDetail[0]?.customer?.id ? (
                  <SelectedCustomerCard
                    customer={viewCartDetail[0]?.customer}
                    isDeleteEditOptions={true}
                    isStoreCredit={false}
                    handleCustomerDeleteClick={() => {
                      if (
                        viewCartDetail[0]?.paymentHistory?.totalPaidAmount
                          ?.storeCredit > 0
                      ) {
                        customToast(
                          toastMessages.UPDATE_CUSTOMER_ERROR,
                          toastType.ERROR
                        );
                      } else {
                        handleUpdateCustomer(false);
                      }
                    }}
                    handleCustomerEditClick={handleCustomerEditClick}
                    className={{
                      parnetDiv: "selected-customer-wrapper gap-3",
                      nameDiv: "selected-customer-wrapper",
                      nameSpan: "trans-product-name-modal-total",
                      editDeleteDiv: "ms-auto",
                      deleteButton: "p-0 opacity-50",
                      editButton: "p-0 opacity-50",
                    }}
                  />
                ) : !isAddCustomer ? (
                  <Button
                    className="p-0"
                    type="button"
                    label={buttonNameConstants.ADD_CUSTOMER}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleAddCustomerClick}
                  />
                ) : (
                  <CustomerDropdown
                    value={""}
                    menuPortalTarget=""
                    dropdownIndicator={false}
                    noOptionsMessage="No Customer Found"
                    placeholder="Customer Name, Phone, Email"
                    handleAddCustomerClick={handleAddNewCustomerClick}
                    SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                    handleChange={(selectedOption) => {
                      if (selectedOption?.value) {
                        handleUpdateCustomer(
                          parseJsonObject(selectedOption?.value)
                        );
                      }
                    }}
                    selectedCustomerFlag={selectedCustomerFlag}
                  />
                )}
              </Col>
            </Row>
            <Row className="m-0 p-0 ">
              <div className="container-scrollbar p-0  trans-modal-card-wrapper ">
                <Row className="m-0">
                  <Col
                    sm={6}
                    className="px-0 d-flex align-items-center mb-3 history-cart-table-header"
                  >
                    Product Name
                  </Col>
                  <Col
                    sm={3}
                    className="px-0 d-flex align-items-center justify-content-end mb-3 history-cart-table-header"
                  >
                    {isTradeTransaction(viewCartDetail[0])
                      ? "Stock Price"
                      : "COGS"}
                  </Col>
                  <Col
                    sm={3}
                    className="px-0 d-flex align-items-center justify-content-end mb-3 pe-1 history-cart-table-header"
                  >
                    {isTradeTransaction(viewCartDetail[0])
                      ? "COGS"
                      : "Stock Price"}
                  </Col>
                  {Array.isArray(viewCartDetail) &&
                    viewCartDetail[0]?.inventory?.map((product) => {
                      return (
                        <>
                          <Col
                            sm={6}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center gap-1">
                              <span className="trans-items-modal">
                                {product?.price?.quantity}
                              </span>
                              <span className="trans-product-name-modal">
                                {product.product_name}
                              </span>
                              <span className="trans-items-modal">
                                {product?.sku !==
                                  transactionConstants.QUICK_TRADE &&
                                  `#${getShortestSku(product?.sku)}`}
                              </span>
                            </div>
                          </Col>
                          <Col
                            sm={3}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              <span className="trans-product-name-modal">
                                $
                                {isTradeTransaction(viewCartDetail[0])
                                  ? parseToThousandSeperatorDecimalNumber(
                                      product?.price?.unit_sell_price
                                    )
                                  : parseToThousandSeperatorDecimalNumber(
                                      product?.cogs
                                    )}
                              </span>
                            </div>
                          </Col>
                          <Col
                            sm={3}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              <span className="trans-product-name-modal">
                                $
                                {isTradeTransaction(viewCartDetail[0])
                                  ? parseToThousandSeperatorDecimalNumber(
                                      product?.price?.unit_purchase_price
                                    )
                                  : parseToThousandSeperatorDecimalNumber(
                                      product?.price?.unit_purchase_price
                                    )}
                              </span>
                            </div>
                          </Col>

                          <Col
                            md={12}
                            className="m-0 p-0 mb-3
                             d-flex justify-content-between"
                          >
                            <div className="d-flex align-items-center gap-1">
                              <span className="trans-items-modal ms-3">
                                {isTradeTransaction(viewCartDetail[0])
                                  ? product?.["category_name"]
                                  : product?.category}
                              </span>
                              <span className="trans-items-modal">
                                (
                                {isTradeTransaction(viewCartDetail[0])
                                  ? product?.price?.type
                                  : product?.type}
                                )
                              </span>
                              {
                                // Show serial number for systems/consoles if found
                                product?.subcategory ===
                                  TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
                                  product?.serialNumber && (
                                    <span className="trans-items-modal">
                                      S/N : {product?.serialNumber}
                                    </span>
                                  )
                              }
                              <span className="trans-items-modal">
                                {getProductDetailCardTags(
                                  product.tags?.filter(
                                    (tag) =>
                                      tag !== inventoryConstants.CUSTOM_ITEM_TAG
                                  ),
                                  product.sku
                                )}
                              </span>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                </Row>
              </div>
              <Col
                md={12}
                className="m-0 mt-2 mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
              >
                <div className="trans-product-name-modal-text m-0">
                  Subtotal
                </div>

                <div className="">
                  {` $${parseToThousandSeperatorDecimalNumber(
                    viewCartDetail?.[0]?.SubTotal
                  )}`}
                </div>
              </Col>
              <>
                {
                  // discount is visible in case of sale transaction
                  isSaleTransaction(viewCartDetail?.[0]) && (
                    <Col
                      md={12}
                      className="m-0  mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
                    >
                      <div className="trans-product-name-modal-text m-0">
                        Discount
                      </div>
                      <div className="">
                        {`$${parseToThousandSeperatorDecimalNumber(
                          viewCartDetail?.[0]?.discount
                        )}`}
                      </div>
                    </Col>
                  )
                }
                {
                  // tax is visible in case of sale and return transaction
                  (isSaleTransaction(viewCartDetail?.[0]) ||
                    isReturnTransaction(viewCartDetail?.[0])) && (
                    <Col
                      md={12}
                      className="m-0  mb-2 p-0 d-flex justify-content-between tran-modal-detail-margin"
                    >
                      <div className="trans-product-name-modal-text m-0">
                        {`Tax(
                  ${parseToDecimalNumber(
                    viewCartDetail?.[0]?.taxPercentage ?? 0
                  )}
                  %)`}
                      </div>
                      <div className="">
                        {`$${parseToThousandSeperatorDecimalNumber(
                          viewCartDetail?.[0]?.Tax ?? 0
                        )}`}
                      </div>
                    </Col>
                  )
                }
              </>

              <Col
                md={12}
                className="m-0  mb-2 p-0 pt-2 d-flex justify-content-between border-top tran-modal-detail-margin"
              >
                <div className="trans-product-name-modal-total m-0">
                  TOTAL ({calcullateItems(viewCartDetail?.[0]?.inventory)}{" "}
                  items)
                </div>

                <div className="trans-product-name-modal-total">
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    viewCartDetail?.[0]?.totalAmountToPay
                  )}
                </div>
              </Col>
              {
                // PAYMENT HISTORY: history is generating in case of transaction (sale) and trade and in case of return use the current paid values as there is no split payment feature in return transaction
                isReturnTransaction(viewCartDetail?.[0]) ? (
                  <Col
                    md={12}
                    className="m-0 p-0 mb-1 d-flex justify-content-between"
                  >
                    <div className="d-flex align-items-center gap-1">
                      <span className="trans-product-name-modal-text">
                        {viewCartDetail?.[0].PaymentType ===
                        transactionConstants.CREDIT_CARD
                          ? buttonNameConstants.CREDIT_CARD
                          : viewCartDetail?.[0].PaymentType ===
                            transactionConstants.STORE_CREDIT
                          ? buttonNameConstants.STORE_CREDIT
                          : viewCartDetail?.[0].PaymentType ===
                            transactionConstants.GIFT_CARD
                          ? viewCartDetail?.[0]?.giftCard?.giftCardName
                          : buttonNameConstants.CASH}
                      </span>
                    </div>
                    <div>
                      <span className="trans-product-name-modal-text">
                        $
                        {parseToThousandSeperatorDecimalNumber(
                          viewCartDetail?.[0]?.totalAmountToPay
                        )}
                      </span>
                    </div>
                  </Col>
                ) : (
                  viewCartDetail?.[0]?.paymentHistory?.history?.map(
                    (history) => {
                      return history.isUndoTransaction ? (
                        <Col
                          md={12}
                          className="m-0 p-0 mb-1 d-flex justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <span className="trans-product-name-modal-text">
                              {history.paymentType ===
                              transactionConstants.CREDIT_CARD
                                ? buttonNameConstants.CREDIT_CARD
                                : history.paymentType ===
                                  transactionConstants.STORE_CREDIT
                                ? buttonNameConstants.STORE_CREDIT
                                : history.paymentType ===
                                  transactionConstants.GIFT_CARD
                                ? history?.giftCardName
                                : buttonNameConstants.CASH}
                            </span>
                          </div>
                          <div>
                            <span className="trans-product-name-modal-text">
                              $
                              {parseToThousandSeperatorDecimalNumber(
                                history?.currentAmountToPay
                              )}
                            </span>
                          </div>
                        </Col>
                      ) : (
                        ""
                      );
                    }
                  )
                )
              }
            </Row>
            {viewCartDetail?.[0]?.note && (
              <Col md={12} className="m-0 mt-3   p-0">
                <div className="note-text-div container-scrollbar">
                  <p className="trans-items-modal mb-2">Note</p>
                  <span className="note-text">{viewCartDetail?.[0]?.note}</span>
                </div>
              </Col>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-center gap-2 w-100">
              <Button
                type="button"
                label={buttonNameConstants.CLOSE}
                handleClick={() => toggleCustomTradeModal()}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                className="w-100"
              />
              <SelectDropDown
                outerWrapperClassname="w-100"
                menuPortalTarget=""
                isSingleValue="Receipt"
                placeHolder="Receipt"
                handleChange={(option) => {
                  if (
                    option?.value ===
                    transactionConstants.RECEIPT_OPTIONS[0].value
                  ) {
                    handlePrintReceipt(printRecieptData);
                  } else if (
                    option?.value ===
                    transactionConstants.RECEIPT_OPTIONS[1].value
                  ) {
                    toggleIsReceiptModal();
                  }
                }}
                styles={viewCartPrintDropdownStyle}
                menuPlacement="bottom"
                options={transactionConstants.RECEIPT_OPTIONS}
              />

              {viewCartDetail?.[0]?.id.substring(0, 3) ===
              globalConstants.TYPE_ID_TRA ? (
                <Button
                  type="button"
                  className="w-100"
                  label={buttonNameConstants.ISSUE_REFUND}
                  handleClick={handleIssueRefund}
                />
              ) : viewCartDetail?.[0]?.id.substring(0, 3) ===
                globalConstants.TYPE_ID_TRD ? (
                <Button
                  type="button"
                  className="w-100"
                  label={buttonNameConstants.PRINT_PRODUCT_LABEL}
                  handleClick={handlePrintProductLabel}
                ></Button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <PrintLabelModal
        printLabelModal={printLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={selectedRows}
      />
      <SendReceiptModal
        sendEmailOrSMS={sendEmailOrSMS}
        transactionData={viewCartDetail?.[0]}
        isReceiptModal={isReceiptModal}
        toggleIsReceiptModal={toggleIsReceiptModal}
      />
      <AddNewCustomerModal
        selectedCustomer={selectedCustomer}
        addCustomerModal={addCustomerModal}
        customerToEdit={customerToEdit}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  allCustomers: state.customer.allCustomers,
  allTransaction: state.transaction.allTransaction,
  paginationData: state.transaction.allTransaction.paginationData,
  sellTradeHistory: state.transaction.allTransaction.sellTradeHistory,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
  defaultReceiptPrinter: state.printer.defaultReceiptPrinter,
  duplicateHistoryRecords: state.transaction.duplicateHistoryRecords,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllTransaction: (storeId, type, start, end, gridState) =>
    dispatch(
      transactionActions.getAllTransaction(storeId, type, start, end, gridState)
    ),
  emptyAllTransaction: () =>
    dispatch(success({}, transactionConstants.GET_ALL_TRANSACTION)),
  sendEmailOrSMS: (data) => dispatch(transactionActions.sendEmailOrSMS(data)),
  updateCustomerInTransactionHistory: (
    data,
    transactionDetails,
    gridState,
    endPoint
  ) =>
    dispatch(
      transactionActions.updateCustomerInTransactionHistory(
        data,
        transactionDetails,
        gridState,
        endPoint
      )
    ),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
});

//-------Export History Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistoryTableWithFilters);
